import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, InputAdornment, Tooltip } from '@mui/material';
import { Help as HelpIcon } from '@mui/icons-material';
import { hanelMixPanelEvents } from '../../helper';
import { formDefaultValues } from '../../constants';
import { CContext } from '../utils';

// ----------------------------------------------------------------------
const { ONLY_NUMBER, ONLY_ALPHABETS, ALPHA_NUMERIC_DASH_REGEX, ALPHA_NUMERIC_DASH_SLASH_REGEX } = formDefaultValues;

RHFTextField.propTypes = {
  name: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default function RHFTextField({ name, label, handleChange, disabled, valueField, ...other }) {
  const { mixpanel } = useContext(CContext);
  const { t } = useTranslation('translation');
  const { control } = useFormContext();
  const {
    onlyText,
    alphaNumeric,
    endAdornmentHelpText,
    dateField,
    alphaNumericSlash,
    phoneNumber,
    noInitalZero,
    maxLength,
    onKeyDown,
    hanleBlur: _hanleBlur,
    allcaps,
    required,
    type,
    maxValue,
    ...rest
  } = other;

  const handleKeyDonw = (e) => {
    if (type === 'number' && !ONLY_NUMBER.test(e.target.value)) {
      return e.preventDefault();
    }
    if (onKeyDown && typeof onKeyDown === 'function') {
      return onKeyDown.call(null, e);
    }
    return e;
  };

  const checkValueConditions = useCallback(
    (value) =>
      (noInitalZero && +value === 0) ||
      (maxLength && maxLength < value.length) ||
      (phoneNumber && value.length > 10) ||
      (!ALPHA_NUMERIC_DASH_SLASH_REGEX.test(value) && alphaNumericSlash) ||
      (!ALPHA_NUMERIC_DASH_REGEX.test(value) && alphaNumeric) ||
      (!ONLY_ALPHABETS.test(value) && onlyText) ||
      (type === 'number' && (+value < 0 || !ONLY_NUMBER.test(value))),
    []
  );

  const handleOnChange = (onChange, event) => {
    const { value } = event.target;
    if (value && checkValueConditions(value)) {
      return event.preventDefault();
    }
    if (maxLength && maxLength > value.length) {
      event.target.value = value.substr(0, maxLength);
    }
    if (type === 'number' && value.indexOf('0') === 0) {
      event.target.value = value.substr(1, value.length);
    }
    if (type === 'number' && +value > maxValue) {
      event.target.value = value.substr(0, `${maxValue}`.length);
    }
    if (allcaps && value) {
      event.target.value = value.toUpperCase();
    }
    if (handleChange && typeof handleChange === 'function') {
      handleChange(event);
    }
    return onChange(event);
  };

  const handleBlur = (onBlur, onChange, event) => {
    if(event?.target?.value?.trim) {
      event.target.value = event.target.value.trim()
      handleOnChange(onChange, event)
    }
    if (_hanleBlur && typeof _hanleBlur === 'function') {
      _hanleBlur.call(event);
    }
    hanelMixPanelEvents.call(mixpanel, t(label), name);
    return onBlur(event);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onBlur, onChange, ...field }, fieldState: { error } }) => (
        <TextField
          {...field}
          disabled={disabled}
          onKeyDown={handleKeyDonw.bind(this)}
          onChange={handleOnChange.bind(this, onChange)}
          label={
            <>
              {t(label)}
              {required && !t(label).includes(' *') && ' *'}
            </>
          }
          fullWidth
          value={
            valueField === undefined
              ? (typeof value === 'number' && value === 0) || value === null
                ? ''
                : value || ''
              : valueField
          }
          error={!!error}
          helperText={t(error?.message)}
          onBlur={handleBlur.bind(this, onBlur, onChange)}
          {...(endAdornmentHelpText && {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={t(endAdornmentHelpText)} aria-label="add" placement="right">
                    <HelpIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            },
          })}
          {...(!['text', 'number'].includes(type) && { type })}
          {...rest}
          {...(dateField && { error: !!error })}
        />
      )}
    />
  );
}

