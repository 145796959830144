/* eslint-disable react/prop-types */
import React from 'react';
// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
// sections

export default React.memo(({ isMobile, handlePreviousButton, route, t, isSubmitting=true, className }) => (
  <Stack className={className}>
    {!isMobile && (
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        sx={(theme) => ({
          background: theme.palette.common.blue,
          color: theme.palette.common.offWhite,
          '&:hover': {
            background: theme.palette.offWhite,
            color: theme.palette.common.black,
          },
        })}
        onClick={handlePreviousButton.bind(this, route)}
      >
        <ArrowBackIcon />
        {t('PREVIOUS')}
      </LoadingButton>
    )}
    <LoadingButton
      fullWidth
      
      size="large"
      type="submit"
      variant="contained"
      loading={isSubmitting}
      sx={(theme) => ({
        background: theme.palette.common.blue,
        '&:hover': {
          background: theme.palette.offWhite,
          color: theme.palette.common.black,
        },
      })}
    >
      {t('SAVE_AND_CONTINUE')}
    </LoadingButton>
  </Stack>
));
