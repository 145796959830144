import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const applicationProgressStatusBoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  position: 'relative',
  marginTop: '0 !important',
  paddingTop: 45,
  '& .vertical-lines': {
    position: 'absolute',
    width: 2,
    top: '100%',
    background: 'rgba(0, 0, 0, 0.12)',
    left: '11.5px',
    height: '100%',
  },
  '&:last-child': {
    '& .vertical-lines': {
      display: 'none',
    },
  },
  '& > .pending-status': {
    color: theme.palette.greyBlue,
    '& + .pending-status-arrow': {
      color: theme.palette.greyBlue,
    },
  },
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '1px',
    '& > span': {
      width: 25,
      height: 25,
      border: `2px solid ${theme.palette.common.white}`,
      boxShadow: theme.palette.ocrButtonShadow,
      borderRadius: '50%',
      marginRight: 20,
    },
    '& > #status-circle-success': {
      background: theme.palette.success.dark,
    },
    '& > #status-circle-error': {
      background: theme.palette.error.dark,
    },
    '& > #status-circle-pending': {
      background: theme.palette.greyBlue,
    },
  },
  '& svg': {
    fontSize: 28,
  },
}));

const isErrorDisplay = (_) => _ === 'true';

export const applicationProgressCardContainerStyle = styled(Box)(({ theme, iserrordisplayed }) => ({
  padding: '100px 50px 50px',
  [theme.breakpoints.up('sm')]: {
    maxWidth: 500,
    margin: 'auto',
  },
  '& .contentBox': {
    marginBottom: '20px',
    '& p': {
      marginBottom: '6px',
      color: theme.palette.common.black,
      fontSize: '16px',
    },
  },
  '& .buttonBox': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '35px',
    '& button': {
      fontSize: 14,
      borderRadius: '4px',
      fontWeight: 500,
    },
    '& .skipButton': {
      backgroundColor: theme.palette.ocrCardBg,
      color: theme.palette.common.black,
      marginRight: '16px',
    },
    '& .saveButton': {
      backgroundColor: theme.palette.navyBlue,
      color: theme.palette.common.white,
      flex: 1,
    },
    '& .hand-icon': {
      cursor: 'pointer',
    },
  },
  '& .cursor-pointer': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    padding: isErrorDisplay(iserrordisplayed) ? '124px 20px 20px' : '80px 20px 20px',
  },
}));
