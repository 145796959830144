import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @mui
import { Stack } from '@mui/material';

// ----------------------------------------------------------------------
import { RHFTextField, RHFSelectbox } from '../hook-form';
import { getAddredByPincode } from '../../services';
import { ToTitleCase } from '../../helper';
import { CContext } from '../utils';
import { formDefaultValues } from '../../constants';

const AddressInformationsForm = ({ name, getValues, disable, setValue, watchAddress, typeOfAddress }) => {
  const [villageOptions, setVillageOptions] = useState([]);
  const { setErrorMessage, setShowLoader } = useContext(CContext);

  const {
    villages,
    plotNumber,
    floorNumber,
    streetAndBuildingName,
    locality,
    landmark,
    pinCode,
    villageOrTown,
    tehsil,
    state,
    numberOfMonthsAtThisAddress,
    propertyOwnership,
  } = name;

  const [pincodeData, setPincodeData] = useState({ city: false, village: false, state: false });

  // useEffect(() => {
  //   setTimeout(() => {
  //     const value = typeof getValues === 'function' ? getValues(pinCode) : false;
  //     const villageOrTownVal = typeof getValues === 'function' ? getValues(villageOrTown) : false;
  //     const tehsilVal = typeof getValues === 'function' ? getValues(tehsil) : false;
  //     const stateVal = typeof getValues === 'function' ? getValues(state) : false;
  //     if (getValues(villages)?.length > 0) {
  //       setVillageOptions(getValues(villages).map((e) => ({ name: ToTitleCase(e), value: e })));
  //     }
  //     if (value && !(villageOrTown && tehsil && state)) {
  //       handleChange({ target: { value: +value } }, villageOrTownVal, tehsilVal, stateVal);
  //     }
  //   }, 1000);
  // }, [watchAddress]);

  const setValuesInForm = (villageOrTownVal, tehsilVal, stateVal, res) => {
    let stateValue = '';
    let distValue = '';
    let localityVal = '';
    let tehsileValue = '';
    stateValue = !stateVal ? res?.data?.stateName || '' : getValues(state);
    distValue = !villageOrTownVal ? res?.data?.districtName || '' : getValues(villageOrTown);
    tehsileValue = !tehsilVal ? res?.data?.villages?.[0] || '' : getValues(tehsil);
    localityVal = !res?.postOffice ? res?.data?.postOffice || '' : getValues(locality);
    setPincodeData({
      city: stateValue.length > 0,
      village: tehsileValue.length > 0,
      state: res?.data?.villages?.[0],
      locality: res?.data.postOffice,
    });
    if (locality) setValue(locality, localityVal, { shouldValidate: true });
    if (tehsileValue) setValue(villageOrTown, tehsileValue, { shouldValidate: true });
    if (distValue) setValue(tehsil, distValue, { shouldValidate: true });
    if (stateValue) setValue(state, stateValue, { shouldValidate: true });
  };

  // const handleChange = async (e, villageOrTownVal, tehsilVal, stateVal) => {
  //   if (villageOptions.length > 0) setVillageOptions([]);
  //   if (+e.target.value > 99999) {
  //     setShowLoader(true);
  //     const res = await getAddredByPincode(e.target.value);
  //     if (res.status) {
  //       if (res?.data?.villages.length > 0) {
  //         const villageOpt = res.data.villages
  //           .filter((e) => e !== null)
  //           .map((e) => ({ name: ToTitleCase(e), value: ToTitleCase(e) }));
  //         setVillageOptions(villageOpt);
  //       }
  //       setValuesInForm(villageOrTownVal, tehsilVal, stateVal, res);
  //     } else {
  //       setPincodeData({
  //         city: false,
  //         village: false,
  //         state: false,
  //       });
  //       setErrorMessage({ title: res.error.msg, show: true });
  //     }
  //     setShowLoader(false);
  //   }
  //   setShowLoader(false);
  // };

  return (
    <>
      <Stack className="width-100 mobile-view">
        <Stack spacing={3} mb={5} className="width-50">
          <RHFTextField disabled={!!disable} name={plotNumber} label="PLOT_NUMBER" required />
        </Stack>
        <Stack spacing={3} mb={5} className="width-50">
          <RHFTextField disabled={!!disable} name={floorNumber} label="FLOOR_NUMBER" required />
        </Stack>
      </Stack>
      <Stack className="fullContainer">
        <Stack spacing={3} mb={5} className="width-50">
          <RHFTextField disabled={!!disable} name={streetAndBuildingName} label="STREAT_AND_BUILDING_NAME" required />
        </Stack>
        <Stack spacing={3} mb={5} className="width-50">
          <RHFTextField disabled={!!disable} name={locality} label="LOCALITY" />
        </Stack>
        <Stack spacing={3} mb={5} className="width-50">
          <RHFTextField disabled={!!disable} name={landmark} label="LANDMARK" alphaNumeric required />
        </Stack>
        <Stack spacing={3} mb={5} className="width-50">
          <RHFTextField
            disabled={!!disable}
            name={pinCode}
            // handleChange={handleChange}
            label="PIN_CODE"
            type="number"
            maxLength={6}
            required
          />
        </Stack>
        <Stack spacing={3} mb={5} className="width-50">
          {villageOptions.length > 1 && (
            <RHFSelectbox
              // disabled={!!disable}
              name={villageOrTown}
              label="VILLAGE_TOWN"
              required
              menus={villageOptions}
            />
          )}
          {villageOptions.length <= 1 && (
            <RHFTextField
              name={villageOrTown}
              label="VILLAGE_TOWN"
              // disabled={!!disable || pincodeData.village || typeOfAddress?.villageOrTown?.length > 15}
              onlyText
              required
            />
          )}
        </Stack>
        <Stack spacing={3} mb={5} className="width-50">
          <RHFTextField
            // disabled={!!disable || pincodeData.city || typeOfAddress?.tehsil?.length > 15}
            name={tehsil}
            label="DISTRICT"
            onlyText
            required
          />
        </Stack>
        <Stack spacing={3} mb={5} className="width-50">
          <RHFTextField
            // disabled={!!disable || pincodeData.state || typeOfAddress?.state?.length > 15}
            name={state}
            label="STATE"
            onlyText
            required
          />
        </Stack>
        {numberOfMonthsAtThisAddress && (
          <Stack spacing={3} mb={5} className="width-50">
            <RHFTextField
              disabled={!!disable}
              name={numberOfMonthsAtThisAddress}
              label="NUMBER_OF_MONTHS_AT_THIS_ADDRESS"
              type="number"
              maxLength={3}
              noInitalZero
              required
            />
          </Stack>
        )}
        {propertyOwnership && (
          <Stack spacing={3} mb={5} className="width-50">
            <RHFSelectbox
              name={propertyOwnership}
              disable={!!disable}
              label="PROPERTY_OWNERSHIP"
              menus={formDefaultValues.propertyOwnership}
              required
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

AddressInformationsForm.propTypes = {
  name: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  disable: PropTypes.bool,
  watchAddress: PropTypes.object,
  typeOfAddress: PropTypes.object,
};

export default memo(AddressInformationsForm);

