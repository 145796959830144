import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CContext } from './CContextProvider';

// ----------------------------------------------------------------------

export default () => {
  const { pathname } = useLocation();
  const { activeStep } = useContext(CContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, activeStep]);

  return <></>;
};
