import { styled, Box } from '@mui/material';

export const StepperBox = styled(Box)(({ theme }) => ({
  position: 'static',
  top: 78,
  padding: '15px 120px',
  background: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 9,
  boxShadow: `0px 3px 4px ${theme.palette.greyishColor}`,
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: '35px 20px 20px',
  },
  '& .stepperTitle': {
    fontSize: 22,
    fontWeight: 500,
    [theme.breakpoints.between('sm', 'lg')]: {
      display: 'none',
    },
  },
  '& .stepperName': {
    fontSize: 14,
    fontWeight: 400,
    display: 'none',
    [theme.breakpoints.between('sm', 'lg')]: {
      display: 'none',
    },
  },
  '& .MuiStepper-horizontal': {
    flex: 0.7,
    margin: 'auto',
    '& .MuiStep-root': {
      padding: 0,
      '& .MuiStepConnector-root': {
        top: 11,
        left: 'calc(-50% + 10px)',
        right: 'calc(50% + 10px)',
      },
      '& .MuiStepLabel-root': {
        '& .MuiStepLabel-iconContainer': {
          width: 22,
          height: 22,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `2px solid ${theme.palette.common.white}`,
          boxShadow: theme.palette.ocrButtonShadow,
        },
        '& .MuiStepLabel-labelContainer': {
          color: theme.palette.common.black,
          position: 'absolute',
          top: -24,
        },
        '& .MuiStepLabel-label': {
          marginTop: 0,
          marginBottom: 3,
          '@media (min-width: 601px) and (max-width: 767px)': {
            fontSize: 9,
          },
          '@media (min-width: 768px) and (max-width: 1400px)': {
            fontSize: 12,
          },
          '&.Mui-completed': {
            color: theme.palette.success.darker,
          },
          '&.Mui-active': {
            color: theme.palette.success.darker,
          },
        },
        '& .MuiStepIcon-root': {
          width: 20,
          height: 20,
          fill: theme.palette.error.dark,
          '& text': {
            display: 'none',
          },
          '&.Mui-completed': {
            fill: theme.palette.success.darker,
          },
          '&.Mui-active': {
            fill: theme.palette.success.darker,
          },
        },
      },
    },
    '& .hand-icon': {
      cursor: 'pointer',
      '& .Mui-disabled': {
        cursor: 'pointer',
      },
    },
    '& .disabled-title': {
      '& .MuiStepLabel-root': {
        '& .MuiStepIcon-root': {
          fill: theme.palette.greyBlue,
        },
      },
    },
  },
}));

