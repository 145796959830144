import React, { useState, useEffect, createContext } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

import { useScreenWidth } from '../../custom-hooks';

export const CContext = createContext();

const defaultLang = localStorage.language || 'en';

const CContextProvider = ({ children }) => {
  const theme = useTheme();
  const screenWidth = useScreenWidth();
  const [mixpanel, setMixpanel] = useState(null);
  const [hidestepper, setHideStepper] = useState(false);
  const [lang, setLang] = useState(defaultLang);
  const [errorMessage, setErrorMessage] = useState({ title: null, show: false });
  const [showLoader, setShowLoader] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [tabPanelValue, setTabPanelValue] = useState(0);
  const [socket, setSocket] = useState(null);
  const [feePyamentDone, setFeePayMentDone] = useState(false);
  const [showProvisionalOffer, setShowProvisionalOffer] = useState(false);
  const [isMobile, setIsMobile] = useState(screenWidth < theme.breakpoints.values.sm);
  const [updateApplicationProgress, setUpdateApplicationProgress] = useState(false);
  const [offerAccepted, setOfferAccepted] = useState(false);
  const [applicationProgress, setApplicationProgress] = useState({
    completed: [],
    available: [],
    allStepsCount: 7,
  });

  const changeLanguage = () => {
    const language = lang === 'en' ? 'hi' : 'en';
    setLang(language);
  };

  const contextValue = {
    lang,
    socket,
    isMobile,
    setSocket,
    showLoader,
    activeStep,
    tabPanelValue,
    hidestepper,
    errorMessage,
    setShowLoader,
    setActiveStep,
    setTabPanelValue,
    setHideStepper,
    changeLanguage,
    feePyamentDone,
    setErrorMessage,
    setFeePayMentDone,
    applicationProgress,
    setApplicationProgress,
    updateApplicationProgress,
    setUpdateApplicationProgress,
    setShowProvisionalOffer,
    showProvisionalOffer,
    offerAccepted,
    setOfferAccepted,
    mixpanel,
    setMixpanel,
  };

  useEffect(() => {
    i18next.changeLanguage(lang);
    localStorage.language = lang;
  }, [lang]);

  useEffect(() => {
    setIsMobile(screenWidth < theme.breakpoints.values.sm);
  }, [screenWidth, theme.breakpoints.values.sm]);

  return <CContext.Provider value={contextValue}>{children}</CContext.Provider>;
};

CContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CContextProvider;
