import { useEffect, useState } from 'react';

export default (initialState, uploadedDocs) => {
  const [photoData, setPhotosData] = useState(initialState);

  useEffect(() => {
    if (Object.values(uploadedDocs).some((item) => item.state)) {
      setTimeout(() => {
        const _photoData = { ...photoData };
        Object.keys(uploadedDocs).forEach((item) => {
          if (uploadedDocs[item].state) {
            _photoData[item].status = 2;
          }
        });
        setPhotosData(_photoData);
      }, 500);
    }
  }, [uploadedDocs]);

  return { photoData, setPhotosData };
};
