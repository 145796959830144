import { isValidPhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';
import { formDefaultValues } from '../constants';

export default () => {
  const bool = Yup.bool().nullable(true);
  const string = Yup.string().nullable(true);
  const email = string.email('INVALID_EMAIL').nullable(true);
  const emailRequire = email.required('EMAIL_REQUIRED');
  const number = Yup.string()
    .nullable(true)
    .test('validation', 'MUST_BE_A_NUMBER', (value) => !Number.isNaN(value))
    .typeError('MUST_BE_A_NUMBER');
  const object = Yup.object().nullable(true);
  const possitiveNumber = number.test('validation', 'MUST_BE_POSSITIVE', (value) => (value ? +value >= 0 : true));
  const array = Yup.array();
  const arrayOfObject = (objectSchema) => array.of(object.shape(objectSchema));

  const requiredValidations = {
    bool,
    string,
    number,
    'possitive-number': possitiveNumber,
    array,
    object,
  };

  /**
   *
   * @param {string} type bool, string, number, possitive-number, array, object
   * @param {string} message
   * @returns {*} Yup validation instance
   */
  const requireDynamic = (type, message) => requiredValidations[type || 'string'].required(message);
  const nonRequireDynamic = (type, message) => requiredValidations[type || 'string'].required(message);

  const checkIfLestThenSix = (value) =>
    (value[0] === '+' && value[3] && +value[3] > 5) || (value[0] && value[0] !== '+' && +value[0] > 5);

  const phoneNumber = requireDynamic('string', 'PHONE_NUMBER_REQUIRED').test(
    'validation',
    'INVALID_PHONE',
    (value) => value && checkIfLestThenSix(value) && isValidPhoneNumber(value)
  );

  const officialPhoneNumber = nonRequireDynamic('string', 'OFFICIAL_PHONE_REQUIRED').test(
    'validation',
    'INVALID_PHONE',
    (value) => value && checkIfLestThenSix(value) && isValidPhoneNumber(value)
  );

  const addressSchemaNonRequired = {
    plotNumber: string,
    floorNumber: string,
    streetAndBuildingName: string,
    locality: string,
    landmark: string,
    pinCode: possitiveNumber,
    villageOrTown: string,
    tehsil: string,
    state: string,
    numberOfMonthsAtThisAddress: possitiveNumber,
    propertyOwnership: string,
  };

  const addressSchema = {
    plotNumber: requireDynamic('string', 'PLOT_REQUIRED'),
    floorNumber: requireDynamic('string', 'FLOOR_REQUIRED'),
    streetAndBuildingName: requireDynamic('string', 'BUILDING_NAME_REQUIRED'),
    locality: string,
    landmark: requireDynamic('string', 'LANDMARK_REQUIRED'),
    pinCode: requireDynamic('possitive-number', 'PIN_CODE_REQUIRED'),
    villageOrTown: requireDynamic('string', 'CITY_VILLAGE_TOWN_REQUIERD'),
    tehsil: requireDynamic('string', 'DISTRICT_IS_REQUIRED'),
    state: requireDynamic('string', 'STATE_IS_REQUIRED'),
    numberOfMonthsAtThisAddress: requireDynamic('possitive-number', 'NUMBER_OF_MONTHS_IS_REQUIRED')
      .test('minimum-check', 'MINIMUN_MONTHS_IS_0', (val) => val && +val >= 0)
      .test('maximum-check', 'MAXIMUN_MONTHS_IS_999', (val) => val && +val <= 999),
    propertyOwnership: requireDynamic('string', 'PROPERTY_OWNERSHIP_IS_REQUIRED'),
    isSameAsCurrentAddress: string,
    isCommunicationAddress: string,
    villages: array.of(string),
  };

  const myDeatilsSchema = {
    customerType: requireDynamic('string', 'CUSTOMER_TYPE_REQUIRED'),
    phoneNumber,
    verifiedPhoneNumber: string,
    fatherName: string.when(['customerType', 'crmAccountId'], {
      is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
      then: requireDynamic('string', 'FATHER_NAME_REQUIRED'),
      otherwise: string,
    }),
    email,
    motherName: string.when(['customerType', 'crmAccountId'], {
      is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
      then: requireDynamic('string', 'MOTHER_NAME_REQUIRED'),
      otherwise: string,
    }),
    dateOfBirth: string.when(['customerType', 'crmAccountId'], {
      is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
      then: requireDynamic('string', 'DOB_REQUIRED'),
      otherwise: string,
    }),
    maritalStatus: string.when(['customerType', 'crmAccountId'], {
      is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
      then: requireDynamic('string', 'MARITAL_STATUS_REQUIRED'),
      otherwise: string,
    }),
    otpSent: string,
    spouseName: string.when(['customerType', 'crmAccountId'], {
      is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
      then: string.when('maritalStatus', {
        is: 'MARRIED',
        then: requireDynamic('string', 'SPOUSE_NAME_REQUIRED'),
        otherwise: string,
      }),
    }),
    religion: string.when(['customerType', 'crmAccountId'], {
      is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
      then: requireDynamic('string', 'RELIGION_REQUIRED'),
      otherwise: string,
    }),
    casteCategory: string.when(['customerType', 'crmAccountId'], {
      is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
      then: requireDynamic('string', 'CASTE_CATEGORY_REQUIRED'),
      otherwise: string,
    }),
    firstName: string.when(['customerType', 'crmAccountId'], {
      is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
      then: requireDynamic('string', 'FIRST_NAME_REQUIRED'),
      otherwise: string,
    }),
    name: string,
    middleName: string,
    lastName: string.when(['customerType', 'crmAccountId'], {
      is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
      then: requireDynamic('string', 'LAST_NAME_REQUIRED'),
      otherwise: string,
    }),
    gender: string.when(['customerType', 'crmAccountId'], {
      is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
      then: requireDynamic('string', 'GENDER_REQUIRED'),
      otherwise: string,
    }),
    occupation: string.when(['customerType', 'crmAccountId'], {
      is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
      then: requireDynamic('string', 'OCCUPATION_REQUIRED'),
      otherwise: string,
    }),
    panCardNumber: requireDynamic('string', 'PAN_CARD_NUMBER_REQUIRED').test('validation', 'INVALID_PAN', (gst) =>
      formDefaultValues.panCardRegex.test(gst)
    ),
    crmAccountId: string,
    aadharCardNumber: string
      .when(['customerType', 'crmAccountId'], {
        is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
        then: string.when('voterId', {
          is: (_) => !_,
          then: string.when('drivingLicense', {
            is: (_) => !_,
            then: requireDynamic('string', 'AADHAR_CARD_NUMBER_REQUIRED'),
          }),
        }),
        otherwise: string,
      })
      .test('validation', 'INVALID_AADHAAR', (id) => !id || formDefaultValues.AADHAR_REGEX.test(id)),
    voterId: string
      .when(['customerType', 'crmAccountId'], {
        is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
        then: string.when('aadharCardNumber', {
          is: '',
          then: string.when('drivingLicense', {
            is: '',
            then: requireDynamic('string', 'VOTER_ID_REQUIRED'),
          }),
        }),
        otherwise: string,
      })
      .test('validation', 'INVALID_VOTER', (id) => !id || formDefaultValues.VOTER_CARD_VALIDATION.test(id)),
    drivingLicense: string.when(['customerType', 'crmAccountId'], {
      is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
      then: string.when('aadharCardNumber', {
        is: '',
        then: string.when('voterId', {
          is: '',
          then: requireDynamic('string', 'DRIVING_LICENSE_REQUIRED'),
        }),
      }),
      otherwise: string,
    }),

    residencyStatus: string.when(['customerType', 'crmAccountId'], {
      is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
      then: requireDynamic('string', 'RESIDENCY_STATUS_REQ'),
      otherwise: string,
    }),
  };

  const SELF_EMPLOYED_BUSINESSPERSON = object.when(
    ['applicantMyDetails.customerType', 'applicantMyDetails.occupation', 'applicantMyDetails.crmAccountId'],
    {
      is: (customerType, occupation, crmAccountId) =>
        customerType === 'INDIVIDUAL' && occupation === 'SELF_EMPLOYED_BUSINESSPERSON' && crmAccountId === '',
      then: object.shape({
        constitutionOfCompany: requireDynamic('string', 'CONSTITUTION_OF_COMPANY_REQUIRED'),
        nameOfEnterprise: requireDynamic('string', 'NAME_OF_ENTERPRISE_REQUIRED'),
        officialEmailIdId: email,
        officialPhoneNumber,
        monthlyNetIncome: requireDynamic('number', 'MONTHLY_NET_INCOME_REQUIRED'),
        isThisAnMSME: requireDynamic('string', 'MSME_REQUIREED'),
        msmeClassification: string.when('isThisAnMSME', {
          is: '',
          then: requireDynamic('string', 'MSME_CLASSIFICATION_REQ'),
        }),
        isTheMSMEUdyamRegistered: string.when('isThisAnMSME', {
          is: '',
          then: requireDynamic('string', 'UDHYAM_REG_IS_REQ'),
        }),
        itrFiledForIncomeDeclared: string,
        sector: requireDynamic('string', 'SECTOR_IS_REQ'),
        subSector: requireDynamic('string', 'SUB_SEC_IS_REQ'),
        gstNo: requireDynamic('string', 'GST_NO_REQUIRED').test('validation', 'INVALID_GST', (gst) =>
          formDefaultValues.gstNoRegex.test(gst)
        ),
      }),
    }
  );

  const NONINDIVIDUAL = object.when(['applicantMyDetails.customerType', 'applicantMyDetails.crmAccountId'], {
    is: (customerType, crmAccountId) => customerType === 'NONINDIVIDUAL' && crmAccountId === '',
    then: object.shape({
      isThisAnMSME: requireDynamic('string', 'MSME_REQUIREED'),
      msmeClassification: string.when('isThisAnMSME', {
        is: 'true',
        then: requireDynamic('string', 'MSME_CLASSIFICATION_REQ'),
      }),
      isTheMSMEUdyamRegistered: string.when('isThisAnMSME', {
        is: 'true',
        then: requireDynamic('string', 'UDHYAM_REG_IS_REQ'),
      }),
      constitutionOfCompany: requireDynamic('string', 'CONSTITUTION_OF_COMPANY_REQUIRED'),
      officialEmailId: email,
      officialPhoneNumber,
      monthlyNetIncome: requireDynamic('number', 'MONTHLY_NET_INCOME_REQUIRED'),
      entityName: requireDynamic('string', 'ENTITY_NAME_REQUIRED'),
      gstNo: requireDynamic('string', 'GST_NO_REQUIRED').test('validation', 'INVALID_GST', (gst) =>
        formDefaultValues.gstNoRegex.test(gst)
      ),
      dateOfEstablishment: requireDynamic('string', 'DATE_OF_EST_REQ'),
      industry: requireDynamic('string', 'INDUSTRY_IS_REQ'),
      subIndustry: requireDynamic('string', 'SUB_INDUSTRY_IS_REQ'),
      sector: requireDynamic('string', 'SECTOR_IS_REQ'),
      companyWebsite: string,
    }),
  });

  const SELF_EMPLOYED_PROFESSIONAL = object.when(
    ['applicantMyDetails.customerType', 'applicantMyDetails.occupation', 'applicantMyDetails.crmAccountId'],
    {
      is: (customerType, occupation, crmAccountId) =>
        customerType === 'INDIVIDUAL' && occupation === 'SELF_EMPLOYED_PROFESSIONAL' && crmAccountId === '',
      then: object.shape({
        constitutionOfCompany: requireDynamic('string', 'CONSTITUTION_OF_COMPANY_REQUIRED'),
        profession: requireDynamic('string', 'PROFESSION_REQUIRED'),
        officialEmailIdId: email,
        officialPhoneNumber,
        monthlyNetIncome: requireDynamic('number', 'MONTHLY_NET_INCOME_REQUIRED'),
        sector: requireDynamic('string', 'SECTOR_IS_REQ'),
        subSector: requireDynamic('string', 'SUB_SEC_IS_REQ'),
        address: requireDynamic('string', 'COMPANY_ADDRESS_REQ'),
        itrFiledForIncomeDeclared: requireDynamic('string', 'COMPANY_ADDRESS_REQ'),
      }),
    }
  );

  const bankOrCash = object.when(
    ['applicantMyDetails.customerType', 'applicantMyDetails.occupation', 'applicantMyDetails.crmAccountId'],
    {
      is: (customerType, occupation, crmAccountId) =>
        customerType === 'INDIVIDUAL' &&
        ['BANK_SALARIED_PRIVATE', 'CASH_SALARIED', 'BANK_SALARIED_GOVT_PSU'].includes(occupation) &&
        crmAccountId === '',
      then: object.shape({
        nameOfCompany: requireDynamic('string', 'NAME_OF_COMPANY_REQUIRED'),
        officialEmailId: email,
        officialPhoneNumber,
        monthlyNetIncome: requireDynamic('number', 'MONTHLY_NET_INCOME_REQUIRED'),
        sector: requireDynamic('string', 'SECTOR_IS_REQ'),
        subSector: requireDynamic('string', 'SUB_SEC_IS_REQ'),
        address: requireDynamic('string', 'COMPANY_ADDRESS_REQ'),
      }),
    }
  );

  const applicantAddress = object.when('applicantMyDetails.customerType', {
    is: 'INDIVIDUAL',
    then: object.shape({
      current: object.shape({ ...addressSchema }),
      permanent: object.shape({ ...addressSchema }),
    }),
  });

  const appliantDetailsValidations = {
    applicantMyDetails: object.shape(myDeatilsSchema),
    bankOrCash,
    SELF_EMPLOYED_PROFESSIONAL,
    NONINDIVIDUAL,
    SELF_EMPLOYED_BUSINESSPERSON,
    applicantAddress,
  };

  const coApplicantDetils = {
    applicantMyDetails: object.shape({
      ...myDeatilsSchema,
      otp: string,
      otpVerified: string,
      details: '',
      phoneNumber: requireDynamic('string', 'PHONE_NUMBER_REQUIRED')
        .notOneOf([Yup.ref('verifiedPhoneNumber')], 'PHONE_VERIFICATION_REQUIRED')
        .test(
          'validation',
          'INVALID_PHONE',
          (value) => value && checkIfLestThenSix(value) && isValidPhoneNumber(value)
        ),
      relationshipWithMainApplicant: string.when(['customerType', 'crmAccountId'], {
        is: (customerType, crmAccountId) => customerType === 'INDIVIDUAL' && crmAccountId === '',
        then: requireDynamic('string', 'RELATION_WITH_MAIN_APPLICANT_REQUIRED'),
        otherwise: string,
      }),
    }),
    bankOrCash,
    SELF_EMPLOYED_PROFESSIONAL,
    NONINDIVIDUAL,
    SELF_EMPLOYED_BUSINESSPERSON,
    applicantAddress,
  };

  return {
    bool,
    email,
    array,
    string,
    object,
    emailRequire,
    arrayOfObject,
    requireDynamic,
    addressSchema,
    possitiveNumber,
    appliantDetailsValidations,
    addressSchemaNonRequired,
    coApplicantDetils,
    phoneNumber,
    mixed: Yup.mixed(),
  };
};
