import AuthService from './authService';
import englishJson from '../locales/en/locale-en.json';
import transformPayload from './payloadTranform';
import responseTransform from './responseTransform';

export default {
  requestHandler(request) {
    if (request?.data?.apiKey) {
      request = transformPayload(request);
      return request;
    } 
    return request;
  },
  errorHandler(error) {
    if (error && error?.response?.status && [401, 403].includes(error.response.status)) {
      // AuthService.signOut();
    }
    const { t_message: message } = error?.response?.data || `ERROR OCCURED ==> ${error}`;
    return {
      error: {
        ...(error?.response?.data || error?.response || error),
        msg:
          (message && englishJson[message] && message) ||
          error.response?.errors?.[0]?.msg ||
          error.response?.errors?.msg ||
          error.response?.message?.msg ||
          error.response?.data?.message?.msg ||
          error.response?.data?.message ||
          error.response?.message ||
          'Something Went Wrong',
      },
      status: false,
    };
  },
  successHandler(response) {
    if(response?.config?.responseKey){
      return {
        status: true,
        data: responseTransform(response),
      ...(response.data.message && { message: response.data.message }),
      }
    } return {
      status: true,
      data: response.data.data || response.data,
      ...(response.data.message && { message: response.data.message }),
    };
  },
};
