import { styled, Box } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

export const applicationStatusCardContainerStyle = styled(Box)(({ theme }) => ({
  padding: '180px 50px 50px',
  [theme.breakpoints.up('sm')]: {
    maxWidth: 800,
    margin: 'auto',
  },
  '& .contentBox': {
    marginBottom: '20px',
    '& p': {
      marginBottom: '6px',
      color: theme.palette.common.black,
      fontSize: '16px',
    },
  },
  '& .buttonBox': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '35px',
    '& button': {
      fontSize: 14,
      borderRadius: '4px',
      fontWeight: 500,
      background: theme.palette.common.blue,
      textTransform: 'uppercase',
      color: theme.palette.common.white,
      padding: '8px 60px',
    },
    '& .lowerCase': {
      textTransform: 'capitalize',
    },
    '& .uploadDocumentsButton': {
      marginLeft: 8,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      width: '100%',
      bottom: 0,
      padding: '20px',
      zIndex: 9,
      background: theme.palette.common.white,
      margin: 0,
      left: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    padding: '100px 20px 70px',
  },
  '& > .application-status-text': {
    paddingBottom: 10,
    textAlign: 'center',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  '& .headerTitle': {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  '& .progress-bar-stack': {
    padding: '50px 0 80px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0 40px',
    },
    '&.mobile-bar': {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        '& .MuiStepConnector-line': {
          minHeight: 44,
        },
        '& .MuiStepConnector-root': {
          marginLeft: 10,
        },
        '& .MuiStepper-vertical': {
          '& .MuiStep-root': {
            padding: 0,
            '& .MuiStepLabel-labelContainer': {
              marginLeft: 15,
            },
            '& .MuiStepLabel-root': {
              padding: 0,
              '& .MuiStepLabel-iconContainer': {
                width: 22,
                height: 22,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `2px solid ${theme.palette.common.white}`,
                boxShadow: theme.palette.ocrButtonShadow,
                paddingRight: 0,
              },
              '& .MuiStepLabel-label': {
                marginTop: 0,
                marginBottom: 0,
                fontSize: 16,
                color: theme.palette.common.black,
                fontWeight: 500,
                '@media (min-width: 601px) and (max-width: 767px)': {
                  fontSize: 9,
                },
                '@media (min-width: 768px) and (max-width: 1400px)': {
                  fontSize: 12,
                },
                '&.Mui-completed': {
                  color: theme.palette.common.black,
                },
                '&.Mui-active': {
                  color: theme.palette.common.black,
                },
              },
              '& .MuiStepIcon-root': {
                width: 20,
                height: 20,
                fill: theme.palette.error.main,
                '& text': {
                  display: 'none',
                },
                '&.Mui-completed': {
                  fill: theme.palette.common.blue,
                },
                '&.Mui-active': {
                  fill: theme.palette.common.blue,
                },
              },
            },
          },
        },
      },
    },
    '&.web-bar': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      '& .MuiStepper-horizontal': {
        margin: 'inherit'
      }
    },
    '& .MuiStepper-horizontal': {
      flex: 0.7,
      margin: 'auto',
      '& .MuiStep-root': {
        padding: 0,
        '&:nth-child(even)': {
          '& .MuiStepLabel-root': {
            '& .MuiStepLabel-labelContainer': {
              position: 'static',
              paddingTop: 7,
            },
          },
        },
        '& .MuiStepConnector-root': {
          top: 11,
          left: 'calc(-50% + 10px)',
          right: 'calc(50% + 10px)',
        },
        '& .MuiStepLabel-root': {
          '& .MuiStepLabel-iconContainer': {
            width: 22,
            height: 22,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `2px solid ${theme.palette.common.white}`,
            boxShadow: theme.palette.ocrButtonShadow,
          },
          '& .MuiStepLabel-labelContainer': {
            color: theme.palette.common.black,
            position: 'absolute',
            top: -30,
          },
          '& .MuiStepLabel-label': {
            marginTop: 0,
            marginBottom: 0,
            fontSize: 13,
            '&.Mui-completed': {
              color: theme.palette.common.blue,
            },
            '&.Mui-active': {
              color: theme.palette.common.blue,
            },
          },
          '& .MuiStepIcon-root': {
            width: 20,
            height: 20,
            fill: theme.palette.error.main,
            '& text': {
              display: 'none',
            },
            '&.Mui-completed': {
              fill: theme.palette.common.blue,
            },
            '&.Mui-active': {
              fill: theme.palette.common.blue,
            },
          },
        },
      },
    },
  },
  '& .thanks-message-stack': {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
}));

export const UploadDocumentsContainerStyle = styled(Box)(({ theme }) => ({
  padding: '214px 120px 50px',
  [theme.breakpoints.down('sm')]: {
    padding: '100px 20px 70px',
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: '214px 20px 50px',
  },
  '& .buttonBox': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '35px',
    '& button': {
      fontSize: 14,
      borderRadius: '4px',
      fontWeight: 500,
      background: theme.palette.common.blue,
      textTransform: 'uppercase',
      color: theme.palette.common.white,
      padding: '8px 60px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& .lowerCase': {
      textTransform: 'capitalize',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      width: '100%',
      bottom: 0,
      padding: '20px',
      zIndex: 9,
      background: theme.palette.common.white,
      margin: 0,
      left: 0,
    },
  },
  '& .inputContainer': {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > div': {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '&.fullWidth': {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'column',
        '& .fileIcon': {
          position: 'absolute',
          margin: 0,
          right: 16,
          cursor: 'pointer',
        },
        '& .label': {
          fontSize: 16,
          marginBottom: 5,
          '& + div': {
            marginTop: 0,
          },
        },
        '& > div': {
          width: '100%',
          gap: '30px',
          marginTop: 0,
          marginBottom: '30px',
          position: 'relative',
          cursor: 'pointer',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
          '&.divider': {
            width: '100%',
            background: theme.palette.greyBorder,
            height: 1,
          },
          '& label': {
            // display: 'none',
          },
          '& legend': {
            display: 'none',
          },
          '& input[type="file"]': {
            opacity: 0,
          },

          // '&.inputTextField': {
          //   width: '100%',
          //   cursor: 'unset',
          //   // '& .MuiFormControl-root': {
          //   //   width: '49%',
          //   //   [theme.breakpoints.down('sm')]: {
          //   //     width: '100%',
          //   //   },
          //   //   '& label': {
          //   //     display: 'flex',
          //   //   },
          //   //   '& legend': {
          //   //     display: 'block',
          //   //   },
          //   // },
          // },
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        },
      },
      '& span': {
        marginBottom: 8,
        color: theme.palette.common.black,
        fontSize: 16,
        '& + div': {
          marginTop: 0,
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
}));

export const AppStatusColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.common.blue,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.common.blue,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.greyBorder,
    borderRadius: 1,
  },
}));
export const AppStatusMobileColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    left: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.common.blue,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.common.blue,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: 2,
    border: 0,
    backgroundColor: theme.palette.greyBorder,
    borderRadius: 1,
  },
}));

