import { styled } from '@mui/material/styles';

export const applicantDetailsContainerStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderTop: 0,
  borderBottom: 0,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    minHeight: '-webkit-fill-available',
    boxShadow: 'none',
    backgroundColor: theme.palette.offWhite,
  },
  '& .fullContainer': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  '& .mobile-view': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > div': {
      width: '49% !important',
    },
  },
  '& .error-field': {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: theme.palette.common.blue,
    fontWeight: 400,
    padding: '12px 0',
    '& svg': {
      fontSize: 22,
      marginRight: 10,
      fill: theme.palette.common.blue,
    },
  },
}));

export const applicationDetailsContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    minHeight: 'inherit',
  },
  '& .fixedBtn': {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '10px',
    background: theme.palette.offWhite,
    zIndex: 9,
    marginBottom: 0,
    '& button': {
      textTransform: 'uppercase',
    },
    [theme.breakpoints.up('sm')]: {
      position: 'static',
      padding: 0,
      zIndex: 'inherit',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      marginTop: 50,
      backgroundColor: theme.palette.common.white,
      '& button': {
        width: 'auto',
        height: 40,
        borderRadius: 4,
        marginLeft: 10,
        boxShadow: 'none',
        fontSize: 14,
        fontWeight: 500,
        '& svg': {
          marginRight: 6,
        },
      },
    },
  },
  '& .MuiAccordion-root': {
    borderRadius: '0 !important',
    border: `1px solid ${theme.palette.grey[1000]}`,
    margin: '-2px 0px !important',
    overflowAnchor: 'initial !important',
    '-webkit-overflow-anchor': 'initial !important',
    '&::before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: '75px',
      '&.noAccordion': {
        '& .MuiAccordionSummary-content': {
          justifyContent: 'flex-start',
          '& > div': {
            width: 34,
            height: 26,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 5,
            background: theme.palette.common.red,
            cursor: 'pointer',
            marginLeft: 10,
            '& svg': {
              fontSize: 18,
              fill: theme.palette.common.white,
              marginRight: '0 !important',
            },
          },
        },
      },
      '&.Mui-expanded': {
        backgroundColor: theme.palette.accordionBg,
      },
      '& .MuiAccordionSummary-content': {
        justifyContent: 'space-between',
        alignItems: 'center',
        '& svg': {
          fontSize: '20px',
          marginRight: 5,
        },
      },
      '& p': {
        fontWeight: 500,
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: '30px 16px 0',
      flexDirection: 'row',
      flexWrap: 'wrap',
      display: 'flex',
      justifyContent: 'space-between',
      '& hr': {
        width: '100%',
        '&.width-hr': {
          margin: '0 -16px',
          width: 'calc(100% + 32px)',
        },
      },
      '& .width-50': {
        width: '49%',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      '& .width-100': {
        width: '100%',
      },
    },
  },
  '& .addApplicantDetail': {
    '& .MuiChip-root': {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      '& span': {
        paddingLeft: 0,
        paddingRight: 6,
        fontSize: 14,
        fontWeight: 500,
      },
      '& svg': {
        fill: theme.palette.navyBlue,
      },
    },
  },
  '& .applicantHead': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '& p': {
      margin: 0,
      fontSize: 16,
      fontWeight: 500,
    },
    '& div': {
      width: 34,
      height: 26,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
      background: theme.palette.common.red,
      cursor: 'pointer',
      '& svg': {
        fontSize: 18,
        fill: theme.palette.common.white,
      },
    },
  },
}));

