import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import styled from '@emotion/styled';
import { hanelMixPanelEvents } from '../../helper';
import { CContext } from '../utils';

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hrefAddress: PropTypes.string,
  anchor: PropTypes.bool,
};

const LabelBox = styled(Box)(() => ({
  '& .anchor-tag': {
    textTransform: 'none',
  },
}));

const ErrorLabel = styled('p')(({ theme }) => ({
  color: theme.palette.inputError,
  fontSize: '0.75rem',
  fontWeight: 400,
  textAlign: 'left',
  marginTop: '6px !important',
}));

export function RHFCheckbox({ name, label, anchor, hrefAddress, ...other }) {
  const { t } = useTranslation('translation');
  const { control } = useFormContext();
  const { mixpanel } = React.useContext(CContext);
  const labelText = useCallback(() => {
    if (anchor && hrefAddress) {
      const lblArr = label.split(t('HERE'));
      return (
        <>
          {lblArr[0]}
          <a className="anchor-tag" href={hrefAddress} target="_window">
            {t('HERE')}
          </a>
          {lblArr[1]}
        </>
      );
    }
    return <>{t(label)}</>;
  }, [label]);

  return (
    <>
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={field.value}
                onBlur={(...args) => {
                  hanelMixPanelEvents.call(mixpanel, t(label), name);
                  field.onBlur(...args);
                }}
              />
            )}
          />
        }
        label={<LabelBox>{labelText()}</LabelBox>}
        {...other}
      />
    </>
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export function RHFMultiCheckbox({ name, options, title, required, ...other }) {
  const { control } = useFormContext();
  const { t } = useTranslation('translation');

  const onSelected = (option, field) =>
    field.value && field.value.includes(option)
      ? field.value.filter((value) => value !== option)
      : [...(field.value ? field.value : []), option];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <FormLabel
            sx={(theme) => ({
              color: theme.palette.common.black,
              display: 'flex',
              alignItems: 'center',
              [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start',
              },
              marginBottom: '-24px',
            })}
          >
            {t(title)}
            {required && !t(title).includes(' *') && ' *'}
          </FormLabel>
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={field?.value && field?.value?.includes(option.value)}
                    onChange={() => field.onChange(onSelected(option.value, field))}
                  />
                }
                label={t(option.label)}
                {...other}
              />
            ))}
          </FormGroup>
          {!!error && error?.message && <ErrorLabel>{t(error?.message)}</ErrorLabel>}
        </>
      )}
    />
  );
}
