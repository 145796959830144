import { format } from 'date-fns';
import { constants, formDefaultValues } from '../constants';

const { ROUTE } = constants;

/**
 * CheckIfNotEmpty is for checking text is empty or not
 * @param text
 * @returns {*|boolean}
 * @constructor
 */
export const CheckIfNotEmpty = (text) => !(text == null || /^\s*$/.test(text));

/**
 * ToTitleCase is for convert lines/text into title case
 * @param {string} txt
 * @returns {stargin}
 * @constructor
 */
export const ToTitleCase = (txt) =>
  txt
    .split(' ')
    .map((_i) => _i.slice(0, 1).toUpperCase() + _i.slice(1, _i.length).toLowerCase())
    .join(' ');

function isObject(value) {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
}

export const setReccurssiveObjectKeys = (object, path, value) => {
  if (!isObject(object)) {
    return { ...object };
  }
  path = path.split('.');
  const { length } = path;
  const lastIndex = length - 1;

  let index = -1;
  let nested = object;
  while (nested != null && ++index < length) {
    const key = path[index];
    let newValue = value;
    if (index !== lastIndex) {
      const objValue = nested[key];
      newValue = objValue;
    }
    nested[key] = newValue;
    nested = nested[key];
  }
  return object;
};

export const removeUndefinedOrNullFromObject = (object) => {
  const keys = Object.keys(object);
  keys.forEach((key) => {
    const element = object[key];
    if (!element || (Array.isArray(element) && element.length === 0)) {
      delete object[key];
    } else if (typeof element === 'object' && Object.keys(element).length > 0) {
      return removeUndefinedOrNullFromObject(element);
    }
    return null;
  });
  return object;
};

export const getActiveRoute = (activeStep) => formDefaultValues.routesAsTabs[activeStep] || ROUTE.BASIC_INFORMATION;

export const getActiveTab = (route) => formDefaultValues.tabAsRoute[route] || 0;

export const getReccurssiveObjectKeys = (object, path) => {
  if (!isObject(object)) {
    return object;
  }
  path = path.split('.');
  const { length } = path;

  let index = -1;
  let nested = object;
  while (nested != null && ++index < length) {
    const key = path[index];
    nested = nested[key];
  }
  return nested;
};

export const getKey = (docType) => formDefaultValues.docType[docType] || docType;

export const getUniqueArray = (arr) => arr.filter((item, index) => arr.indexOf(item) === index);

export const printErrorsOnConsole = (error) => console.log('error :>> ', error);

export function hanelMixPanelEvents(distinctId, fieldName) {
  if (sessionStorage.mixpanel_token && sessionStorage.phoneNumber) {
    this?.track(distinctId, {
      fieldName,
      // distinct_id: sessionStorage.phoneNumber,
    });
  }
}

export const filterErrorMessage = (errorObject) => {
  console.log(errorObject)
  let errorMessage = ''
  if (errorObject?.message?.toUpperCase() !== "SOMETHING WENT WRONG") {
    errorMessage = errorObject?.message
  } else if (errorObject?.msg?.toUpperCase() !== "SOMETHING WENT WRONG") {
    errorMessage = errorObject?.msg
  } else if (errorObject?.t_message !== "SOMETHING WENT WRONG") {
    errorMessage = errorObject?.t_message
  } else if (errorObject?.errors[0] !== "SOMETHING WENT WRONG") {
    errorMessage = errorObject?.errors[0]
  } else {
    errorMessage = "SOMETHING WENT WRONG"
  }
  return errorMessage
}

export const getdescriptiveMessage = (errorObject) => {
  console.log(errorObject)
  let errorMessage = []
  if (errorObject?.error?.error?.message !== "SOMETHING WENT WRONG") {
    errorMessage.push(errorObject?.error?.error?.message)
  } 
  if  (errorObject?.error?.error?.details !== "SOMETHING WENT WRONG") {
    errorMessage.push(errorObject?.error?.error?.details?.message)
  }
  else {
    errorMessage = "SOMETHING WENT WRONG"
  }
  return errorMessage
}

export const convertDateTimeStampToYYYYMMDDFormat = (dateTimeStamp) => {
	const yearMonthDateTimeFormat = format(new Date(dateTimeStamp), 'yyyy-MM-dd');
	console.log(yearMonthDateTimeFormat)
	return yearMonthDateTimeFormat
}
