import React, { memo, Suspense, useContext, useEffect } from 'react';
import mixpanel from 'mixpanel';
// routes
import { I18nextProvider } from 'react-i18next';
import Router from './routes';
// theme
import ThemeProvider from './theme';
import i18next from './i18n';
// components
import { CContext, CContextProvider, ScrollToTop, Toaster } from './components/utils';
import { AppLoader } from './components/common-components';

// ----------------------------------------------------------------------

const RoutesWithSuspense = memo(() => {
  const { showLoader, setMixpanel } = useContext(CContext);
  useEffect(() => {
    if (sessionStorage.mixpanel_token) {
      const mix = mixpanel.init(sessionStorage.mixpanel_token);
      setMixpanel(mix);
    }
  }, []);
  return (
    <Suspense fallback={showLoader ? '' : <AppLoader suspenseLoader />}>
      <Router />
    </Suspense>
  );
});

export default function App() {
  return (
    <I18nextProvider i18n={i18next}>
      <ThemeProvider>
        <CContextProvider>
          <ScrollToTop />
          <RoutesWithSuspense />
          <AppLoader />
        </CContextProvider>
      </ThemeProvider>
      <Toaster />
    </I18nextProvider>
  );
}
