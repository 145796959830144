import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const PagesWithTitle = ({ title, element: Children, ...others }) => {
  const { t } = useTranslation('translation');

  useEffect(() => {
    if (title) {
      document.title = `${t(title)} | ${t('UNASSISTED_LOAN_JOURNEY')}`;
    } else {
      document.title = `${t('UNASSISTED_LOAN_JOURNEY')}`;
    }
    return () => {
      document.title = `${t('UNASSISTED_LOAN_JOURNEY')}`;
    };
  }, [title]);

  return <Children {...others} />;
};

PagesWithTitle.propTypes = {
  title: PropTypes.string.isRequired,
  element: PropTypes.object.isRequired,
};

export default memo(PagesWithTitle);
