import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

// @mui
import { Typography, Stack } from '@mui/material';

// ----------------------------------------------------------------------
import { RHFTextField, RHFRadio, RHFMultiCheckbox, RHFSelectbox } from '../hook-form';
import AddressInformationsForm from './AddressInformationsForm';
import { setReccurssiveObjectKeys } from '../../helper';
import { formDefaultValues } from '../../constants';
import { CContext } from '../utils';

const AssetDetails = ({
  formfor,
  reset,
  getValues,
  setValue,
  allApplicant,
  watchForpropertyOwnership,
  currentAddressData,
  watchForAddType,
  watchForPropertyIdentiFied,
  watchIsPropertyIdentified,
}) => {
  const { t } = useTranslation('translation');
  const { isMobile } = useContext(CContext);
  const [isFirsttime, setIsFistTime] = useState(true);
  const watchForAddTypeBool = useMemo(() => watchForAddType === 'true', [watchForAddType]);

  useEffect(() => {
    if (watchForAddTypeBool) {
      reset(setReccurssiveObjectKeys({ ...getValues() }, `${formfor}.address`, currentAddressData));
    } else if (!isFirsttime) {
      reset(
        setReccurssiveObjectKeys({ ...getValues() }, `${formfor}.address`, {
          ...formDefaultValues.AssetDetailsDefaultValues.address,
        })
      );
    }
    setIsFistTime(false);
  }, [watchForAddTypeBool]);

  const handleOwnertype = (e) => {
    setValue(`${formfor}.propertyOwners`, e.target.value === 'INDIVIDUAL' ? null : [], { shouldValidate: true });
  };

  const PropertyOwners = useCallback(
    // ({ ownershipType }) =>
    () =>
      allApplicant.length > 0 && (
        <Stack spacing={3} mt={5} mb={5} className="width-100">
          {/* {ownershipType === 'INDIVIDUAL' ? (
            <RHFRadio
              name={`${formfor}.propertyOwners`}
              title="PROPERTY_OWNERS"
              required
              labels={allApplicant.map((item) => {
                const midleName = item.midleName ? ` ${item.midleName}` : '';
                const lastName = item.lastName ? ` ${item.lastName}` : '';
                return {
                  name: item.firstName + midleName + lastName,
                  value: item.id,
                };
              })}
            />
          ) : (
            <RHFMultiCheckbox
              required
              name={`${formfor}.propertyOwners`}
              title="PROPERTY_OWNERS"
              options={allApplicant.map((item) => {
                const midleName = item.midleName ? ` ${item.midleName}` : '';
                const lastName = item.lastName ? ` ${item.lastName}` : '';
                return {
                  label: item.firstName + midleName + lastName,
                  value: item.id,
                };
              })}
            />
          )} */}

          <RHFMultiCheckbox
            required
            name={`${formfor}.propertyOwners`}
            title="PROPERTY_OWNERS"
            options={allApplicant.map((item) => {
              const midleName = item.midleName ? ` ${item.midleName}` : '';
              const lastName = item.lastName ? ` ${item.lastName}` : '';
              return {
                label: item.firstName + midleName + lastName,
                value: item.id,
              };
            })}
          />
        </Stack>
      ),
    [allApplicant]
  );

  // Schema for basic information form

  return (
    <React.Fragment key={formfor}>
      {watchForPropertyIdentiFied === 'true' && (
        <Stack spacing={3} mb={5} className="width-100">
          <RHFRadio
            name={`${formfor}.isPropertyIdentified`}
            title="IS_THE_PROPERTY_IDENTIFIED"
            labels={[
              { name: 'YES', value: true },
              { name: 'NO', value: false },
            ]}
            singleLineRadio
            required
          />
        </Stack>
      )}

      {watchForPropertyIdentiFied === 'true' && (
        <Stack spacing={3} mb={5} className="width-100">
          <RHFRadio
            name={`${formfor}.isPropertyGovtFunded`}
            title="IS_THE_PROPERTY_GOVT_FUNDED"
            labels={[
              { name: 'YES', value: true },
              { name: 'NO', value: false },
            ]}
            singleLineRadio
            required
          />
        </Stack>
      )}

      <Stack spacing={3} mb={5} className="width-100">
        <RHFRadio
          name={`${formfor}.typeOfProperty`}
          title="TYPE_OF_PROPERTY"
          labels={[
            { name: 'ROW_HOUSE', value: 'ROW_HOUSE' },
            { name: 'VACANT_LAND', value: 'VACANT_LAND' },
            { name: 'BUNGALOW', value: 'BUNGALOW' },
            { name: 'GPLUS_TWO', value: 'GPLUS_TWO' },
            { name: 'HIGH_RISE', value: 'HIGH_RISE' },
            { name: 'RESIDENTIAL_CUM_COMMERCIAL', value: 'RESIDENTIAL_CUM_COMMERCIAL' },
            { name: 'COMMERCIAL_BUILDING', value: 'COMMERCIAL_BUILDING' },
            { name: 'COMMERCIAL_OFFICE', value: 'COMMERCIAL_OFFICE' },
            { name: 'COMMERCIAL_SHOP', value: 'COMMERCIAL_SHOP' },
          ]}
          required
        />
      </Stack>
      {watchIsPropertyIdentified === 'true' && (
        <Stack spacing={3} mb={5} className="width-50">
          <RHFSelectbox
            name={`${formfor}.propertyTitle`}
            label="PROPERTY_TITLE"
            menus={formDefaultValues.propertyTitle}
            required
          />
        </Stack>
      )}
      {currentAddressData?.id && (
        <Stack spacing={3} mb={5} className="width-100">
          <RHFRadio
            name={`${formfor}.isCurrentAddSameAsCollateralProperty`}
            title="SAME_AS_CURRENT_ADDRESS"
            labels={[
              { name: 'YES', value: true },
              { name: 'NO', value: false },
            ]}
            singleLineRadio
            required
          />
        </Stack>
      )}
      {getValues(`${formfor}.displayProposedAreaOfConstruction`) && (
        <Stack spacing={3} mb={5} className="width-50">
          <RHFTextField
            name={`${formfor}.proposedAreaOfConstruction`}
            label="PROPOSED_AREA_CONSTRUCTION"
            type="number"
            required
          />
        </Stack>
      )}
      <hr />
      <Typography
        className="width-100"
        variant="h4"
        gutterBottom
        sx={{ fontSize: '20px !important', marginBottom: 5, marginTop: 5, textAlign: 'left' }}
      >
        {t('COLLATERAL_PROPERTY_ADDRESS')}
      </Typography>
      <AddressInformationsForm
        name={{
          plotNumber: `${formfor}.address.plotNumber`,
          floorNumber: `${formfor}.address.floorNumber`,
          streetAndBuildingName: `${formfor}.address.streetAndBuildingName`,
          locality: `${formfor}.address.locality`,
          landmark: `${formfor}.address.landmark`,
          pinCode: `${formfor}.address.pinCode`,
          villageOrTown: `${formfor}.address.villageOrTown`,
          tehsil: `${formfor}.address.tehsil`,
          state: `${formfor}.address.state`,
          fromAddresses: false,
        }}
        reset={reset}
        getValues={getValues}
        setValue={setValue}
        disable={watchForAddTypeBool}
      />
      <hr />

      <Stack spacing={3} mt={5} mb={5} className="width-100">
        <RHFRadio
          //   showTooltip
          name={`${formfor}.propertyOwnership`}
          title="PROPERTY_OWNERSHIP"
          required
          labels={[
            { name: 'FATHER_MOTHER', value: 'FATHER_MOTHER_OWNED' },
            { name: 'GRAND_PARENTS', value: 'GRAND_PARENTS_OWNED' },
            { name: 'OWNED', value: 'OWNED' },
            { name: 'RENTED_PREMISES', value: 'RENTED_PREMISES' },
          ]}
          //   tooltipText={<>{t('OWNER_SHIP_HELP_TEXT')}</>}
          //   tooltipPlacement={isMobile ? 'top' : 'right'}
          onChange={handleOwnertype.bind(this)}
        />
      </Stack>
      {watchForpropertyOwnership === 'OWNED' && <PropertyOwners ownershipType={watchForpropertyOwnership} />}
    </React.Fragment>
  );
};

AssetDetails.propTypes = {
  formfor: PropType.string,
  reset: PropType.func,
  getValues: PropType.func,
  setValue: PropType.func,
  watchForAddType: PropType.string,
  currentAddressData: PropType.object,
  watchForPropertyIdentiFied: PropType.string,
  allApplicant: PropType.array,
  watchForpropertyOwnership: PropType.string,
  watchIsPropertyIdentified: PropType.string,
};

export default React.memo(AssetDetails);
