const TEN = 10;
const ONE_HUNDRED = 100;
const ONE_THOUSAND = 1000;
const ONE_LAKH = 100000;
const ONE_CR = 10000000;
const ONE_ARAB = 1000000000;
const ONE_KHARAB = 100000000000;
const MAX = 9007199254740991;
const MAX_SAFE_INTEGER = 9007199254740991;

const LESS_THAN_TWENTY = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
];

const LESS_THAN_HUNDRED = [
  'शून्य',
  'एक',
  'दो',
  'तीन',
  'चार',
  'पाँच',
  'छह',
  'सात',
  'आठ',
  'नौ',
  'दस',
  'ग्यारह',
  'बारह',
  'तेरह',
  'चौदह',
  'पन्द्रह',
  'सोलह',
  'सत्रह',
  'अठारह',
  'उन्नीस',
  'बीस',
  'इक्कीस',
  'बाईस',
  'तेईस',
  'चौबीस',
  'पच्चीस',
  'छब्बीस',
  'सत्ताईस',
  'अट्ठाईस',
  'उनतीस',
  'तीस',
  'इकतीस',
  'बत्तीस',
  'तैंतीस',
  'चौंतीस',
  'पैंतीस',
  'छत्तीस',
  'सैंतीस',
  'अड़तीस',
  'उनतालीस',
  'चालीस',
  'इकतालीस',
  'बयालीस',
  'तैंतालीस',
  'चौवालीस',
  'पैंतालीस',
  'छियालीस',
  'सैंतालीस',
  'अड़तालीस',
  'उनचास',
  'पचास',
  'इक्यावन',
  'बावन',
  'तिरेपन',
  'चौवन',
  'पचपन',
  'छप्पन',
  'सत्तावन',
  'अट्ठावन',
  'उनसठ',
  'साठ',
  'इकसठ',
  'बासठ',
  'तिरेसठ',
  'चौंसठ',
  'पैंसठ',
  'छियासठ',
  'सड़सठ',
  'अड़सठ',
  'उनहत्तर',
  'सत्तर',
  'इकहत्तर',
  'बहत्तर',
  'तिहत्तर',
  'चौहत्तर',
  'पचहत्तर',
  'छिहत्तर',
  'सतहत्तर',
  'अठहत्तर',
  'उनासी',
  'अस्सी',
  'इक्यासी',
  'बयासी',
  'तिरासी',
  'चौरासी',
  'पचासी',
  'छियासी',
  'सत्तासी',
  'अट्ठासी',
  'नवासी',
  'नब्बे',
  'इक्यानबे',
  'बानबे',
  'तिरानबे',
  'चौरानबे',
  'पंचानबे',
  'छियानबे',
  'सत्तानबे',
  'अट्ठानबे',
  'निन्यानबे',
];

const TENTHS_LESS_THAN_HUNDRED_HI = ['शून्य', 'दस', 'बीस', 'तीस', 'चालीस', 'पचास', 'साठ', 'सत्तर', 'अस्सी', 'नब्बे'];

const TENTHS_LESS_THAN_HUNDRED_EN = [
  'zero',
  'ten',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
];

const MORE_THAN_HUNDRED_EN = ['hundred', 'thousand', 'lakh', 'crore', 'arab', 'kharab'];

const MORE_THAN_HUNDRED_HI = ['सौ', 'हजार', 'लाख', 'करोड़', 'अरब', 'खरब'];

/**
 * Converts an integer into words.
 * If this.numberToBeConvert is decimal, the decimals will be removed.
 * @example toWords(12) => 'twelve'
 * @param {number|string} this.numberToBeConvert
 * @param {boolean} [asOrdinal] - Deprecated, use toWordsOrdinal() instead!
 * @returns {string}
 */
class NumberToWordsConvertor {
  constructor() {
    this.language = 'en';
    this.remainder = -1;
    this.numberToBeConvert = 0;
  }

  /**
   * @param {string} type
   */
  set lang(type) {
    this.language = type;
  }

  /**
   * @param {number} num
   */
  set number(num) {
    this.numberToBeConvert = parseInt(num, 10);
  }

  get words() {
    // if number is finite number and is in safe limit then call the function
    if (this.isFinite() && this.isSafeNumber()) {
      return this.generateWords(this.numberToBeConvert);
    }
    return '';
  }

  isFinite() {
    return !(
      typeof this.numberToBeConvert !== 'number' ||
      this.numberToBeConvert === Infinity ||
      this.numberToBeConvert === -Infinity
    );
  }

  isSafeNumber() {
    return typeof this.numberToBeConvert === 'number' && Math.abs(this.numberToBeConvert) <= MAX_SAFE_INTEGER;
  }

  get TENTHS_LESS_THAN_HUNDRED() {
    return this.language === 'hi' ? TENTHS_LESS_THAN_HUNDRED_HI : TENTHS_LESS_THAN_HUNDRED_EN;
  }

  get MORE_THAN_HUNDRED() {
    return this.language === 'hi' ? MORE_THAN_HUNDRED_HI : MORE_THAN_HUNDRED_EN;
  }

  get NON_REPETITIVE_NUMBER() {
    return this.language === 'hi' ? 100 : 20;
  }

  get NON_REPETITIVE_NUMBERS_ARRAY() {
    return this.language === 'hi' ? LESS_THAN_HUNDRED : LESS_THAN_TWENTY;
  }

  generateWords(number, words) {
    let word = '';
    let remainder;
    // We’re done
    if (number === 0) {
      return !words ? this.NON_REPETITIVE_NUMBERS_ARRAY[0] : words.join(' ').replace(/,$/, '');
    }

    // First run
    if (!words) {
      words = [];
    }

    if (number < this.NON_REPETITIVE_NUMBER) {
      remainder = 0;
      word = this.NON_REPETITIVE_NUMBERS_ARRAY[number];
    } else if (number < ONE_HUNDRED) {
      remainder = number % TEN;
      word = this.TENTHS_LESS_THAN_HUNDRED[Math.floor(number / TEN)];
      // In case of remainder, we need to handle it here to be able to add the “ ”
      if (remainder) {
        word += ` ${this.NON_REPETITIVE_NUMBERS_ARRAY[remainder]}`;
        remainder = 0;
      }
    } else if (number < ONE_THOUSAND) {
      remainder = number % ONE_HUNDRED;
      word = `${this.generateWords(Math.floor(number / ONE_HUNDRED))} ${this.MORE_THAN_HUNDRED[0]}`;
    } else if (number < ONE_LAKH) {
      remainder = number % ONE_THOUSAND;
      word = `${this.generateWords(Math.floor(number / ONE_THOUSAND))} ${this.MORE_THAN_HUNDRED[1]},`;
    } else if (number < ONE_CR) {
      remainder = number % ONE_LAKH;
      word = `${this.generateWords(Math.floor(number / ONE_LAKH))} ${this.MORE_THAN_HUNDRED[2]},`;
    } else if (number < ONE_ARAB) {
      remainder = number % ONE_CR;
      word = `${this.generateWords(Math.floor(number / ONE_CR))} ${this.MORE_THAN_HUNDRED[3]},`;
    } else if (number < ONE_KHARAB) {
      remainder = number % ONE_ARAB;
      word = `${this.generateWords(Math.floor(number / ONE_ARAB))} ${this.MORE_THAN_HUNDRED[4]},`;
    } else if (number <= MAX) {
      remainder = number % ONE_KHARAB;
      word = `${this.generateWords(Math.floor(number / ONE_KHARAB))} ${this.MORE_THAN_HUNDRED[5]},`;
    } else {
      return '';
    }
    words.push(word);
    return this.generateWords(remainder, words);
  }
}

export default NumberToWordsConvertor;
