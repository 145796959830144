// ----------------------------------------------------------------------

export default function Switch(theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
          marginTop: 20,
          width: '25%',
          '& .MuiTypography-root': {
            textTransform: 'capitalize',
          },
          '& .MuiRadio-root': {
            padding: '7px',
            '&:hover': {
              backgroundColor: '#5b65db25',
              padding: '7px',
            },
            '&.Mui-checked': {
              // backgroundColor: '#5b65db25',
              padding: '7px',
            },
          },
          '& .MuiTouchRipple-root ': {
            // display: 'none',
          },
          [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            width: '100%',
            '& .MuiTypography-root': {
              fontSize: 14,
            },
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        },
      },
    },
  };
}
