import { styled } from '@mui/material/styles';

const isErrorDisplay = (_) => _ === 'true';

export const assetDetailsContainerStyle = styled('div')(({ theme, iserrordisplayed }) => ({
  padding: '200px 120px 50px',
  backgroundColor: theme.palette.common.white,
  borderTop: 0,
  borderBottom: 0,
  '& .error': {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.error.main,
    marginLeft: 34,
    marginTop: 6,
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: isErrorDisplay(iserrordisplayed) ? '214px 20px 50px' : '170px 20px 50px',
  },
  '& form': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& hr': {
      width: '100%',
    },
  },
  '& .fullContainer': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  '& .mobile-view': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > div': {
      width: '49%',
    },
  },
  '& .width-50': {
    width: '49%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  '& .width-100': {
    width: '100%',
  },
  '& .processingOfApp': {
    textAlign: 'center',
    marginBottom: 0,
    '&.greyBox': {
      flex: 1,
      justifyContent: 'center',
      '& h3': {
        fontSize: 30,
        marginBottom: 0,
        borderRadius: 31,
        background: theme.palette.greyishColor,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        padding: 50,
        flex: 0.5,
      },
      '& .greyBoxSuccessPayment': {
        borderRadius: 31,
        background: theme.palette.greyishColor,
        '& h4': {
          fontSize: 26,
          marginBottom: 0,
          justifyContent: 'center',
          display: 'flex',
          padding: 40,
          alignItems: 'center',
          flex: 0.5,
          '& + h4:nth-child(1)': {
            paddingBottom: '7px !important',
          },
          '& + h4:nth-child(2)': {
            paddingTop: '7px !important',
          },
        },
      },
    },
    '& .childOfGrayBox': {
      padding: 30,
    },
    '& h5': {
      fontSize: 16,
      fontWeight: 400,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    '& h2': {
      fontSize: 22,
      marginBottom: 0,
      justifyContent: 'center',
      display: 'flex',
      padding: 15,
      alignItems: 'center',
      flex: 0.5,
      '& + h4:nth-child(1)': {
        paddingBottom: '7px !important',
      },
      '& + h4:nth-child(2)': {
        paddingTop: '7px !important',
      },
    },
    '& .paymentSuccessH5': {
      [theme.breakpoints.down('sm')]: {
        marginTop: '50px',
        marginBottom: '-18px',
      },
      fontSize: '22px',
    },
  },
  '& .MuiFormControlLabel-root': {
    '& .MuiRadio-root': {
      padding: '7px',
      marginRight: 10,
      '&:hover': {
        backgroundColor: '#5b65db25',
        padding: '7px',
      },
      '&.Mui-checked': {
        // backgroundColor: '#5b65db25',
        padding: '7px',
      },
    },
    '& .MuiCheckbox-root': {
      padding: '0 10px 0 0',
    },
  },
  '& .includingTax': {
    textAlign: 'center',
    fontWeight: 400,
    marginTop: 0,
    fontSize: 14,
    color: theme.palette.common.black,
    opacity: 0.6,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: isErrorDisplay(iserrordisplayed) ? '164px 20px 50px' : '120px 20px 80px',
    boxShadow: 'none',
  },
}));

export const assetDetailsContentStyle = styled('div')(({ theme }) => ({
  '& .addAssetBlock': {
    height: 56,
    alignItems: 'center',
    border: `1px solid ${theme.palette.greyBorder}`,
    padding: '0 14px',
    marginTop: 40,
    borderRadius: 4,
    cursor: 'pointer',
    '& h6': {
      marginBottom: 0,
    },
    '& .iconContainer': {
      width: 30,
      height: 26,
      borderRadius: 5,
      background: theme.palette.common.blue,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&.deleteIconContainer': {
        background: theme.palette.error.main,
      },
      '& svg': {
        fill: theme.palette.common.white,
        fontSize: 18,
      },
    },
  },
  '& .loadingButton': loadingButton(theme),
  '& .inputFieldContainer': {
    '&.plotFloorContainer': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      flexDirection: 'row',
      '& > div': {
        width: '49%',
      },
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      flexDirection: 'row',
      '& > div': {
        width: '49%',
      },
    },
  },
  '& .feePaymentBlock': {
    height: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      width: 500,
      margin: 'auto',
      '& .loadingButton': {
        justifyContent: 'center',
        '& button': {
          width: 260,
          marginTop: 50,
        },
      },
    },
    '& .MuiFormControlLabel-root': {
      marginTop: 0,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        marginTop: 0,
      },
    },
  },
}));

export const loadingButton = (theme) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '20px',
  background: theme.palette.common.white,
  zIndex: 9,
  '& button': {
    textTransform: 'uppercase',
  },
  [theme.breakpoints.up('sm')]: {
    position: 'static',
    padding: 0,
    zIndex: 'inherit',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    '& button': {
      width: 'auto',
      height: 44,
      borderRadius: 4,
      marginLeft: 16,
      boxShadow: 'none',
      fontSize: 14,
      fontWeight: 500,
      '& svg': {
        marginRight: 6,
      },
    },
  },
});
