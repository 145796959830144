import { useTranslation } from 'react-i18next';
import React, { useContext, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// @mui
import { Switch, AccordionDetails, AccordionSummary, Accordion, Typography, Stack, Box, Divider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';

// sections
import { ExpandMore as ExpandMoreIcon, CheckCircle as CheckCircleIcon, Error as ErrorIcon } from '@mui/icons-material';
// ----------------------------------------------------------------------
import { LoadingButton } from '@mui/lab';
import { sendOtp, verifyOtp, panVerification, getAccountDisabledField } from '../../services/applicantDetails';
import { RHFTextField, RHFSelectbox, RHFRadio, RHFPhoneNumberInput } from '../hook-form';
import AddressInformationsForm from './AddressInformationsForm';
import { formDefaultValues } from '../../constants';
import { getdescriptiveMessage, setReccurssiveObjectKeys, toasterService } from '../../helper';
import { CContext } from '../utils';

const ApplicantDetailsForm = ({
  expanded,
  setExpanded,
  reset,
  setValue,
  watchAddress,
  clearErrors,
  getValues,
  watchOccupation,
  watchcustomerType,
  formfor,
  watch,
  watchMaritalStatus,
  watchMSMENonIndividual,
  watchMSME,
  isSameAsCurrentAddress,
  isCommunicationAddress,
  isCommunicationAddressPermanent,
  watchGender,
  accordionStatus,
  checkValidAccordions,
  watchAccountId,
  watchPhoneNumber,
  watchOtpSent,
  watchOtpVerified,
  setShowLoader,
  setErrorMessage,
  watchVerifiedPhoneNumber,
  watchIndustry,
}) => {
  const { t } = useTranslation('translation');
  const [showUdhynamTooltip, setShowUdhynamTooltip] = useState(false);
  const { isMobile } = useContext(CContext);
  const [isApplicantDetailsDisabledAfterPANVerification, setIsApplicantDetailsDisabledAfterPANVerification] =
    useState(false);
  const [timer, setTimer] = useState();
  const [resend, setResend] = useState(false);
  const [, setVerfiedNumber] = useState();

  const timeOutCallback = useCallback(() => {
    setTimer((currentTimer) => currentTimer - 1);
  }, [timer]);

  useEffect(() => {
    if (timer > 0 && resend === true) {
      setTimeout(timeOutCallback, 1000);
    }
    if (timer === 0) setResend(false);
  }, [timer]);

  const onDateChange = (field, value) => {
    setValue(field, value);
    clearErrors(field);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    const prev = [...expanded];
    if (isExpanded || !prev.includes(panel)) {
      prev.push(panel);
    } else {
      const index = prev.indexOf(panel);
      prev.splice(index, 1);
    }
    setExpanded(prev);
    setTimeout(() => {
      checkValidAccordions(false);
    }, 1100);
  };

  const handleSwithChange = (field) => {
    const val = getValues(field) !== true;
    let values = setReccurssiveObjectKeys({ ...getValues() }, field, val);
    if (field === `${formfor}.applicantAddress.permanent.isSameAsCurrentAddress`) {
      if (val) {
        values = setReccurssiveObjectKeys({ ...values }, `${formfor}.applicantAddress.permanent`, {
          ...getValues(`${formfor}.applicantAddress.current`),
          isSameAsCurrentAddress: true,
          isCommunicationAddress: false,
        });
      } else {
        values = setReccurssiveObjectKeys({ ...values }, `${formfor}.applicantAddress.permanent`, {
          ...formDefaultValues.ApplicantDetailsDefaultValues.applicantAddress.permanent,
        });
      }
    }
    reset(values, { keepErros: true });
  };

  const handleRadioChange = ({ setTooltip, type }, event) => {
    const {
      target: { value },
    } = event;
    if ((type === 'address' && value === 'false') || (type === 'uddhyam' && value === 'true')) {
      setTooltip.call(null, true);
      setTimeout(setTooltip.bind(null, false), 7000);
    }
  };

  useEffect(() => {
    if (watchcustomerType === 'NONINDIVIDUAL') {
      reset(setReccurssiveObjectKeys({ ...getValues() }, `${formfor}.applicantMyDetails.occupation`, null));
    }
  }, [watchcustomerType]);

  const relationsWithClientMenu = useMemo(
    () =>
      (watchGender === 'MALE' && formDefaultValues.relationWithMainApplicantMale) ||
      (watchGender === 'FEMALE' && formDefaultValues.relationWithMainApplicantFemale) ||
      formDefaultValues.relationWithMainApplicant,
    [watchGender, watchcustomerType]
  );

  const convertTextToUpperCase = (field, e) => {
    if (e?.target?.value) {
      e.target.value = e.target.value.toUpperCase();
    }
    if (field && getValues(field)) {
      setValue(field, getValues(field).toUpperCase());
    }
    return e;
  };

  const panCard = async () => {
    // if (
    //   event.target.value.length === 10 &&
    //   event.target.name.includes('co-applicant') &&
    //   event.target.name.includes('panCardNumber')
    // ) {
    //   setShowLoader(true);
    //   const res = await getPanCardDetails(event.target.value);
    //   if (res?.data?.accountId) {
    //     setValue(`${formfor}.applicantMyDetails.crmAccountId`, res?.data?.accountId, { shouldValidate: true });
    //     setValue(`${formfor}.applicantMyDetails.name`, res?.data?.name, { shouldValidate: true });
    //     setValue(`${formfor}.applicantMyDetails.customerType`, res?.data?.customerType.toUpperCase(), {
    //       shouldValidate: true,
    //     });
    //   } else {
    //     setValue(`${formfor}.applicantMyDetails.crmAccountId`, '', { shouldValidate: true });
    //   }
    //   setShowLoader(false);
    // }
  };

  const otpSend = async () => {
    setShowLoader(true);
    if (watchPhoneNumber && watchPhoneNumber.length === 13) {
      const phoneNumber = watchPhoneNumber.replace('+91', '');
      const newPhone = parseInt(phoneNumber, 10);
      const res = await sendOtp({ phoneNumber: newPhone });
      if (res?.data?.Status === 'Success') {
        setResend(true);
        setTimer(60);
        setValue(`${formfor}.applicantMyDetails.otpVerified`, 'false', { shouldValidate: true });
        setValue(`${formfor}.applicantMyDetails.otpSent`, 'true');
        // setValue(`${formfor}.applicantMyDetails.details`, res.data.Details);
        toasterService(t('OTP_SENT'), 2, 'otpsent');
      } else {
        toasterService(t('ERROR_OCCURED_WHILE_SENDING_OTP'), 4, res?.data);
      }
      setShowLoader(false);
    } else {
      setShowLoader(false);
      setResend(false);
      toasterService(t('CHECK_MOB'), 4, 'otpnotsent');
    }
  };

  const _verifyOtp = async (e) => {
    if (e.target.value.length === 6) {
      setShowLoader(true);
      const res = await verifyOtp({
        otp_input: parseInt(e.target.value, 10),
        details: getValues(`${formfor}.applicantMyDetails.details`),
      });

      if (res?.data?.Status === 'Success') {
        // setValue(`${formfor}.applicantMyDetails.verifiedPhoneNumber`, watchPhoneNumber, { shouldValidate: true });
        setValue(`${formfor}.applicantMyDetails.phoneNumber`, watchPhoneNumber, { shouldValidate: true });
        setTimer(0);
        setResend(false);
        setVerfiedNumber(parseInt(watchPhoneNumber, 10));
        setValue(`${formfor}.applicantMyDetails.otpVerified`, 'true', { shouldValidate: true });
        toasterService(t('OTP_VERIFIED'), 2, 'otpverified');
      } else {
        toasterService(t('OTP_NOT_VERIFIED'), 4, 'otpnotverified');
        setValue(`${formfor}.applicantMyDetails.otpVerified`, 'false', { shouldValidate: true });
      }
      setShowLoader(false);
      setValue(`${formfor}.applicantMyDetails.otp`, '', { shouldValidate: true });
    }
  };

  const DisplayOtherContents = useCallback(
    () =>
      watchcustomerType === 'INDIVIDUAL' && watchOccupation === 'SELF_EMPLOYED_PROFESSIONAL' ? (
        <>
          {/* employement - self employed professional */}
          <Stack spacing={3} mb={5} className="width-50">
            <RHFSelectbox
              name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.constitutionOfCompany`}
              label="CONSTITUTION_OF_COMPANY"
              menus={formDefaultValues.constitutionOfCompanyValue}
              required
            />
          </Stack>
          <Stack spacing={3} mb={5} className="width-50">
            <RHFTextField
              name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.profession`}
              label="PROFESSION"
              onlyText
              required
            />
          </Stack>
          <Stack spacing={3} mb={5} className="width-50">
            <RHFSelectbox
              name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.sector`}
              label="SECTOR"
              menus={formDefaultValues.sectorValues}
              required
            />
          </Stack>
          <Stack spacing={3} mb={5} className="width-50">
            <RHFSelectbox
              name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.subSector`}
              label="SUB_SECTOR"
              menus={formDefaultValues.subSectorValuesForSEP}
              required
            />
          </Stack>
          <Stack spacing={3} mb={5} className="width-50">
            <RHFTextField name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.officialEmailId`} label="OFFICIAL_EMAIL" />
          </Stack>
          <Stack spacing={3} mb={5} className="width-50">
            <RHFPhoneNumberInput
              name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.officialPhoneNumber`}
              label="OFFICIAL_PHONE"
            />
          </Stack>
          <Stack spacing={3} mb={5} className="width-50">
            <RHFTextField
              name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.address`}
              label={t(`COMPANY_ADDRESS`)}
              multiline
              rows={2}
              required
            />
          </Stack>{' '}
          <Stack spacing={3} mb={5} className="width-50">
            <RHFRadio
              name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.itrFiledForIncomeDeclared`}
              title="ITR_FIELD"
              labels={[
                { name: 'YES', value: true },
                { name: 'NO', value: false },
              ]}
              required
            />
          </Stack>
          <Stack spacing={3} mb={5} className="width-50">
            <RHFTextField
              name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.monthlyNetIncome`}
              label="MONTHLY_NET_INCOME"
              type="number"
              required
            />
          </Stack>
        </>
      ) : (
        watchcustomerType === 'INDIVIDUAL' &&
        ['BANK_SALARIED_GOVT_PSU', 'BANK_SALARIED_PRIVATE', 'CASH_SALARIED'].includes(watchOccupation) > -1 && (
          <>
            <Stack spacing={3} mb={5} className="width-50">
              <RHFTextField name={`${formfor}.bankOrCash.nameOfCompany`} label="NAME_OF_COMPANY" required />
            </Stack>
            <Stack spacing={3} mb={5} className="width-50">
              <RHFTextField name={`${formfor}.bankOrCash.officialEmailId`} label="OFFICIAL_EMAIL" />
            </Stack>
            <Stack spacing={3} mb={5} className="width-50">
              <RHFSelectbox
                name={`${formfor}.bankOrCash.sector`}
                label="SECTOR"
                menus={formDefaultValues.sectorValues}
                required
              />
            </Stack>
            <Stack spacing={3} mb={5} className="width-50">
              <RHFSelectbox
                name={`${formfor}.bankOrCash.subSector`}
                label="SUB_SECTOR"
                menus={
                  (watchOccupation === 'BANK_SALARIED_GOVT_PSU' && formDefaultValues.subSectorForGovtPSU) ||
                  (watchOccupation === 'BANK_SALARIED_PRIVATE' && formDefaultValues.subSectorForBankSalaried) ||
                  formDefaultValues.subSectorForCashSalaried
                }
                required
              />
            </Stack>
            <Stack spacing={3} mb={5} className="width-50">
              <RHFPhoneNumberInput name={`${formfor}.bankOrCash.officialPhoneNumber`} label="OFFICIAL_PHONE" />
            </Stack>
            <Stack spacing={3} mb={5} className="width-50">
              <RHFTextField
                name={`${formfor}.bankOrCash.monthlyNetIncome`}
                label="MONTHLY_NET_INCOME"
                type="number"
                required
              />
            </Stack>
            <Stack spacing={3} mb={5} className="width-100">
              <RHFTextField
                name={`${formfor}.bankOrCash.address`}
                label={t(`COMPANY_ADDRESS`)}
                multiline
                rows={2}
                required
              />
            </Stack>{' '}
          </>
        )
      ),
    [watchcustomerType, watchOccupation]
  );

  const tooltipPlacement = useMemo(() => (isMobile ? 'top' : 'right'), [isMobile]);

  const callPANVerificationAPI = async () => {
    const obj = getValues();
    setShowLoader(true);
    const res = await panVerification(obj);
    if (res.status) {
      if (res.data.disableEdit === true) {
        setIsApplicantDetailsDisabledAfterPANVerification(true);
      }
    } else {
      setErrorMessage({ title: getdescriptiveMessage(res), show: true });
    }
    setShowLoader(false);
  };

  const callGetDisbledField = async (docType, pancardInputFieldName) => {
    const panNumberValue = getValues(pancardInputFieldName);
    if (panNumberValue && panNumberValue.length === 10) {
      convertTextToUpperCase(pancardInputFieldName);
      const obj = getValues();
      const panNumber = obj?.applicant?.applicantMyDetails?.panCardNumber;
      setShowLoader(true);
      const res = await getAccountDisabledField(panNumber);
      if (res.status) {
        if (res.data.disableEdit === true) {
          Object.entries(res.data.disableFieldObject).forEach(([key, value]) => {
            if (value) {
              setValue(`${formfor}.applicantMyDetails.${key}`, value, { shouldValidate: true });
            }
          });
          setIsApplicantDetailsDisabledAfterPANVerification(true);
        }
      } else {
        setErrorMessage({ title: res.error.t_message, show: true });
      }
    }
    setShowLoader(false);
  };

  const MsmeBlockOptions = useCallback(
    ({ watcForhMSME, fieldFor, showTooltipState }) =>
      `${watcForhMSME}` === 'true' ? (
        <>
          <Stack spacing={3} mb={5} className="width-100">
            <RHFRadio
              name={`${fieldFor}.msmeClassification`}
              title="MSME_CLASSIFICATION"
              labels={[
                { name: 'MICRO', value: 'MICRO' },
                { name: 'SMALL', value: 'SMALL' },
                { name: 'MEDIUM', value: 'MEDIUM' },
              ]}
              required
            />
          </Stack>
          <Stack spacing={3} mb={5} className="width-100">
            <RHFRadio
              name={`${fieldFor}.isTheMSMEUdyamRegistered`}
              title="UDHYAM_REGISTRATION"
              labels={[
                { name: 'YES', value: true },
                { name: 'NO', value: false },
              ]}
              showTooltip
              required
              tooltipOpen={showTooltipState}
              onChange={handleRadioChange.bind(this, { setTooltip: setShowUdhynamTooltip.bind(this), type: 'uddhyam' })}
              setShowTooltip={setShowUdhynamTooltip}
              tooltipText={
                <>
                  <span className="tooltiphead">{t('UDHYAM_REG_UPLOAD')}</span>
                </>
              }
              tooltipPlacement={tooltipPlacement}
            />
          </Stack>
        </>
      ) : null,
    [watchMSME]
  );

  const checkValueEntered = React.useCallback(
    (field) => {
      const formValues = getValues(field);
      const isEntered = Object.values(formValues || {}).some((x) => x);
      return (
        (!formValues || (isEntered && Object.keys(formValues || {}).length > 0)) && accordionStatus.includes(field)
      );
    },
    [accordionStatus]
  );

  return (
    <React.Fragment key={formfor}>
      <Accordion
        key="acc-1"
        expanded={expanded.includes(`${formfor}.applicantMyDetails`)}
        onChange={handleChange(`${formfor}.applicantMyDetails`)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>{t('MY_DETAILS')}</Typography>
          {checkValueEntered(`${formfor}.applicantMyDetails`) ? (
            <CheckCircleIcon sx={(theme) => ({ color: theme.palette.success.dark })} />
          ) : (
            <ErrorIcon sx={(theme) => ({ color: theme.palette.error.light })} />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={3} mb={5} className="width-50">
            <RHFSelectbox
              name={`${formfor}.applicantMyDetails.customerType`}
              label="CUSTOMER_TYPE"
              menus={formDefaultValues.customerType}
              required
            />
          </Stack>
          <Stack spacing={3} mb={5} className="width-50">
            <RHFTextField
              name={`${formfor}.applicantMyDetails.panCardNumber`}
              handleChange={panCard}
              label="PAN_CARD_NUMBER"
              alphaNumeric
              inputProps={{ maxlength: 10 }}
              hanleBlur={convertTextToUpperCase.bind(this, `${formfor}.applicantMyDetails.panCardNumber`)}
              // hanleBlur={() => callGetDisbledField(`PAN`, `${formfor}.applicantMyDetails.panCardNumber`)}
              allcaps
              required
              disabled={isApplicantDetailsDisabledAfterPANVerification}
            />
          </Stack>
          {watchcustomerType === 'INDIVIDUAL' && (
            <>
              {watchAccountId === '' && (
                <>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField
                      name={`${formfor}.applicantMyDetails.firstName`}
                      label="NAME_AS_PER_PAN_CARD"
                      onlyText
                      required
                      disabled={isApplicantDetailsDisabledAfterPANVerification}

                    // hanleBlur={callPANVerificationAPI}
                    // disabled={false}
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField
                      name={`${formfor}.applicantMyDetails.middleName`}
                      label="MIDDLE_NAME"
                      disabled={isApplicantDetailsDisabledAfterPANVerification}
                      onlyText
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField
                      name={`${formfor}.applicantMyDetails.lastName`}
                      label="LAST_NAME"
                      disabled={isApplicantDetailsDisabledAfterPANVerification}
                      onlyText
                      required
                    />
                  </Stack>

                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFPhoneNumberInput
                      name={`${formfor}.applicantMyDetails.phoneNumber`}
                      disabled={formfor === 'applicant'}
                      label="PHONE_NUMBER"
                      required
                    />
                    {formfor !== 'applicant' &&
                      parseInt(watchPhoneNumber, 10) !== parseInt(watchVerifiedPhoneNumber, 10) &&
                      watchOtpVerified !== 'true' &&
                      watchOtpVerified !== true && (
                        <Stack spacing={3} mb={5} className="width-50">
                          <LoadingButton
                            fullWidth
                            size="medium"
                            variant="contained"
                            disabled={timer > 0}
                            onClick={otpSend}
                            sx={(theme) => ({
                              background: theme.palette.common.blue,
                            })}
                          >
                            {resend === true ? t('RESEND_OTP') : t('SEND_OTP')}
                            {resend === true && `(${timer})`}
                          </LoadingButton>
                        </Stack>
                      )}
                  </Stack>
                  {watchOtpSent === 'true' && watchOtpVerified === 'false' && (
                    <Stack spacing={3} mb={5} className="width-50">
                      <RHFTextField
                        name={`${formfor}.applicantMyDetails.otp`}
                        label="OTP"
                        inputProps={{ maxLength: 6 }}
                        handleChange={_verifyOtp}
                        hanleBlur={convertTextToUpperCase.bind(this, `${formfor}.applicantMyDetails.otp`)}
                      />
                    </Stack>
                  )}

                  {watchAccountId === '' && (
                    <>
                      {' '}
                      <Stack spacing={3} mb={5} className="width-50">
                        <RHFTextField name={`${formfor}.applicantMyDetails.email`} label="PERSONAL_EMAIL" />
                      </Stack>
                    </>
                  )}

                  <Stack spacing={3} mb={4} className="width-50">
                    <RHFSelectbox
                      name={`${formfor}.applicantMyDetails.gender`}
                      label="GENDER"
                      menus={formDefaultValues.GENDER}
                      required
                    />
                  </Stack>

                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField
                      name={`${formfor}.applicantMyDetails.fatherName`}
                      label="FATHER_NAME"
                      onlyText
                      required
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField
                      name={`${formfor}.applicantMyDetails.motherName`}
                      label="MOTHER_NAME"
                      onlyText
                      required
                    />
                  </Stack>

                  <Stack spacing={3} mb={5} className="width-50">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
                        minDate={new Date().setFullYear(new Date().getFullYear() - 70)}
                        value={watch(`${formfor}.applicantMyDetails.dateOfBirth`) || null}
                        onChange={onDateChange.bind(this, `${formfor}.applicantMyDetails.dateOfBirth`)}
                        renderInput={(params) => (
                          <RHFTextField
                            {...params}
                            name={`${formfor}.applicantMyDetails.dateOfBirth`}
                            label="DOB"
                            dateField
                            required
                          />
                        )}
                        disabled={isApplicantDetailsDisabledAfterPANVerification}
                        hanleBlur={callPANVerificationAPI}
                      />
                    </LocalizationProvider>
                  </Stack>
                  {formfor !== 'applicant' && (
                    <Stack spacing={3} mb={4} className="width-50">
                      <RHFSelectbox
                        name={`${formfor}.applicantMyDetails.relationshipWithMainApplicant`}
                        label="RELATION_WITH_MAIN_APPLICANT"
                        menus={relationsWithClientMenu}
                        required
                      />
                    </Stack>
                  )}
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFSelectbox
                      name={`${formfor}.applicantMyDetails.maritalStatus`}
                      label="MARITAL_STATUS"
                      menus={formDefaultValues.MARITAL_STATUS}
                      required />

                  </Stack>
                  {watchMaritalStatus === 'MARRIED' && (
                    <Stack spacing={3} mb={5} className="width-50">
                      <RHFTextField
                        name={`${formfor}.applicantMyDetails.spouseName`}
                        label="SPOUSE_NAME"
                        onlyText
                        required
                      />
                    </Stack>
                  )}
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFSelectbox
                      name={`${formfor}.applicantMyDetails.religion`}
                      label="RELIGION"
                      menus={formDefaultValues.RELIGION}
                      required />

                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFSelectbox
                      name={`${formfor}.applicantMyDetails.casteCategory`}
                      label="CASTE_CATEGORY"
                      menus={formDefaultValues.CASTE_CATEGORY}
                      required />

                  </Stack>
                  {/* <hr /> */}
                  {/* <Divider /> */}

                  <Stack spacing={3} mb={5} className="width-50">
                  <RHFSelectbox name={`${formfor}.applicantMyDetails.identification`} label="IDENTIFICATION_DOCUMENT" menus={formDefaultValues.Identification} required />
                  </Stack>
                  {watch(`${formfor}.applicantMyDetails.identification`) === 'AADHAR_CARD_NUMBER' && (


                    <Stack spacing={3} mb={4} className="width-50">
                      <RHFTextField
                        name={`${formfor}.applicantMyDetails.aadharCardNumber`}
                        label="AADHAR_CARD_NUMBER"
                        type="number"
                        maxLength={12}
                        disabled={
                          getValues().applicant?.applicantMyDetails?.aadharCardNumber?.length > 0
                            ? isApplicantDetailsDisabledAfterPANVerification
                            : false
                        }
                      />
                    </Stack>
                  )}
                  {watch(`${formfor}.applicantMyDetails.identification`) === 'VOTER_ID' && (
                    <Stack spacing={3} mb={5} className="width-50">
                      <RHFTextField
                        name={`${formfor}.applicantMyDetails.voterId`}
                        label="VOTER_ID"
                        alphaNumeric
                        voterCardNo
                        inputProps={{ maxLength: 10 }}
                        hanleBlur={convertTextToUpperCase.bind(this, `${formfor}.applicantMyDetails.voterId`)}
                        allcaps
                        disabled={
                          getValues().applicant?.applicantMyDetails?.voterId?.length > 0
                            ? isApplicantDetailsDisabledAfterPANVerification
                            : false
                        }
                      />
                    </Stack>


                  )}
                  {watch(`${formfor}.applicantMyDetails.identification`) === 'DRIVING_LICENSE' && (

                    <Stack spacing={3} mb={5} className="width-50">
                      <RHFTextField
                        name={`${formfor}.applicantMyDetails.drivingLicense`}
                        label="DRIVING_LICENSE"
                        alphaNumericSlash
                        inputProps={{
                          maxlength: 16,
                        }}
                        maxlength={16}
                        disabled={
                          getValues().applicant?.applicantMyDetails?.drivingLicense?.length > 0
                            ? isApplicantDetailsDisabledAfterPANVerification
                            : false
                        }
                      />
                    </Stack>
                  )}
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFSelectbox
                      name={`${formfor}.applicantMyDetails.residencyStatus`}
                      label="RESIDENCY_STATUS"
                      menus={formDefaultValues.RESIDENCY_STATUS}
                      required />

                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFSelectbox
                      name={`${formfor}.applicantMyDetails.occupation`}
                      label="OCCUPATION"
                      menus={formDefaultValues.OCCUPATION}
                      required
                    />

                  </Stack>
                </>
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
      {watchcustomerType === 'INDIVIDUAL' && (
        <Accordion
          key="acc-2"
          expanded={expanded.includes(`${formfor}.applicantAddress`)}
          onChange={handleChange(`${formfor}.applicantAddress`)}
          TransitionProps={{
            'transition-timing-function': 'ease-in-out',
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content1" id="panel1a-header1">
            <Typography>{t('MY_ADDRESSES')}</Typography>
            {checkValueEntered(`${formfor}.applicantAddress`) ? (
              <CheckCircleIcon sx={(theme) => ({ color: theme.palette.success.dark })} />
            ) : (
              <ErrorIcon sx={(theme) => ({ color: theme.palette.error.light })} />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={3} mb={2}>
              <Typography variant="h4" component="h4">
                {t(`CURRENT_ADDRESSES`)}
              </Typography>
            </Stack>
            <Stack className="width-100" direction="row" mb={5} justifyContent="space-between" alignItems="center">
              <Typography>{t(`COMMUNICATE_ON_THIS_ADD`)}</Typography>
              <Switch
                checked={isCommunicationAddress}
                disabled={isCommunicationAddressPermanent}
                onChange={handleSwithChange.bind(this, `${formfor}.applicantAddress.current.isCommunicationAddress`)}
              />
            </Stack>
            <AddressInformationsForm
              name={{
                plotNumber: `${formfor}.applicantAddress.current.plotNumber`,
                floorNumber: `${formfor}.applicantAddress.current.floorNumber`,
                streetAndBuildingName: `${formfor}.applicantAddress.current.streetAndBuildingName`,
                locality: `${formfor}.applicantAddress.current.locality`,
                landmark: `${formfor}.applicantAddress.current.landmark`,
                pinCode: `${formfor}.applicantAddress.current.pinCode`,
                villageOrTown: `${formfor}.applicantAddress.current.villageOrTown`,
                tehsil: `${formfor}.applicantAddress.current.tehsil`,
                state: `${formfor}.applicantAddress.current.state`,
                villages: `${formfor}.applicantAddress.current.villages`,
                numberOfMonthsAtThisAddress: `${formfor}.applicantAddress.current.numberOfMonthsAtThisAddress`,
                propertyOwnership: `${formfor}.applicantAddress.current.propertyOwnership`,
              }}
              reset={reset}
              getValues={getValues}
              setValue={setValue}
              watchAddress={watchAddress}
              typeOfAddress={watchAddress?.current}
            />
            <hr />
            <Typography variant="h4" component="h4" mt={3} className="width-100">
              {t(`PERMANENT_ADDRESSES`)}
            </Typography>
            <Stack className="width-100" direction="row" mt={2} justifyContent="space-between" alignItems="center">
              <Typography>{t(`SAME_AS_CURRENT_ADD`)}</Typography>
              <Switch
                checked={isSameAsCurrentAddress}
                onChange={handleSwithChange.bind(this, `${formfor}.applicantAddress.permanent.isSameAsCurrentAddress`)}
              />
            </Stack>
            <Stack
              className="width-100"
              direction="row"
              mb={4}
              mt={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>{t(`COMMUNICATE_ON_THIS_ADD`)}</Typography>
              <Switch
                checked={isCommunicationAddressPermanent}
                disabled={isCommunicationAddress}
                onChange={handleSwithChange.bind(this, `${formfor}.applicantAddress.permanent.isCommunicationAddress`)}
              />
            </Stack>
            <AddressInformationsForm
              name={{
                plotNumber: `${formfor}.applicantAddress.permanent.plotNumber`,
                floorNumber: `${formfor}.applicantAddress.permanent.floorNumber`,
                streetAndBuildingName: `${formfor}.applicantAddress.permanent.streetAndBuildingName`,
                locality: `${formfor}.applicantAddress.permanent.locality`,
                landmark: `${formfor}.applicantAddress.permanent.landmark`,
                pinCode: `${formfor}.applicantAddress.permanent.pinCode`,
                villageOrTown: `${formfor}.applicantAddress.permanent.villageOrTown`,
                tehsil: `${formfor}.applicantAddress.permanent.tehsil`,
                state: `${formfor}.applicantAddress.permanent.state`,
                villages: `${formfor}.applicantAddress.permanent.villages`,
                numberOfMonthsAtThisAddress: `${formfor}.applicantAddress.permanent.numberOfMonthsAtThisAddress`,
                propertyOwnership: `${formfor}.applicantAddress.permanent.propertyOwnership`,
              }}
              reset={reset}
              getValues={getValues}
              setValue={setValue}
              watchAddress={watchAddress}
              disable={isSameAsCurrentAddress}
              typeOfAddress={watchAddress?.permanent}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {watchcustomerType === 'INDIVIDUAL' && (watchOccupation === null || watchOccupation === '')
        ? ''
        : watchAccountId === '' &&
        !['STUDENT', 'NOT_WORKING', 'HOME_MAKER', 'RETIRED_PENSIONER'].includes(watchOccupation) && (
          <Accordion
            key="acc-4"
            expanded={expanded.includes(`${formfor}.employmentInfo`)}
            onChange={handleChange(`${formfor}.employmentInfo`)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>{t('EMPLOYMENT_INFORMATINOS')}</Typography>
              {checkValueEntered(`${formfor}.employmentInfo`) ? (
                <CheckCircleIcon sx={(theme) => ({ color: theme.palette.success.dark })} />
              ) : (
                <ErrorIcon sx={(theme) => ({ color: theme.palette.error.light })} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              {watchcustomerType === 'NONINDIVIDUAL' && (
                <>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField name={`${formfor}.NONINDIVIDUAL.entityName`} label="ENTITY_NAME" required />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFSelectbox
                      name={`${formfor}.NONINDIVIDUAL.constitutionOfCompany`}
                      label="CONSTITUTION_OF_COMPANY"
                      menus={[
                        { name: 'PUBLIC_PRIVATE_PARTNERSHIP', value: 'PUBLIC_PRIVATE_PARTNERSHIP' },
                        { name: 'JOINT_VENTURE', value: 'JOINT_VENTURE' },
                        ...formDefaultValues.constitutionOfCompanyValue,
                      ]}
                      required
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFSelectbox
                      name={`${formfor}.NONINDIVIDUAL.sector`}
                      label="SECTOR"
                      menus={formDefaultValues.sectorValues}
                      required
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFSelectbox
                      name={`${formfor}.NONINDIVIDUAL.industry`}
                      label="INDUSTRY"
                      menus={formDefaultValues.INDUSTRY}
                      required
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFSelectbox
                      name={`${formfor}.NONINDIVIDUAL.subIndustry`}
                      label="SUB_INDUSTRY"
                      menus={formDefaultValues.SUB_INDUSTRY[0][watchIndustry]}
                      required
                    />
                  </Stack>

                  {/* <Stack spacing={3} mb={5} className="width-50">
                      <RHFSelectbox
                        name={`${formfor}.NONINDIVIDUAL.subSector`}
                        label="SUB_SECTOR"
                        menus={(formDefaultValues.subSectorValuesForSENP.sort())}
                        required
                      />
                    </Stack> */}
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField name={`${formfor}.NONINDIVIDUAL.officialEmailId`} label="OFFICIAL_EMAIL" />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFPhoneNumberInput
                      name={`${formfor}.NONINDIVIDUAL.officialPhoneNumber`}
                      label="OFFICIAL_PHONE"
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField
                      name={`${formfor}.NONINDIVIDUAL.monthlyNetIncome`}
                      label="MONTHLY_NET_INCOME"
                      type="number"
                      required
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField
                      name={`${formfor}.NONINDIVIDUAL.gstNo`}
                      label="GST_NO"
                      alphaNumeric
                      inputProps={{ maxlength: 15 }}
                      hanleBlur={convertTextToUpperCase.bind(this, `${formfor}.NONINDIVIDUAL.gstNo`)}
                      allcaps
                      required
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField name={`${formfor}.NONINDIVIDUAL.companyWebsite`} label="COMPANY_WEBSITE" required />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        maxDate={new Date(new Date().getTime()).setDate(new Date().getDate() - 1)}
                        value={watch(`${formfor}.NONINDIVIDUAL.dateOfEstablishment`) || null}
                        onChange={onDateChange.bind(this, `${formfor}.NONINDIVIDUAL.dateOfEstablishment`)}
                        renderInput={(params) => (
                          <RHFTextField
                            {...params}
                            name={`${formfor}.NONINDIVIDUAL.dateOfEstablishment`}
                            label="DATE_OF_ESTABLISHMENT"
                            dateField
                            required
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-100">
                    <RHFRadio
                      name={`${formfor}.NONINDIVIDUAL.isThisAnMSME`}
                      title="MSME"
                      labels={[
                        { name: 'YES', value: true },
                        { name: 'NO', value: false },
                      ]}
                      required
                    />
                  </Stack>
                  <MsmeBlockOptions
                    watcForhMSME={watchMSMENonIndividual}
                    fieldFor={`${formfor}.NONINDIVIDUAL`}
                    showTooltipState={showUdhynamTooltip}
                  />
                </>
              )}
              {watchcustomerType === 'INDIVIDUAL' && watchOccupation === 'SELF_EMPLOYED_BUSINESSPERSON' ? (
                <>
                  {/* employement - self employed Non-professional (Businessman) */}

                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFSelectbox
                      name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.constitutionOfCompany`}
                      label="CONSTITUTION_OF_COMPANY"
                      menus={formDefaultValues.constitutionOfCompanyValue}
                      required
                    />
                  </Stack>

                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField
                      name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.nameOfEnterprise`}
                      label="NAME_OF_ENTERPRISE"
                      required
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFSelectbox
                      name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.sector`}
                      label="SECTOR"
                      menus={formDefaultValues.sectorValues}
                      required
                    />
                  </Stack>

                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFSelectbox
                      name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.subSector`}
                      label="SUB_SECTOR"
                      menus={formDefaultValues.subSectorValuesForSENP}
                      required
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField
                      name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.officialEmailId`}
                      label="OFFICIAL_EMAIL"
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFPhoneNumberInput
                      name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.officialPhoneNumber`}
                      label="OFFICIAL_PHONE"
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField
                      name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.monthlyNetIncome`}
                      label="MONTHLY_NET_INCOME"
                      type="number"
                      required
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFTextField
                      name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.gstNo`}
                      label="GST_NO"
                      alphaNumeric
                      inputProps={{ maxlength: 15 }}
                      hanleBlur={convertTextToUpperCase.bind(this, `${formfor}.SELF_EMPLOYED_BUSINESSPERSON.gstNo`)}
                      allcaps
                      required
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFRadio
                      name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.isThisAnMSME`}
                      title="MSME"
                      labels={[
                        { name: 'YES', value: true },
                        { name: 'NO', value: false },
                      ]}
                      required
                    />
                  </Stack>
                  <Stack spacing={3} mb={5} className="width-50">
                    <RHFRadio
                      name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.itrFiledForIncomeDeclared`}
                      title="ITR_FIELD"
                      labels={[
                        { name: 'YES', value: true },
                        { name: 'NO', value: false },
                      ]}
                      required
                    />
                  </Stack>
                  <MsmeBlockOptions
                    watcForhMSME={watchMSME}
                    fieldFor={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON`}
                    showTooltipState={showUdhynamTooltip}
                  />
                </>
              ) : (
                <DisplayOtherContents />
              )}
            </AccordionDetails>
          </Accordion>
        )}
    </React.Fragment>
  );
};

ApplicantDetailsForm.propTypes = {
  expanded: PropTypes.array,
  setExpanded: PropTypes.func,
  watchOccupation: PropTypes.string,
  reset: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  formfor: PropTypes.string,
  watch: PropTypes.func,
  watchcustomerType: PropTypes.string,
  watchMaritalStatus: PropTypes.string,
  watchMSMENonIndividual: PropTypes.any,
  watchMSME: PropTypes.string,
  isSameAsCurrentAddress: PropTypes.bool,
  isCommunicationAddress: PropTypes.bool,
  isCommunicationAddressPermanent: PropTypes.bool,
  watchGender: PropTypes.string,
  accordionStatus: PropTypes.array,
  checkValidAccordions: PropTypes.func,
  watchAddress: PropTypes.object,
  watchAccountId: PropTypes.string,
  watchPhoneNumber: PropTypes.string,
  watchOtpSent: PropTypes.string,
  watchOtpVerified: PropTypes.string,
  watchPanCard: PropTypes.string,
  setShowLoader: PropTypes.bool,
  setErrorMessage: PropTypes.func,
  watchVerifiedPhoneNumber: PropTypes.string,
  watchIndustry: PropTypes.string,
};

export default React.memo(ApplicantDetailsForm);

