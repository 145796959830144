import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
// @mui
import { Box } from '@mui/material';
import Logo from '../../assets/images/habilelabs.png';
import { FormProvider, RHFSelectbox } from '../hook-form';
import { CContext } from './CContextProvider';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  zIndex: 9,
  width: '100%',
  display: 'flex',
  marginBottom: '18px',
  alignItems: 'center',
  position: 'static',
  padding: theme.spacing(2, 5),
  justifyContent: 'space-between',
  backgroundColor: theme.palette.common.blue,
  '& img': {
    height: 50,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: theme.spacing(2, 2.5),
  },
}));

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, ...other }, ref) => {
  const { lang, changeLanguage } = useContext(CContext);
  const handleChange = (event) => {
    if (event.target.value !== lang) {
      changeLanguage();
    }
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      lang,
    },
  });

  return (
    <>
      <Box ref={ref} {...other}>
        <RootStyle>
          <HeaderStyle>
            <img src={Logo} alt="logo" />
            <FormProvider methods={methods}>
              <RHFSelectbox
                name="lang"
                label=""
                onChange={handleChange}
                menus={[
                  { name: 'English', value: 'en' },
                  { name: 'हिन्दी', value: 'hi' },
                ]}
                languageDropdown
              />
            </FormProvider>
          </HeaderStyle>
          {children}
        </RootStyle>
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Page;
