import { styled, Box } from '@mui/material';
// import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

export const applicationFeedbackModuleStyle = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: '0',
  right: '0',

  border: '1px solid #000',
  backgroundColor: theme.palette.common.white,
  zIndex: 99,
  padding: '15px',
  [theme.breakpoints.up('sm')]: {
    minWidth: 500,
    margin: 'auto',
  },
  '& .feedbackBox': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: '25px',
  },
  '& .feedbackInput': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },

  '& .feedbackButton': {
    fontSize: 14,
    borderRadius: '4px',
    fontWeight: 500,
    background: theme.palette.common.blue,
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    padding: '8px 60px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: 'auto',
  },
}));

