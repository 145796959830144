/* eslint-disable import/no-unresolved */
import { formDefaultValues } from "src/constants";


export const transformAddressResponseAsPerHTMLFormFields = (addressResponseArray) => {
    const transformedAddressResponseArray = [];

    for (let index = 0; index < addressResponseArray.length; index++) {
        const addressObject = {
            ...addressResponseArray[index]
        };
        
        if (addressObject.addressType === "CURRENT_ADDRESS") {
            addressObject.addressType = "CURRENT";
        } else if (addressObject.addressType === "PERMANENT_ADDRESS") {
            addressObject.addressType = "PERMANENT";
        } else if (addressObject.addressType === "ASSET_ADDRESS") {
            addressObject.addressType = "ASSET";
        }
        
        addressObject.propertyOwnership = formDefaultValues.propertyOwnershipHTMLFormValue[addressObject.propertyOwnership];
        transformedAddressResponseArray.push(addressObject);
    }

    return transformedAddressResponseArray;
};

export const transformEmploymentPayloadAsPerHTMLFormFields = (employmentResponse) => {
	if (employmentResponse?.sector) {
		employmentResponse.sector = formDefaultValues?.sectorAPIValue[employmentResponse?.sector]	
	}
	if (employmentResponse?.subSector) {
		employmentResponse.subSector	= formDefaultValues?.subSectorAPIValue[employmentResponse?.subSector]
	}

	return employmentResponse
}