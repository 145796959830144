/* eslint-disable no-import-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import constants from './constants';

const { ROUTE } = constants;
const address = {
  plotNumber: '',
  floorNumber: '',
  streetAndBuildingName: '',
  locality: '',
  landmark: '',
  pinCode: '',
  villageOrTown: '',
  villages: [],
  tehsil: '',
  state: '',
};

export default  {
  locationTypeValue: [
    {
      name: 'RURAL',
      value: 'RURAL',
    },
    {
      name: 'URBAN',
      value: 'URBAN',
    },
    {
      name: 'SEMI_URBAN',
      value: 'SEMI_URBAN',
    },
    {
      name: 'METRO',
      value: 'METRO',
    },
  ],

  PROVISIONAL_OFFER_MATRIX : {
    "HL": {
        "CAT_A": {
            "YES": {
                "ROI": {
                    "BANK_SALARIED_GOVT_PSU": "12.00%",
                    "BANK_SALARIED_PRIVATE": "14.00%",
                    "CASH_SALARIED": "16.00%",
                    "SELF_EMPLOYED_BUSINESSPERSON": "12.00%",
                    "SELF_EMPLOYED_PROFESSIONAL": "11.50%"
                },
                "TENURE": {
                    "BANK_SALARIED_GOVT_PSU": "360",
                    "BANK_SALARIED_PRIVATE": "300",
                    "CASH_SALARIED": "180",
                    "SELF_EMPLOYED_BUSINESSPERSON": "240",
                    "SELF_EMPLOYED_PROFESSIONAL": "240"
                }
            },
            "NO": {
                "ROI": {
                    "BANK_SALARIED_GOVT_PSU": "12.00%",
                    "BANK_SALARIED_PRIVATE": "14.00%",
                    "CASH_SALARIED": "16.00%",
                    "SELF_EMPLOYED_BUSINESSPERSON": "15.50%",
                    "SELF_EMPLOYED_PROFESSIONAL": "15.00%"
                },
                "TENURE": {
                    "BANK_SALARIED_GOVT_PSU": "360",
                    "BANK_SALARIED_PRIVATE": "300",
                    "CASH_SALARIED": "180",
                    "SELF_EMPLOYED_BUSINESSPERSON": "180",
                    "SELF_EMPLOYED_PROFESSIONAL": "180"
                }
            }
        },
        "REST" : {
            "YES": {
                "ROI": {
                    "BANK_SALARIED_GOVT_PSU": "14.50%",
                    "BANK_SALARIED_PRIVATE": "16.50%",
                    "CASH_SALARIED": "17.50%",
                    "SELF_EMPLOYED_BUSINESSPERSON": "15.50%",
                    "SELF_EMPLOYED_PROFESSIONAL": "15.00%"
                },
                "TENURE": {
                    "BANK_SALARIED_GOVT_PSU": "300",
                    "BANK_SALARIED_PRIVATE": "240",
                    "CASH_SALARIED": "120",
                    "SELF_EMPLOYED_BUSINESSPERSON": "240",
                    "SELF_EMPLOYED_PROFESSIONAL": "240"
                }
            },
            "NO": {
                "ROI": {
                    "BANK_SALARIED_GOVT_PSU": "14.50%",
                    "BANK_SALARIED_PRIVATE": "16.50%",
                    "CASH_SALARIED": "17.50%",
                    "SELF_EMPLOYED_BUSINESSPERSON": "17.50%",
                    "SELF_EMPLOYED_PROFESSIONAL": "17.00%"
                },
                "TENURE": {
                    "BANK_SALARIED_GOVT_PSU": "300",
                    "BANK_SALARIED_PRIVATE": "240",
                    "CASH_SALARIED": "120",
                    "SELF_EMPLOYED_BUSINESSPERSON": "180",
                    "SELF_EMPLOYED_PROFESSIONAL": "180"
                }
            }
        }
    },
    "NHL": {
        "CAT_A": {
            "UPTO_10_LAC": {
                "ROI": {
                    "BANK_SALARIED_GOVT_PSU": "16.00%",
                    "BANK_SALARIED_PRIVATE": "16.00%",
                    "CASH_SALARIED": "16.00%",
                    "SELF_EMPLOYED_BUSINESSPERSON": "16.00%",
                    "SELF_EMPLOYED_PROFESSIONAL": "15.50%"
                },
                "TENURE": {
                    "BANK_SALARIED_GOVT_PSU": "180",
                    "BANK_SALARIED_PRIVATE": "180",
                    "CASH_SALARIED": "180",
                    "SELF_EMPLOYED_BUSINESSPERSON": "180",
                    "SELF_EMPLOYED_PROFESSIONAL": "180"
                }
            },
            "10_TO_25_LAC": {
                "ROI": {
                    "BANK_SALARIED_GOVT_PSU": "15.00%",
                    "BANK_SALARIED_PRIVATE": "15.00%",
                    "CASH_SALARIED": "15.00%",
                    "SELF_EMPLOYED_BUSINESSPERSON": "15.00%",
                    "SELF_EMPLOYED_PROFESSIONAL": "14.50%"
                },
                "TENURE": {
                    "BANK_SALARIED_GOVT_PSU": "180",
                    "BANK_SALARIED_PRIVATE": "180",
                    "CASH_SALARIED": "180",
                    "SELF_EMPLOYED_BUSINESSPERSON": "180",
                    "SELF_EMPLOYED_PROFESSIONAL": "180"
                }
            },
            "25_TO_50_LAC": {
                "ROI": {
                    "BANK_SALARIED_GOVT_PSU": "13.00%",
                    "BANK_SALARIED_PRIVATE": "13.00%",
                    "CASH_SALARIED": "13.00%",
                    "SELF_EMPLOYED_BUSINESSPERSON": "13.00%",
                    "SELF_EMPLOYED_PROFESSIONAL": "12.50%"
                },
                "TENURE": {
                    "BANK_SALARIED_GOVT_PSU": "180",
                    "BANK_SALARIED_PRIVATE": "180",
                    "CASH_SALARIED": "180",
                    "SELF_EMPLOYED_BUSINESSPERSON": "180",
                    "SELF_EMPLOYED_PROFESSIONAL": "180"
                }
            },
            "ABOVE_50_LAC": {
                "ROI": {
                    "BANK_SALARIED_GOVT_PSU": "12.75%",
                    "BANK_SALARIED_PRIVATE": "12.75%",
                    "CASH_SALARIED": "12.75%",
                    "SELF_EMPLOYED_BUSINESSPERSON": "12.75%",
                    "SELF_EMPLOYED_PROFESSIONAL": "12.25%"
                },
                "TENURE": {
                    "BANK_SALARIED_GOVT_PSU": "180",
                    "BANK_SALARIED_PRIVATE": "180",
                    "CASH_SALARIED": "180",
                    "SELF_EMPLOYED_BUSINESSPERSON": "180",
                    "SELF_EMPLOYED_PROFESSIONAL": "180"
                }
            },

        },
        "REST": {
            "UPTO_10_LAC": {
                "ROI": {
                    "BANK_SALARIED_GOVT_PSU": "18.00%",
                    "BANK_SALARIED_PRIVATE": "18.00%",
                    "CASH_SALARIED": "18.00%",
                    "SELF_EMPLOYED_BUSINESSPERSON": "18.00%",
                    "SELF_EMPLOYED_PROFESSIONAL": "17.50%"
                },
                "TENURE": {
                    "BANK_SALARIED_GOVT_PSU": "180",
                    "BANK_SALARIED_PRIVATE": "180",
                    "CASH_SALARIED": "180",
                    "SELF_EMPLOYED_BUSINESSPERSON": "180",
                    "SELF_EMPLOYED_PROFESSIONAL": "180"
                }
            },
            "10_TO_25_LAC": {
                "ROI": {
                    "BANK_SALARIED_GOVT_PSU": "16.00%",
                    "BANK_SALARIED_PRIVATE": "16.00%",
                    "CASH_SALARIED": "16.00%",
                    "SELF_EMPLOYED_BUSINESSPERSON": "16.00%",
                    "SELF_EMPLOYED_PROFESSIONAL": "15.50%"
                },
                "TENURE": {
                    "BANK_SALARIED_GOVT_PSU": "180",
                    "BANK_SALARIED_PRIVATE": "180",
                    "CASH_SALARIED": "180",
                    "SELF_EMPLOYED_BUSINESSPERSON": "180",
                    "SELF_EMPLOYED_PROFESSIONAL": "180"
                }
            },
            "25_TO_50_LAC": {
                "ROI": {
                    "BANK_SALARIED_GOVT_PSU": "15.00%",
                    "BANK_SALARIED_PRIVATE": "15.00%",
                    "CASH_SALARIED": "15.00%",
                    "SELF_EMPLOYED_BUSINESSPERSON": "15.00%",
                    "SELF_EMPLOYED_PROFESSIONAL": "14.50%"
                },
                "TENURE": {
                    "BANK_SALARIED_GOVT_PSU": "180",
                    "BANK_SALARIED_PRIVATE": "180",
                    "CASH_SALARIED": "180",
                    "SELF_EMPLOYED_BUSINESSPERSON": "180",
                    "SELF_EMPLOYED_PROFESSIONAL": "180"
                }
            },
            "ABOVE_50_LAC": {
                "ROI": {
                    "BANK_SALARIED_GOVT_PSU": "14.00%",
                    "BANK_SALARIED_PRIVATE": "14.00%",
                    "CASH_SALARIED": "14.00%",
                    "SELF_EMPLOYED_BUSINESSPERSON": "14.00%",
                    "SELF_EMPLOYED_PROFESSIONAL": "13.50%"
                },
                "TENURE": {
                    "BANK_SALARIED_GOVT_PSU": "180",
                    "BANK_SALARIED_PRIVATE": "180",
                    "CASH_SALARIED": "180",
                    "SELF_EMPLOYED_BUSINESSPERSON": "180",
                    "SELF_EMPLOYED_PROFESSIONAL": "180"
                }
            }
        }  
    }
},

endUseForMSME: [
    {
      name: 'WORKING_CAPITAL',
      value: 'WORKING_CAPITAL',
    },
    {
      name: 'FUND_CAPITAL_EXPENDITURE',
      value: 'FUND_CAPITAL_EXPENDITURE',
    },
    {
      name: 'BALANCE_TRANSFER',
      value: 'BALANCE_TRANSFER',
    },
    {
      name: 'BUSINESS_EXPANSION',
      value: 'BUSINESS_EXPANSION',
    },
    {
      name: 'PURCHASE_OR_CONSTRUCTION_COMM_PROPERTY',
      value: 'PURCHASE_OR_CONSTRUCTION_COMM_PROPERTY',
    },
  ],
  Identification: [
    {
      name: 'AADHAR_CARD_NUMBER',
      value: 'AADHAR_CARD_NUMBER',
    },
    {
      name: 'DRIVING_LICENSE',
      value: 'DRIVING_LICENSE',
    },
    {
      name: 'VOTER_ID',
      value: 'VOTER_ID',
    }
  ],

  products: [
    {
      name: 'PURCHASE',
      value: 'NEW_PROPERTY_PURCHASE',
    },
    {
      name: 'RESALE_PROPERTY_PURCHASE',
      value: 'RESALE_PROPERTY_PURCHASE',
    },
    {
      name: 'CONS',
      value: 'CONSTRUCTION',
    },
    {
      name: 'PUR_CON',
      value: 'LAND_PURCHASE_AND_HOME_CONSTRUCTION',
    },
    {
      name: 'REPAIR',
      value: 'REPAIR_AND_RENOVATION',
    },
    {
      name: 'MSME_PRODUCT',
      value: 'MSME',
    },
    {
      name: 'HOME_EQ',
      value: 'HOME_EQUITY_LOAN_AGAINST_PROPERTY',
    },
  ],
  customerType: [
    {
      name: 'INDIVIDUAL',
      value: 'INDIVIDUAL',
    },
    {
      name: 'NON_INDIVIDUAL',
      value: 'NONINDIVIDUAL',
    },
  ],

  propertyOwnership: [
    {
      name: 'FATHER/MOTHER OWNED',
      value: 'FATHER/MOTHER OWNED',
    },
    {
      name: 'GRAND PARENTS OWNED',
      value: 'GRAND PARENTS OWNED',
    },
    {
      name: 'OWNED',
      value: 'OWNED',
    },
    {
      name: 'RENTED PREMISES',
      value: 'RENTED PREMISES',
    },
  ],

	propertyOwnershipAPIValue : {
		'FATHER/MOTHER OWNED' : 'FATHER_MOTHER_OWNED',
		'GRAND PARENTS OWNED' : 'GRAND_PARENTS_OWNED',
		'OWNED' : 'OWNED',
		'RENTED PREMISES' : 'RENTED_PREMISES'
	},

	propertyOwnershipHTMLFormValue : {
		'FATHER_MOTHER_OWNED' : 'FATHER/MOTHER OWNED',
		'GRAND_PARENTS_OWNED' : 'GRAND PARENTS OWNED',
		'OWNED' : 'OWNED',
		"RENTED_PREMISES" : "RENTED PREMISES"
	},

	sectorAPIValue : {
		'AGRICULTURE' : 'AGRICULTURE',
		'ANIMAL_FARMING_POULTRY' : 'ANIMAL_FARMING_POULTRY',
		'MANUFACTURER' : 'MANUFACTURER',
		'REAL_ESTATE' : 'REAL_ESTATE',
		'RETAILER' : 'RETAILER',
		'SERVICE' : 'SERVICE',
		'WHOLESALER_DISTRIBUTOR' : 'WHOLESALER_DISTRIBUTOR'
	},

	subSectorAPIValue : {
		'GOVERNMENT':'GOVERNMENT'
	},

  sectorValues: [
    { name: 'AGRICULTURE', value: 'AGRICULTURE' },
    {
      name: 'ANIMAL_FARMING_POULTRY',
      value: 'ANIMAL_FARMING_POULTRY',
    },
    { name: 'MANUFACTURER', value: 'MANUFACTURER' },
    { name: 'REAL_ESTATE', value: 'REAL_ESTATE' },
    { name: 'RETAILER', value: 'RETAILER' },
    { name: 'SERVICE', value: 'SERVICE' },
    {
      name: 'WHOLESALER_DISTRIBUTOR',
      value: 'WHOLESALER_DISTRIBUTOR',
    },
  ],
  subSectorForGovtPSU: [{ name: 'GOVERNMENT', value: 'GOVERNMENT' }],
  subSectorForBankSalaried: [{ name: 'PRIVATE_COMPANY', value: 'PRIVATE_COMPANY' }],
  subSectorForCashSalaried: [{ name: 'CASH', value: 'CASH' }],

  subSectorValuesForSENP: [
    { name: 'ADVERTISEMENT', value: 'ADVERTISEMENT' },
    { name: 'AGENTS', value: 'AGENTS' },
    { name: 'AGRICULTURE', value: 'AGRICULTURE' },
    // { name: 'ASTROLOGER', value: 'ASTROLOGER' },
    { name: 'BRICKKILN', value: 'BRICKKILN' },
    { name: 'BUILDER', value: 'BUILDER' },
    { name: 'BUILDING_MATERIAL_SUPPLIERS', value: 'BUILDING_MATERIAL_SUPPLIERS' },
    { name: 'DAILY_WAGE_WORKER', value: 'DAILY_WAGE_WORKER' },
    { name: 'DAIRY_OR_MILK_TRADING', value: 'DAIRY_OR_MILK_TRADING' },
    { name: 'DIAMOND_GEMS_STONES', value: 'DIAMOND_GEMS_STONES' },
    { name: 'DISTRIBUTORS', value: 'DISTRIBUTORS' },
    { name: 'DRIVER', value: 'DRIVER' },
    { name: 'EATERY_OR_FASTFOOD', value: 'EATERY_OR_FASTFOOD' },
    { name: 'ELECTRIC', value: 'ELECTRIC' },
    { name: 'ELECTICIAN_PLUMBER', value: 'ELECTICIAN_PLUMBER' },
    { name: 'FABRICTORS_OR_FURNITURE', value: 'FABRICTORS_OR_FURNITURE' },
    { name: 'FANCY_STORE', value: 'FANCY_STORE' },
    { name: 'FERTILIZER_PERSTICIDES', value: 'FERTILIZER_PERSTICIDES' },
    { name: 'FLOUR_MILL', value: 'FLOUR_MILL' },
    { name: 'FMCG_GOODS', value: 'FMCG_GOODS' },
    { name: 'FOOD_GRAIN', value: 'FOOD_GRAIN' },
    { name: 'FOOTWEAR_BAGS', value: 'FOOTWEAR_BAGS' },
    { name: 'FRUIT_VEGETABLE', value: 'FRUIT_VEGETABLE' },
    { name: 'GARMENT_SHOP', value: 'GARMENT_SHOP' },
    { name: 'HAIR_SALOONS', value: 'HAIR_SALOONS' },
    { name: 'HOTELS_RESTAURANTS', value: 'HOTELS_RESTAURANTS' },
    { name: 'INSTALLATION_WORK', value: 'INSTALLATION_WORK' },
    { name: 'IRONWORK', value: 'IRONWORK' },
    { name: 'JEWELLERY', value: 'JEWELLERY' },
    { name: 'JOB_WORK', value: 'JOB_WORK' },
    { name: 'KITCHEN_ITEMS', value: 'KITCHEN_ITEMS' },
    { name: 'LAUNDARY', value: 'LAUNDARY' },
    { name: 'LIGHT_MANDAP_FLOWER', value: 'LIGHT_MANDAP_FLOWER' },
    { name: 'MASON', value: 'MASON' },
    { name: 'MEDICAL_STORES', value: 'MEDICAL_STORES' },
    { name: 'MINES_AND_STONE_CRUSHER', value: 'MINES_AND_STONE_CRUSHER' },
    {
      name: 'MOBILE_COMPUTER_ACCESSORIES',
      value: 'MOBILE_COMPUTER_ACCESSORIES',
    },
    { name: 'OTHER_MANUFACTURER', value: 'OTHER_MANUFACTURER' },
    { name: 'OTHER_RETALER_TRADER', value: 'OTHER_RETALER_TRADER' },
    { name: 'OTHER_SERVICES', value: 'OTHER_SERVICES' },
    {
      name: 'PHOTO_COPIER_CYBER_PRINTING',
      value: 'PHOTO_COPIER_CYBER_PRINTING',
    },
    { name: 'PHOTO_STUDIO_OR_EVENT', value: 'PHOTO_STUDIO_OR_EVENT' },
    { name: 'PLASTIC_PAPER_ITEMS', value: 'PLASTIC_PAPER_ITEMS' },
    { name: 'PROVISION_STORE', value: 'PROVISION_STORE' },
    { name: 'ASTROLOGER', value: 'ASTROLOGER' },
    { name: 'RENTAL_INCOME', value: 'RENTAL_INCOME' },
    { name: 'REPAIRING_WORK', value: 'REPAIRING_WORK' },
    { name: 'SCHOOLS_TUITION', value: 'SCHOOLS_TUITION' },
    { name: 'SCARP_BUSINESS', value: 'SCARP_BUSINESS' },
    { name: 'SCULPTURE_HANDCRAFT', value: 'SCULPTURE_HANDCRAFT' },
    {
      name: 'SERVICE_SECTOR_CIVIL_CONTRACTOR',
      value: 'SERVICE_SECTOR_CIVIL_CONTRACTOR',
    },
    {
      name: 'SERVICE_SECTOR_LABOUR_CONTRACTOR',
      value: 'SERVICE_SECTOR_LABOUR_CONTRACTOR',
    },
    {
      name: 'SERVICE_SECTOR_OR_PAINT_CONTRACTOR',
      value: 'SERVICE_SECTOR_OR_PAINT_CONTRACTOR',
    },
    { name: 'SWEETS_SAIVERY', value: 'SWEETS_SAIVERY' },
    { name: 'TAILOR_STICHING', value: 'TAILOR_STICHING' },
    { name: 'TEA_STALL', value: 'TEA_STALL' },
    { name: 'TOURS_AND_TRAVELS', value: 'TOURS_AND_TRAVELS' },
    {
      name: 'TRANSPORTERS_EARTHMOVER_HIRING',
      value: 'TRANSPORTERS_EARTHMOVER_HIRING',
    },
    { name: 'WATER_SUPPLIER', value: 'WATER_SUPPLIER' },
    { name: 'WORKSHOPS_AUTO_OR_MECH', value: 'WORKSHOPS_AUTO_OR_MECH' },
  ],

  subSectorValuesForSEP: [
    { name: 'HOSPITAL', value: 'HOSPITAL' },
    {
      name: 'PROFESSIONALORCONSULTANTS',
      value: 'PROFESSIONALORCONSULTANTS',
    },
  ],
  CASTE_CATEGORY: [
    { name: 'GENERAL', value: 'GENERAL' },
    { name: 'OBC', value: 'OBC' },
    { name: 'SC', value: 'SC' },
    { name: 'ST', value: 'ST' },
    { name: 'EWS', value: 'EWS' },
  ],
  RELIGION: [
    { name: 'HINDU', value: 'HINDU' },
    { name: 'CHRISTIAN', value: 'CHRISTIAN' },
    { name: 'MUSLIM', value: 'MUSLIM' },
    { name: 'BUDDHIST', value: 'BUDDHIST' },
    { name: 'SIKH', value: 'SIKH' },
    { name: 'ZOROASTRIAN', value: 'ZOROASTRIAN' },
    { name: 'JAIN', value: 'JAIN' },
    { name: 'OTHER', value: 'OTHER' },
  ],
  RESIDENCY_STATUS: [
    { name: 'RESIDENCY', value: 'RESIDENCE' },
    { name: 'NRI', value: 'NRI' },
  ],
  GENDER: [
    { name: 'MALE', value: 'MALE' },
    { name: 'FEMALE', value: 'FEMALE' },
    { name: 'OTHERS', value: 'OTHER' },
    ],
  MARITAL_STATUS: [
    { name: 'SINGLE', value: 'SINGLE' },
    { name: 'MARRIED', value: 'MARRIED' },
    { name: 'WIDOWED', value: 'WIDOWED' },
    { name: 'SEPARATED', value: 'SEPARATED' },
    { name: 'DIVORCED', value: 'DIVORCED' },
  ],
  OCCUPATION: [
    { name: 'BANK_SALARIED_GOVT_PSU', value: 'BANK_SALARIED_GOVT_PSU' },
    { name: 'BANK_SALARIED_PRIVATE', value: 'BANK_SALARIED_PRIVATE' },
    { name: 'CASH_SALARIED', value: 'CASH_SALARIED' },
    { name: 'SELF_EMPLOYED_PROFESSIONAL', value: 'SELF_EMPLOYED_PROFESSIONAL' },
    { name: 'SELF_EMPLOYED_BUSINESSPERSON', value: 'SELF_EMPLOYED_BUSINESSPERSON' },
    { name: 'HOME_MAKER', value: 'HOME_MAKER' },
    { name: 'NOT_WORKING', value: 'NOT_WORKING' },
    { name: 'STUDENT', value: 'STUDENT' },
    { name: 'RETIRED_PENSIONER', value: 'RETIRED_PENSIONER' },
  ],

  INDUSTRY: [
    { name: 'AGRICULTURE', value: 'AGRICULTURE' },
    {
      name: 'AGRICULTURE_FORESTRY_AND_FISHING',
      value: 'AGRICULTURE_FORESTRY_AND_FISHING',
    },
    { name: 'AUTOMOBILES', value: 'AUTOMOBILES' },
    { name: 'BEVERAGES', value: 'BEVERAGES' },
    { name: 'CEMENT', value: 'CEMENT' },
    { name: 'CHEMICALS', value: 'CHEMICALS' },
    { name: 'COMPUTERS', value: 'COMPUTERS' },
    { name: 'CONSTRUCTION', value: 'CONSTRUCTION' },
    { name: 'CONTAINERS_PACKAGING', value: 'CONTAINERS_PACKAGING' },
    { name: 'DURABLES', value: 'DURABLES' },
    { name: 'ENERGY', value: 'ENERGY' },
    { name: 'ENTERTAINMENT_LEISURE', value: 'ENTERTAINMENT_LEISURE' },
    { name: 'FMCG', value: 'FMCG' },
    {
      name: 'FOOD_BEVERAGES_FOOD_PROCESSING',
      value: 'FOOD_BEVERAGES_FOOD_PROCESSING',
    },
    { name: 'FOOD_PRODUCTS', value: 'FOOD_PRODUCTS' },
    {
      name: 'GENERAL_INDUSTRIAL_SERVICES',
      value: 'GENERAL_INDUSTRIAL_SERVICES',
    },
    { name: 'HARDWARE_EQUIPMENT', value: 'HARDWARE_EQUIPMENT' },
    { name: 'HEALTHCARE', value: 'HEALTHCARE' },
    { name: 'HOUSEHOLD_PRODUCTS', value: 'HOUSEHOLD_PRODUCTS' },
    { name: 'IND_EQUIPMENT', value: 'IND_EQUIPMENT' },
    { name: 'JEWELLERY', value: 'JEWELLERY' },
    { name: 'LIQUOR', value: 'LIQUOR' },
    { name: 'MANUFACTURER', value: 'MANUFACTURER' },
    { name: 'MEDIA', value: 'MEDIA' },
    { name: 'METALS', value: 'METALS' },
    { name: 'MINING', value: 'MINING' },
    { name: 'MULTILINE_STORES', value: 'MULTILINE_STORES' },
    { name: 'NON_STORE_RETAIL', value: 'NON_STORE_RETAIL' },
    { name: 'OTHERS', value: 'OTHERS' },
    { name: 'PAINTS_EQUIPMENT', value: 'PAINTS_EQUIPMENT' },
    { name: 'PAPER', value: 'PAPER' },
    { name: 'PETROLEUM_PRODUCTS', value: 'PETROLEUM_PRODUCTS' },
    {
      name: 'PHOTOGRAPHIC_AND_ALLIED_PRODUCTS',
      value: 'PHOTOGRAPHIC_AND_ALLIED_PRODUCTS',
    },
    { name: 'PLASTICS', value: 'PLASTICS' },
    { name: 'PRINTING_SERVIES', value: 'PRINTING_SERVIES' },
    { name: 'PROFESSIONAL_SERVICES', value: 'PROFESSIONAL_SERVICES' },
    { name: 'REAL_ESTATE', value: 'REAL_ESTATE' },
    { name: 'RETAILER', value: 'RETAILER' },
    { name: 'RUBBER', value: 'RUBBER' },
    { name: 'SERVICE', value: 'SERVICE' },
    { name: 'SERVICES', value: 'SERVICES' },
    { name: 'SERVICES_BUSINESS', value: 'SERVICES_BUSINESS' },
    { name: 'SERVICES_COMMUNICATIONS', value: 'SERVICES_COMMUNICATIONS' },
    { name: 'SERVICES_TRANSPORT', value: 'SERVICES_TRANSPORT' },
    { name: 'SOFTWARE', value: 'SOFTWARE' },
    { name: 'SPECIALITY', value: 'SPECIALITY' },
    { name: 'TEXTILES', value: 'TEXTILES' },
    { name: 'TRANSPORT', value: 'TRANSPORT' },
    {
      name: 'TRANSPORTATION_LOGISTICS',
      value: 'TRANSPORTATION_LOGISTICS',
    },
    { name: 'TRAVEL_SERVICES', value: 'TRAVEL_SERVICES' },
    { name: 'WHOLESELLER', value: 'WHOLESELLER' },
  ],

  SUB_INDUSTRY: [
    {
      AGRICULTURE: [
        { name: 'ANIMAL_FEED', value: 'ANIMAL_FEED' },
        { name: 'COFFEE', value: 'COFFEE' },
        { name: 'DRY_FRUITS', value: 'DRY_FRUITS' },
        { name: 'EDIBLE_OILS', value: 'EDIBLE_OILS' },
        { name: 'FLORICULTURE', value: 'FLORICULTURE' },
        { name: 'FRUIT_NUTS', value: 'FRUIT_NUTS' },
        { name: 'GROCERY_RELATED_PRODUCTS', value: 'GROCERY_RELATED_PRODUCTS' },
        { name: 'JUTE', value: 'JUTE' },
        { name: 'MILLING_PRODUCTS', value: 'MILLING_PRODUCTS' },
        { name: 'POULTRY_MEAT_PRODUCTS', value: 'POULTRY_MEAT_PRODUCTS' },
        { name: 'SPICES', value: 'SPICES' },
        { name: 'STARCHES', value: 'STARCHES' },
        { name: 'SUGAR', value: 'SUGAR' },
        { name: 'TEA', value: 'TEA' },
        { name: 'TOBACCO_PRODUCTS', value: 'TOBACCO_PRODUCTS' },
        { name: 'VEGETABLE_ROOTS_TUBERS', value: 'VEGETABLE_ROOTS_TUBERS' },
      ],
      AUTOMOBILES: [
        { name: 'AUTHORISED_DEALERS', value: 'AUTHORISED_DEALERS' },
        { name: 'TRACTOR_DEALERS', value: 'TRACTOR_DEALERS' },
        { name: 'PASSENGER_CAR_DEALERS', value: 'DRY_FRUITS' },
        { name: 'EDIBLE_OILS', value: 'EDIBLE_OILS' },
        { name: 'FLORICULTURE', value: 'FLORICULTURE' },
        { name: '2_AND3_WHEELERS', value: '2_AND3_WHEELERS' },
        { name: 'LCV_HCV_COMMERCIAL_VEHICLES', value: 'LCV_HCV_COMMERCIAL_VEHICLES' },
        { name: 'TRACTORS', value: 'TRACTORS' },
        { name: 'AUTO_ANCILLARIES', value: 'AUTO_ANCILLARIES' },
        { name: 'CYCLE_ACCESSORIES', value: 'CYCLE_ACCESSORIES' },
        { name: 'TYRES', value: 'TYRES' },
        { name: 'AUTO_DESIGN', value: 'AUTO_DESIGN' },
        { name: 'PASSENGER_CARS', value: 'PASSENGER_CARS' },
      ],
      AGRICULTURE_FORESTRY_AND_FISHING: [
        { name: 'FARMING', value: 'FARMING' },
        { name: 'FISHERY', value: 'FISHERY' },
        { name: 'TIMBER', value: 'TIMBER' },
        { name: 'DAIRY_MILK_TRADING', value: 'DAIRY_MILK_TRADING' },
        { name: 'FERTILIZER_PESTICIDES', value: 'FERTILIZER_PESTICIDES' },
        { name: 'POULTRY', value: 'POULTRY' },
        { name: 'ANIMAL_FOOD_AND_FODDER', value: 'ANIMAL_FOOD_AND_FODDER' },
      ],
      BEVERAGES: [{ name: 'NON_ALCOHOLOIC_BEVERAGES', value: 'NON_ALCOHOLOIC_BEVERAGES' }],
      CEMENT: [{ name: 'CEMENT_ASBESTOS_PRODUCTS', value: 'CEMENT_ASBESTOS_PRODUCTS' }],
      CHEMICALS: [
        { name: 'CHEMICALS', value: 'CHEMICALS' },
        { name: 'ALKALIS', value: 'ALKALIS' },
        { name: 'FERTILISERS', value: 'FERTILISERS' },
        { name: 'PAINTS_VARNISHES', value: 'PAINTS_VARNISHES' },
        { name: 'PESTICIDES', value: 'PESTICIDES' },
        { name: 'CARBON_BLACK', value: 'CARBON_BLACK' },
        { name: 'CAUSTIC_SODA', value: 'CAUSTIC_SODA' },
        { name: 'CHEMICALS_NOT_DEFINED', value: 'CHEMICALS_NOT_DEFINED' },
        { name: 'DYES_PIGMENTS', value: 'DYES_PIGMENTS' },
        { name: 'INDUSTRIAL_GASSES', value: 'INDUSTRIAL_GASSES' },
        { name: 'INORGANIC_CHEMICALS', value: 'INORGANIC_CHEMICALS' },
        { name: 'ORGANIC_CHEMICALS', value: 'ORGANIC_CHEMICALS' },
        { name: 'REFACTORY_INTERMEDIATES', value: 'REFACTORY_INTERMEDIATES' },
        { name: 'PETROCHEMICALS', value: 'PETROCHEMICALS' },
        { name: 'POLYMERS', value: 'POLYMERS' },
        { name: 'SPECIALITY_CHEMICALS', value: 'SPECIALITY_CHEMICALS' },
      ],
      COMPUTERS: [
        {
          name: 'COMPUTER_SOFTWARE_AND_EDUCATION_AND_POST_PRODUCTIO',
          value: 'COMPUTER_SOFTWARE_AND_EDUCATION_AND_POST_PRODUCTIO',
        },
        { name: 'EDUCATION', value: 'EDUCATION' },
        {
          name: 'COMPUTERS_HARDWARESALES_SERVICE_NETWORKING',
          value: 'COMPUTER_SOFTWARE_AND_EDUCATION_AND_POST_PRODUCTIO',
        },
      ],
      CONSTRUCTION: [
        { name: 'CONSTRUCTION_MATERIALS', value: 'CONSTRUCTION_MATERIALS' },
        { name: 'CERAMIC_TILES_SANITARYWARE', value: 'CERAMIC_TILES_SANITARYWARE' },
        { name: 'CERAMIC_TILES_SANITARYWARE(IMPORTED)', value: 'CERAMIC_TILES_SANITARYWARE(IMPORTED)' },
        { name: 'BUILDER', value: 'BUILDER' },
        { name: 'CONSTRUCTION_EQUIPMENT', value: 'CONSTRUCTION_EQUIPMENT' },
      ],
      CONTAINERS_PACKAGING: [
        { name: 'PACKAGING_MATERIAL', value: 'PACKAGING_MATERIAL' },
        { name: 'PLASTIC_PACKAGING_GOODS', value: 'PLASTIC_PACKAGING_GOODS' },
      ],
      DURABLES: [
        { name: 'ELECTRICAL_GOODS_MANUFATURE', value: 'ELECTRICAL_GOODS_MANUFATURE' },
        { name: 'HARDWARE_PLUMBING_HEATING_EQUIPMENT', value: 'HARDWARE_PLUMBING_HEATING_EQUIPMENT' },
        { name: 'PROFESSIONAL_COMMERCIAL_EQUIPMENT', value: 'PROFESSIONAL_COMMERCIAL_EQUIPMENT' },
      ],
      ENERGY: [{ name: 'REFINERY', value: 'REFINERY' }],
      ENTERTAINMENT_LEISURE: [
        { name: 'HOTELS_RESTAURANTS_', value: 'HOTELS_RESTAURANTS' },
        { name: 'RECREATION_AMUSEMENT_PARKS', value: 'RECREATION_AMUSEMENT_PARKS' },
      ],
      FMCG: [
        { name: 'DISTRIBUTORS_DEALERS ', value: 'DISTRIBUTORS_DEALERS' },
        { name: 'FISH_POULTRY_MEAT', value: 'DISTRIBUTORS_DEALERS' },
        { name: 'MULTIBRAND_STORES', value: 'MULTIBRAND_STORES' },
        { name: 'WHOLESELLER_OF_FOOD_PRODUCTS', value: 'WHOLESELLER_OF_FOOD_PRODUCTS' },
        { name: 'FOOD_PRODUCTS', value: 'FOOD_PRODUCTS' },
        { name: 'TOILETRIES', value: 'TOILETRIES' },
      ],
      FOOD_BEVERAGES_FOOD_PROCESSING: [
        { name: 'BAKERY ', value: 'BAKERY' },
        { name: 'CONFECTIONARY', value: 'CONFECTIONARY' },
        { name: 'FOOD_AND_BEVERAGES', value: 'FOOD_AND_BEVERAGES' },
        { name: 'SOFT_DRINKS', value: 'SOFT_DRINKS' },
      ],
      FOOD_PRODUCTS: [
        { name: 'BOTTLED_WATER', value: 'BOTTLED_WATER' },
        { name: 'FOOD_PROCESSING', value: 'FOOD_PROCESSING' },
        { name: 'COCOA_PRODUCTS_CONFECTIONARY', value: 'COCOA_PRODUCTS_CONFECTIONARY' },
        { name: 'DAIRY', value: 'DAIRY' },
        { name: 'PACKAGED_FOOD', VALUE: 'PACKAGED_FOOD' },
        { name: 'FOOD_OTHER_THAN_PAULTRY_AND_MEAT', value: 'FOOD_OTHER_THAN_PAULTRY_AND_MEAT' },
        { name: 'MARINE_FOODS', value: 'MARINE_FOODS' },
        { name: 'SOYA_BEAN_PRODUCTS', value: 'SOYA_BEAN_PRODUCTS' },
      ],
      GENERAL_INDUSTRIAL_SERVICES: [{ name: 'GENERAL_INDUSTRIAL_SERVICES', value: 'GENERAL_INDUSTRIAL_SERVICES' }],
      HARDWARE_EQUIPMENT: [
        { name: 'OFFICE_EQUIPMENT', value: 'OFFICE_EQUIPMENT' },
        { name: 'NETWORKING', value: 'NETWORKING' },
      ],
      HEALTHCARE: [
        { name: 'BIOTECH', value: 'BIOTECH' },
        { name: 'RESEARCH', value: 'RESEARCH' },
        { name: 'DIAGNOSTIC_CENTERS', value: 'DIAGNOSTIC_CENTERS' },
        {
          name: 'DRUGS_PHARMACEUTICALS_DRUG_PROPRIETARIES_DRUGG',
          value: 'DRUGS_PHARMACEUTICALS_DRUG_PROPRIETARIES_DRUGG',
        },
        { name: 'HOSPITALS', value: 'HOSPITALS' },
        { name: 'MEDICAL_EQUIPMENT', value: 'MEDICAL_EQUIPMENT' },
        { name: 'MEDICAL_SUPPLIES', value: 'MEDICAL_SUPPLIES' },
      ],
      HOUSEHOLD_PRODUCTS: [
        { name: 'CLOCKS_WATCHES', value: 'CLOCKS_WATCHES' },
        { name: 'DETERGENTS_AND_INTERMEDIATES', value: 'DETERGENTS_AND_INTERMEDIATES' },
        { name: 'ELECTRICAL_GOODS_AND_EQUIPMENTS', value: 'ELECTRICAL_GOODS_AND_EQUIPMENTS' },
        { name: 'ELECTRONIC_EQUIPMENT', value: 'ELECTRONIC_EQUIPMENT' },
        { name: 'CONSUMER_ELECTRONIC_SPARES', value: 'CONSUMER_ELECTRONIC_SPARES' },
        { name: 'ELECTRICAL_SPARES_COMPONENTS', value: 'ELECTRICAL_SPARES_COMPONENTS' },
        { name: 'FOOTWEAR', value: 'FOOTWEAR' },
        { name: 'LEATHER_FOOTWEAR', value: 'LEATHER_FOOTWEAR' },
        { name: 'HOME_FURNITURE_FURNISHING', value: 'HOME_FURNITURE_FURNISHING' },
        { name: 'DRY_CELLS', value: 'DRY_CELLS' },
        { name: 'PERSONAL_CARE', value: 'PERSONAL_CARE' },
      ],
      IND_EQUIPMENT: [
        { name: 'BEARINGS_VALVES', value: 'BEARINGS_VALVES' },
        { name: 'COMPRESSORS', value: 'COMPRESSORS' },
        { name: 'ENGINEERING', value: 'ENGINEERING' },
        { name: 'PRECISION_DYES_AND_PARTS', value: 'PRECISION_DYES_AND_PARTS' },
        { name: 'INDUSTRIAL_MACHINERY', value: 'INDUSTRIAL_MACHINERY' },
        { name: 'INDUSTRIAL_MACHINERY_CHEMICAL&_TEXTILES', value: 'INDUSTRIAL_MACHINERY_CHEMICAL&_TEXTILES' },
        { name: 'TEXTILE_MACHINERY', value: 'TEXTILE_MACHINERY' },
        { name: 'MACHINE_TOOLS', value: 'MACHINE_TOOLS' },
        { name: 'PHARMA_MACHINERY', value: 'PHARMA_MACHINERY' },
        { name: 'ABRASIVES_AND_GRINDING', value: 'ABRASIVES_AND_GRINDING' },
        { name: 'ELECTRICAL_EQUIPMENT', value: 'ELECTRICAL_EQUIPMENT' },
        { name: 'ELECTRODES_AND_GRAPHITE', value: 'ELECTRODES_AND_GRAPHITE' },
        { name: 'ENGINES', value: 'ENGINES' },
        { name: 'FASTENERS', value: 'FASTENERS' },
        { name: 'INDUSTRIAL_FURNACES', value: 'INDUSTRIAL_FURNACES' },
        { name: 'MATERIAL_HANDLING_EQUIPMENT', value: 'MATERIAL_HANDLING_EQUIPMENT' },
        { name: 'PRIME_MOVERS', value: 'PRIME_MOVERS' },
        { name: 'PRINTING_MACHINERY', value: 'PRINTING_MACHINERY' },
        { name: 'STRUCTURALS', value: 'STRUCTURALS' },
        { name: 'SWITCHING_APPARATUS', value: 'SWITCHING_APPARATUS' },
        { name: 'MOTORS_GENERATORS_AND_PUMPS_TRANSFORMERS', value: 'MOTORS_GENERATORS_AND_PUMPS_TRANSFORMERS' },
        { name: 'PUMPS', value: 'PUMPS' },
        { name: 'TRANSFORMERS', value: 'TRANSFORMERS' },
        { name: 'STORAGE_BATTERIES', value: 'STORAGE_BATTERIES' },
        { name: 'TRANSMISSION_LINE_TOWERS_EQUIPMENT', value: 'TRANSMISSION_LINE_TOWERS_EQUIPMENT' },
        { name: 'TURNKEY_SERVICES', value: 'TURNKEY_SERVICES' },
        { name: 'VALVES', value: 'VALVES' },
        { name: 'WELDING_MACHINERY', value: 'WELDING_MACHINERY' },
      ],
      JEWELLERY: [{ name: 'GEMS_JEWELLERY ', value: 'GEMS_JEWELLERY' }],
      LIQUOR: [
        { name: 'BEER_WINE_DISTILLED_ALCOHOLIC_BEVERAGES', value: 'BEER_WINE_DISTILLED_ALCOHOLIC_BEVERAGES' },
        { name: 'BREWERIES', value: 'BREWERIES' },
        { name: 'DISTILLERIES', value: 'DISTILLERIES' },
      ],
      MANUFACTURER: [
        { name: 'FABRICATORS_FURNITURE', value: 'FABRICATORS_FURNITURE' },
        { name: 'JEWELARY_MAKING_GOLDSMITH', value: 'JEWELARY_MAKING_GOLDSMITH' },
        { name: 'POULTRY_FARM', value: 'POULTRY_FARM' },
        { name: 'SEED_PROCESSING_FLOOR_MILLS', value: 'SEED_PROCESSING_FLOOR_MILLS' },
        { name: 'ICECREAM_COLDRINK_MANUFACTURERS', value: 'ICECREAM_COLDRINK_MANUFACTURERS' },
        { name: 'FOOTWARE_BAGS', value: 'FOOTWARE_BAGS' },
        { name: 'PLASTIC_MOULDING', value: 'PLASTIC_MOULDING' },
        { name: 'SOAP_DETERGENT_MANUFACTURER', value: 'SOAP_DETERGENT_MANUFACTURER' },
        { name: 'PHARMACEUTICALS', value: 'PHARMACEUTICALS' },
        { name: 'PRINTING_PRESS_FLEX_BOARD', value: 'PRINTING_PRESS_FLEX_BOARD' },
        { name: 'MINES_QUARRIES', value: 'MINES_QUARRIES' },
        { name: 'STONE_CRUSHERS', value: 'STONE_CRUSHERS' },
        { name: 'OTHER_MANUFACTURER', value: 'OTHER_MANUFACTURER' },
        { name: 'ELECTRONICS', value: 'ELECTRONICS' },
        { name: 'PLASTICS', value: 'PLASTICS' },
        { name: 'METAL', value: 'METAL' },
        { name: 'STONE', value: 'STONE' },
        { name: 'SPARE_PARTS', value: 'SPARE_PARTS' },
        { name: 'TIMBER', value: 'TIMBER' },
        { name: 'HEAVY_MACHINERY', value: 'HEAVY_MACHINERY' },
        { name: 'HARDWARE', value: 'HARDWARE' },
        { name: 'HOUSEHOLD_SUPLLIES', value: 'HOUSEHOLD_SUPLLIES' },
        { name: 'APPARAEL_AND_CLOTHING', value: 'APPARAEL_AND_CLOTHING' },
        { name: 'ARMS_AND_AMMUNITION', value: 'ARMS_AND_AMMUNITION' },
        { name: 'HOME_AND_FURNISHING', value: 'HOME_AND_FURNISHING' },
        { name: 'HEALTHCARE', value: 'HEALTHCARE' },
        { name: 'SPORTS_AND_OUTDOOR', value: 'SPORTS_AND_OUTDOOR' },
        { name: 'BEAUTY_AND_COSMETICS', value: 'BEAUTY_AND_COSMETICS' },
        { name: 'CHEMICALS', value: 'CHEMICALS' },
        { name: 'BUILDING_MATERIAL_MFG', value: 'BUILDING_MATERIAL_MFG' },
        { name: 'FABRICATORS_FURNITURE', value: 'FABRICATORS_FURNITURE' },
        { name: 'FMCG_GOODS', value: 'FMCG_GOODS' },
        { name: 'JEWELLERY_MAKING_GOLDSMITH', value: 'JEWELLERY_MAKING_GOLDSMITH' },
        { name: 'SCULPTURE_HANDICRAFT', value: 'SCULPTURE_HANDICRAFT' },
        { name: 'OTHER', value: 'OTHER' },
      ],
      MEDIA: [
        { name: 'MEDIA_ADVERTISING_AND_BROADCASTING', value: 'MEDIA_ADVERTISING_AND_BROADCASTING' },
        { name: 'BROADCASTING', value: 'BROADCASTING' },
        { name: 'CABLE_OTHER_PAY_TV_SERVICES', value: 'CABLE_OTHER_PAY_TV_SERVICES' },
        { name: 'ANIMATION_POST_PRODUCTION', value: 'ANIMATION_POST_PRODUCTION' },
        { name: 'PUBLISHING', value: 'PUBLISHING' },
        { name: 'MEDIA', value: 'MEDIA' },
      ],
      METALS: [
        { name: 'ALUMINUM_ALUMINIUM_PRODUCTS(MANUFACTURER)', value: 'ALUMINUM_ALUMINIUM_PRODUCTS(MANUFACTURER)' },
        { name: 'CASTINGS_FORGINGS', value: 'CASTINGS_FORGINGS' },
        { name: 'COPPER_COPPER_PRODUCTS', value: 'COPPER_COPPER_PRODUCTS' },
        { name: 'GRANITE_OTHER_NON_METAL_MINERALS', value: 'GRANITE_OTHER_NON_METAL_MINERALS' },
        { name: 'IRON_STEEL_STEEL_ALLOYS', value: 'IRON_STEEL_STEEL_ALLOYS' },
        { name: 'STEEL(MANUFACTURING)', value: 'STEEL(MANUFACTURING)' },
        { name: 'STEEL_TUBES_PIPES', value: 'STEEL_TUBES_PIPES' },
        { name: 'STEEL_WIRES', value: 'STEEL_WIRES' },
        {
          name: 'METAL_PRODUCTS(OTHER_THAN_STEEL_ALUMINIUM_AND_C)',
          value: 'METAL_PRODUCTS(_OTHER_THAN_STEEL_ALUMINIUM_AND_C)',
        },
        { name: 'FERRO_ALLOYS', value: 'FERRO_ALLOYS' },
        { name: 'FERROUS_METALS', value: 'FERROUS_METALS' },
        { name: 'PIG_IRON', value: 'PIG_IRON' },
        { name: 'WIRES_CABLES', value: 'WIRES_CABLES' },
      ],
      OTHERS: [
        { name: 'COMMISSION_AGENTS_WITH_ONLY_COMMISION_INCOME', value: 'COMMISSION_AGENTS_WITH_ONLY_COMMISION_INCOME' },
        { name: 'GENERAL_MERCHANDISE_STORES', value: 'GENERAL_MERCHANDISE_STORES' },
        {
          name: 'INDUSTRIES_NOT_CLASSIFIED_ELSEWHERE(MANFACTURERS)',
          value: 'INDUSTRIES_NOT_CLASSIFIED_ELSEWHERE(MANFACTURERS)',
        },
        { name: 'INDUSTRIES_NOT_CLASSIFIED_ELSEWHERE(TRADING)', value: 'INDUSTRIES_NOT_CLASSIFIED_ELSEWHERE(TRADING)' },
        {
          name: 'INDUSTRIES_NOT_CLASSIFIED_ELSEWHERE(SERVICES)',
          value: 'INDUSTRIES_NOT_CLASSIFIED_ELSEWHERE(SERVICES)',
        },
        { name: 'GENERAL_PURPOSE_MACHINERY', value: 'GENERAL_PURPOSE_MACHINERY' },
        { name: 'POLLUTION_CONTROL', value: 'POLLUTION_CONTROL' },
      ],
      MINING: [
        { name: 'COAL', value: 'COAL' },
        { name: 'CRUDE_OIL_NATURAL_GAS', value: 'CRUDE_OIL_NATURAL_GAS' },
        { name: 'MINERALS', value: 'MINERALS' },
        { name: 'METAL', value: 'METAL' },
        { name: 'STONE', value: 'STONE' },
        { name: 'MARBLE_GRANITE', value: 'MARBLE_GRANITE' },
      ],
      MULTILINE_STORES: [
        { name: 'GENERAL_MERCHANDISE_STORE_KIRIANA_STORES_ETC', value: 'GENERAL_MERCHANDISE_STORE_KIRIANA_STORES_ETC' },
      ],
      NON_STORE_RETAIL: [
        { name: 'AUTOMATIC_MERCHANDISING_MACHINE_OPERATORS', value: 'AUTOMATIC_MERCHANDISING_MACHINE_OPERATORS' },
        { name: 'CATALOGUE_MAIL_ORDER_HOUSES', value: 'CATALOGUE_MAIL_ORDER_HOUSES' },
        { name: 'DIRECT_SELLING_ESTABLISHMENTS', value: 'DIRECT_SELLING_ESTABLISHMENTS' },
      ],
      PAINTS_EQUIPMENT: [{ name: 'PAINTS_EQUIPMENT', value: 'PAINTS_EQUIPMENT' }],
      PAPER: [{ name: 'PAPER_AND_PAPER_PRODUCTS', value: 'PAPER_AND_PAPER_PRODUCTS' }],
      PETROLEUM_PRODUCTS: [
        { name: 'PETROLEUM_PRODUCTS', value: 'PETROLEUM_PRODUCTS' },
        { name: 'LPG_DEALERS', value: 'LPG_DEALERS' },
        { name: 'LUBRICANTS', value: 'LUBRICANTS' },
        { name: 'PETROL_PUMPS', value: 'PETROL_PUMPS' },
      ],
      PHOTOGRAPHIC_AND_ALLIED_PRODUCTS: [
        { name: 'PHOTOGRAPHIC_AND_ALLIED_PRODUCTS', value: 'PHOTOGRAPHIC_AND_ALLIED_PRODUCTS' },
      ],
      PLASTICS: [
        { name: 'PLASTIC', value: 'PLASTIC' },
        { name: 'FILMS', value: 'FILMS' },
        { name: 'PLASTIC_RESINS', value: 'PLASTIC_RESINS' },
        {
          name: 'PLASTIC_TUBES_AND_SHEETS_AND_OTHER_PLASTIC_PRODUCT',
          value: 'PLASTIC_TUBES_AND_SHEETS_AND_OTHER_PLASTIC_PRODUCT',
        },
        { name: 'THERMOPLASTICS', value: 'THERMOPLASTICS' },
      ],
      PRINTING_SERVIES: [{ name: 'PRINTING_SERVICES', value: 'PRINTING_SERVICES' }],
      PROFESSIONAL_SERVICES: [
        { name: 'COACHING_CLASSES', value: 'COACHING_CLASSES' },
        { name: 'ENTERTAINMENT_MEDIA_CONTENT_PROVIDER', value: 'ENTERTAINMENT_MEDIA_CONTENT_PROVIDER' },
        {
          name: 'MOTION_PICTURE_PRODUCTION_DISTRIBUTION_EXHIBITION',
          value: 'MOTION_PICTURE_PRODUCTION_DISTRIBUTION_EXHIBITION',
        },
        { name: 'LEGAL_SERVICES_SOLICITOR_FIRMS', value: 'LEGAL_SERVICES_SOLICITOR_FIRMS' },
        { name: 'PUBLIC_RELATIONS', value: 'PUBLIC_RELATIONS' },
        { name: 'TECHNICAL_CONSULTANCY_AND_ENGG_SERVICES', value: 'TECHNICAL_CONSULTANCY_AND_ENGG_SERVICES' },
        { name: 'PROFESIONAL_CONSULTANTS', value: 'PROFESIONAL_CONSULTANTS' },
        { name: 'EDUCATION_CONSULTING', value: 'EDUCATION_CONSULTING' },
        { name: 'EXECUTIVE_SEARCH', value: 'EXECUTIVE_SEARCH' },
        { name: 'IT_CONSULTING', value: 'IT_CONSULTING' },
        { name: 'TAX_AUDIT', value: 'TAX_AUDIT' },
        { name: 'ARCHITECTS', value: 'ARCHITECTS' },
      ],
      REAL_ESTATE: [
        { name: 'BUILDER', value: 'BUILDER' },
        { name: 'CONTRACTOR', value: 'CONTRACTOR' },
        { name: 'AGENT', value: 'AGENT' },
        { name: 'ARCHITECT', value: 'ARCHITECT' },
      ],
      RETAILER: [
        {
          name: 'PROV_STORES_GEN_STORES_STATIONARY_FENCY_STORE',
          value: 'PROV_STORES_GEN_STORES_STATIONARY_FENCY_STORE',
        },
        { name: 'MEDICAL STORES', value: 'MEDICAL STORES' },
        { name: 'HARDWARE_PAINT_AUTO_PARTS', value: 'HARDWARE_PAINT_AUTO_PARTS' },
        { name: 'ELECTRONIC_EQUIPMENT', value: 'ELECTRONIC_EQUIPMENT' },
        { name: 'GARMENT_SHOP_CLOTH_STORES_BUTIQUE', value: 'GARMENT_SHOP_CLOTH_STORES_BUTIQUE' },
        { name: 'FOOTWARE_SHOP', value: 'FOOTWARE_SHOP' },
        { name: 'UTENSILS_SHOP', value: 'UTENSILS_SHOP' },
        { name: 'FERTILIZER_PESTICIDES_AGRI_EQUP', value: 'FERTILIZER_PESTICIDES_AGRI_EQUP' },
        { name: 'DAIRY_MILK_TRADING', value: 'DAIRY_MILK_TRADING' },
        { name: 'BUILDING_MATERIAL_SUPPLIERS', value: 'BUILDING_MATERIAL_SUPPLIERS' },
        { name: 'SWEETS_SAIVERY_BAKERY', value: 'SWEETS_SAIVERY_BAKERY' },
        { name: 'JEWELLER', value: 'JEWELLER' },
        { name: 'MOBILE_CELL_PHONE_ACCESSORIES', value: 'MOBILE_CELL_PHONE_ACCESSORIES' },
        { name: 'ELECTRONICS', value: 'ELECTRONICS' },
        { name: 'HARDWARE', value: 'HARDWARE' },
        { name: 'PACKING_MATERIAL_AND_DISPOSABLE_ITEMS', value: 'PACKING_MATERIAL_AND_DISPOSABLE_ITEMS' },
        { name: 'PLASTIC_GOODS', value: 'PLASTIC_GOODS' },
        { name: 'OTHER_RETAILERS', value: 'OTHER_RETAILERS' },
        { name: 'F_B', value: 'F_B' },
        { name: 'HOUSEHOLD_SUPLLIES', value: 'HOUSEHOLD_SUPLLIES' },
        { name: 'APPARAEL AND CLOTHING', value: 'APPARAEL_AND_CLOTHING' },
        { name: 'ARMS_AND_AMMUNITION', value: 'ARMS_AND_AMMUNITION' },
        { name: 'HOME_AND_FURNISHING', value: 'HOME_AND_FURNISHING' },
        { name: 'HEALTHCARE', value: 'HEALTHCARE' },
        { name: 'SPORTS_AND_OUTDOOR', value: 'SPORTS_AND_OUTDOOR' },
        { name: 'BEAUTY_AND_COSMETICS', value: 'BEAUTY_AND_COSMETICS' },
        { name: 'SPARE_PARTS', value: 'SPARE_PARTS' },
        { name: 'FOOTWEAR', value: 'FOOTWEAR' },
        { name: 'FABRICATORS_FURNITURE', value: 'FABRICATORS_FURNITURE' },
        { name: 'JEWELLERY_DIAMOND_STONES_GEMS', value: 'JEWELLERY_DIAMOND_STONES_GEMS' },
        { name: 'KITCHEN_ITEMS', value: 'KITCHEN_ITEMS' },
        { name: 'MOBILE_COMPUTER_SALES_ACCESSORIES', value: 'MOBILE_COMPUTER_SALES_ACCESSORIES' },
        { name: 'PROVISION_STORES_GENERAL_STORES_STATIONARY', value: 'PROVISION_STORES_GENERAL_STORES_STATIONARY' },
        { name: 'SCRAP_BUSINESS', value: 'SCRAP_BUSINESS' },
        { name: 'TEA_STALL', value: 'TEA_STALL' },
        { name: 'DRIVER', value: 'DRIVER' },
        { name: 'FRUIT_VEGETABLE', value: 'FRUIT_VEGETABLE' },
        { name: 'OTHER', value: 'OTHER' },
      ],
      RUBBER: [{ name: 'RUBBER_RUBBER_PRODUCTS', value: 'RUBBER_RUBBER_PRODUCTS' }],
      SERVICE: [
        { name: 'HOTELS_RESTAURANTS_EATERY_CATERING_BUSINESS', value: 'HOTELS_RESTAURANTS_EATERY_CATERING_BUSINESS' },
        {
          name: 'TOURS_AND_TRAVELS_TRANSPORTERS_EARTHMOVER_HIRING',
          value: 'TOURS_AND_TRAVELS_TRANSPORTERS_EARTHMOVER_HIRING',
        },
        { name: 'HAIR_SALOONS_TAILORS_PUROHIT_ASTROLOGER', value: 'HAIR_SALOONS_TAILORS_PUROHIT_ASTROLOGER' },
        { name: 'CIVIL_CONTRACTORS_ARCHITECT_LABOR_CONTRACTOR', value: 'CIVIL_CONTRACTORS_ARCHITECT_LABOR_CONTRACTOR' },
        { name: 'BUILDERS_COLONIZERS', value: 'BUILDERS_COLONIZERS' },
        { name: 'PROPERTY_BROKER', value: 'PROPERTY_BROKER' },
        { name: 'DOCTORS_HOSPITAL_DIAGNOSTIC_CENTER', value: 'DOCTORS_HOSPITAL_DIAGNOSTIC_CENTER' },
        { name: 'TAX_CONSULTATS_CASDER', value: 'TAX_CONSULTATS_CASDER' },
        { name: 'PHOTO_STUDIO_EVENT_MANGEMENT', value: 'PHOTO_STUDIO_EVENT_MANGEMENT' },
        { name: 'PHOTO_COPIER_CYBER_CAFÉ', value: 'PHOTO_COPIER_CYBER_CAFÉ' },
        { name: 'MECHANICAL_WELDING_AUTOMOBILE_WORKSHOP', value: 'MECHANICAL_WELDING_AUTOMOBILE_WORKSHOP' },
        { name: 'OTHER_SERVICE', value: 'OTHER_SERVICE' },
        { name: 'TELECOM', value: 'TELECOM' },
        { name: 'SOFTWARE', value: 'SOFTWARE' },
        { name: 'PUBLIC_UTILITY_ELECTRICITY_GAS_WATER_SANIT', value: 'PUBLIC_UTILITY_ELECTRICITY_GAS_WATER_SANIT' },
        { name: 'MEDIA_AND_ADVERTISING', value: 'MEDIA_AND_ADVERTISING' },
        { name: 'RENTAL_AND_LEASING', value: 'RENTAL_AND_LEASING' },
        { name: 'TRANSPORTATION', value: 'TRANSPORTATION' },
        { name: 'INTERNET', value: 'INTERNET' },
        { name: 'DTH', value: 'DTH' },
        { name: 'EMPLOYMENT_AGENCY', value: 'EMPLOYMENT_AGENCY' },
        { name: 'HUMAN_RESOURCE', value: 'HUMAN_RESOURCE' },
        {
          name: 'PRINTING_PHOTOCOPY_FAX_CYBERCAFE_VEDIO_GRAPHY',
          value: 'PRINTING_PHOTOCOPY_FAX_CYBERCAFE_VEDIO_GRAPHY',
        },
        { name: 'COURIER', value: 'COURIER' },
        { name: 'ELECTRONIC_REPAIR', value: 'ELECTRONIC_REPAIR' },
        { name: 'AUTO_REPAIR', value: 'AUTO_REPAIR' },
        { name: 'MISCELLEANEOUS_REPAIR', value: 'MISCELLEANEOUS_REPAIR' },
        { name: 'ENTERTAINMENT_MUSIC_MOVIE_GAMING_RETAL', value: 'ENTERTAINMENT_MUSIC_MOVIE_GAMING_RETAL' },
        { name: 'MEDICAL', value: 'MEDICAL' },
        { name: 'SPORTS_AND_RECREATION', value: 'SPORTS_AND_RECREATION' },
        { name: 'MARRIAGE_AND_OTHER_CEREMONIES', value: 'MARRIAGE_AND_OTHER_CEREMONIES' },
        { name: 'LEGAL', value: 'LEGAL' },
        { name: 'EDUCATIONAL', value: 'EDUCATIONAL' },
        { name: 'CHILD_CARE', value: 'CHILD_CARE' },
        { name: 'TESTING_LABORATORIES', value: 'TESTING_LABORATORIES' },
        { name: 'FINANCE_AND_INSURANCE', value: 'FINANCE_AND_INSURANCE' },
        { name: 'COMMISSION_AGENTS', value: 'COMMISSION_AGENTS' },
        { name: 'CENTRING_MATERIAL', value: 'CENTRING_MATERIAL' },
        { name: 'DAILY_WAGE_WORKER', value: 'DAILY_WAGE_WORKER' },
        { name: 'DIAMOND_GEMS_JOBWORK', value: 'DIAMOND_GEMS_JOBWORK' },
        { name: 'ELECTRICIAN_PLUMBER', value: 'ELECTRICIAN_PLUMBER' },
        { name: 'FLOUR_MILL', value: 'FLOUR_MILL' },
        { name: 'HAIR_SALOONS_PUROHIT_ASTROLOGER', value: 'HAIR_SALOONS_PUROHIT_ASTROLOGER' },
        { name: 'HOSPITAL_CLINIC_DOCTOR', value: 'HOSPITAL_CLINIC_DOCTOR' },
        { name: 'HOTELS_RESTAURANTS_EATERY', value: 'HOTELS_RESTAURANTS_EATERY' },
        { name: 'CATERING_BUSINESS', value: 'CATERING_BUSINESS' },
        { name: 'IRON_WORK', value: 'IRON_WORK' },
        { name: 'JOB WORK', value: 'JOB_WORK' },
        { name: 'LAUNDARY_DRY_CLEANER', value: 'LAUNDARY_DRY_CLEANER' },
        { name: 'LIGHT_MANDAP_FLOWER_DECORATOR', value: 'LIGHT_MANDAP_FLOWER_DECORATOR' },
        { name: 'PAINTING', value: 'PAINTING' },
        { name: 'PHOTO_STUDIO_EVENT_MANAGEMENT', value: 'PHOTO_STUDIO_EVENT_MANAGEMENT' },
        { name: 'PROFESSIONAL_CONSULTANTS', value: 'PROFESSIONAL_CONSULTANTS' },
        { name: 'RENTAL_INCOME_FROM_PROPERTY_GOODS', value: 'RENTAL_INCOME_FROM_PROPERTY_GOODS' },
        { name: 'SCHOOLS_TUTION_CENTERS_PG_OR_HOSTEL', value: 'SCHOOLS_TUTION_CENTERS_PG_OR_HOSTEL' },
        { name: 'TAILOR_STITCHING_EMBROIDARY', value: 'TAILOR_STITCHING_EMBROIDARY' },
        { name: 'TOURS_AND_TRAVELS_EARTHMOVER_HIRING', value: 'TOURS_AND_TRAVELS_EARTHMOVER_HIRING' },
        { name: 'WATER_SUPPLIER', value: 'WATER_SUPPLIER' },
        { name: 'WORKSHOPS_AUTO_MECH_WELDING_SERVICE_CENTERS', value: 'WORKSHOPS_AUTO_MECH_WELDING_SERVICE_CENTERS' },
        { name: 'DRIVER', value: 'DRIVER' },
        { name: 'CARPENTER', value: 'CARPENTER' },
        { name: 'OTHER', value: 'OTHER' },
      ],
      SERVICES: [{ name: 'DIVERSIFIED', value: 'DIVERSIFIED' }],
      SERVICES_BUSINESS: [{ name: 'HR_A_MEDICAL_TRANSCRIPTIONS', value: 'HR_A_MEDICAL_TRANSCRIPTIONS' }],
      SERVICES_COMMUNICATIONS: [
        { name: 'INTERNET_SERVICES', value: 'INTERNET_SERVICES' },
        {
          name: 'OTHER_COMMUNICATION_SERVICES(TELEX,WIRELESS,FAX)',
          value: 'OTHER_COMMUNICATION_SERVICES(TELEX,WIRELESS,FAX)',
        },
        { name: 'TELECOM_DISTRIBUTOR', value: 'TELECOM_DISTRIBUTOR' },
      ],
      SERVICES_TRANSPORT: [{ name: 'WATER_TRANSPORT', value: 'WATER_TRANSPORT' }],
      SOFTWARE: [
        { name: 'ITES_CALL_CENTERS', value: 'ITES_CALL_CENTERS' },
        { name: 'ERP', value: 'ERP' },
      ],
      SPECIALITY: [
        { name: 'BOOKS_OFFICE_SUPPLIES_STATIONERY', value: 'BOOKS_OFFICE_SUPPLIES_STATIONERY' },
        { name: 'GLASS_GLASS_PRODUCTS', value: 'GLASS_GLASS_PRODUCTS' },
        { name: 'GLASS_GLASS_PRODUCTS_LABWARE', value: 'GLASS_GLASS_PRODUCTS_LABWARE' },
        { name: 'CONTRACEPTIVES', value: 'CONTRACEPTIVES' },
        { name: 'SPORTS GOODS', value: 'SPORTS GOODS' },
        {
          name: 'HOBBY_TOY_GAME_CAMERA_PHOTOGRAPHIC_SUPPLY_STUDIO',
          value: 'HOBBY_TOY_GAME_CAMERA_PHOTOGRAPHIC_SUPPLY_STUDIO',
        },
        { name: 'LUGGAGE_LEATHER_GOODS_OTHER_LEATHER_PRODUCTS', value: 'LUGGAGE_LEATHER_GOODS_OTHER_LEATHER_PRODUCTS' },
        { name: 'LUGGAGE', value: 'LUGGAGE' },
        { name: 'PENS_AND_PENCILS', value: 'PENS_AND_PENCILS' },
        { name: 'OPTICIANS', value: 'OPTICIANS' },
        { name: 'WOOD_AND_WOOD_PRODUCTS', value: 'WOOD_AND_WOOD_PRODUCTS' },
      ],
      TEXTILES: [
        { name: 'READYMADE_GARMENTS', value: 'READYMADE_GARMENTS' },
        { name: 'TEXTILES', value: 'TEXTILES' },
        { name: 'BLENDED_YARN', value: 'BLENDED_YARN' },
        { name: 'COTTON_TEXTILES', value: 'COTTON_TEXTILES' },
        { name: 'SILK', value: 'SILK' },
        { name: 'SYNTHETIC', value: 'SYNTHETIC' },
        { name: 'WOOLEN', value: 'WOOLEN' },
        { name: 'TEXTILES_OTHER_THAN_THE_ABOVE', value: 'TEXTILES_OTHER_THAN_THE_ABOVE' },
      ],
      TRANSPORT: [{ name: 'LAND_TRANSPORTATION_EQUIPMENT', value: 'LAND_TRANSPORTATION_EQUIPMENT' }],
      TRANSPORTATION_LOGISTICS: [
        { name: 'CLEARING_AND_FORWARDING_AGENTS', value: 'CLEARING_AND_FORWARDING_AGENTS' },
        { name: 'COURIER_MNC_COS', value: 'COURIER_MNC_COS' },
        { name: 'COURIER_LOCAL', value: 'COURIER_LOCAL' },
        { name: 'GOODS_TRANSPORT_SERVICES(ROAD)', value: 'GOODS_TRANSPORT_SERVICES(ROAD)' },
        { name: 'PASSENGER_TRANSPORT_SERVICES(ROAD)', value: 'PASSENGER_TRANSPORT_SERVICES(ROAD)' },
        { name: 'STORAGE_WAREHOUSING', value: 'STORAGE_WAREHOUSING' },
      ],
      TRAVEL_SERVICES: [
        { name: 'TICKETING_AND_TAXI_SERVIES', value: 'TICKETING_AND_TAXI_SERVIES' },
        { name: 'TAXI_CAR_RENTAL', value: 'TAXI_CAR_RENTAL' },
      ],
      WHOLESELLER: [
        { name: 'FOOD_GRAIN_WHOLESELLER', value: 'FOOD_GRAIN_WHOLESELLER' },
        { name: 'DISTRIBUTOR_C_F_OF_ANY_ITEM', value: 'DISTRIBUTOR_C_F_OF_ANY_ITEM' },
        { name: 'F_B', value: 'F_B' },
        { name: 'ELECTRONICS', value: 'ELECTRONICS' },
        { name: 'HARDWARE', value: 'HARDWARE' },
        { name: 'HOUSEHOLD_SUPLLIES', value: 'HOUSEHOLD_SUPLLIES' },
        { name: 'APPARAEL_AND_CLOTHING', value: 'APPARAEL_AND_CLOTHING' },
        { name: 'HOME_AND_FURNISHING', value: 'HOME_AND_FURNISHING' },
        { name: 'HEALTHCARE', value: 'HEALTHCARE' },
        { name: 'SPORTS_AND_OUTDOOR', value: 'SPORTS_AND_OUTDOOR' },
        { name: 'BEAUTY_AND_COSMETICS', value: 'BEAUTY_AND_COSMETICS' },
        { name: 'AUTO_SPARE_PARTS', value: 'AUTO_SPARE_PARTS' },
        { name: 'FRUIT_VEGETABLE', value: 'FRUIT_VEGETABLE' },
        { name: 'FOOD_GRAIN', value: 'FOOD_GRAIN' },
        { name: 'OTHER', value: 'OTHER' },
      ],
    },
  ],

  scaleOfBusinessValues: [
    {
      name: 'LARGE',
      value: 'large',
    },
    {
      name: 'MEDIUM',
      value: 'medium',
    },
    {
      name: 'SMALL',
      value: 'small',
    },
  ],

  relationWithMainApplicantMale: [
    { name: 'BROTHER', value: 'BROTHER' },
    { name: 'BROTHER_IN_LAW', value: 'BROTHER_IN_LAW' },
    { name: 'FATHER', value: 'FATHER' },
    { name: 'FATHER_IN_LAW', value: 'FATHER_IN_LAW' },
    { name: 'HUSBAND', value: 'HUSBAND' },
    { name: 'SON', value: 'SON' },
    { name: 'SON_IN_LAW', value: 'SON_IN_LAW' },
  ],

  relationWithMainApplicantFemale: [
    { name: 'DAUGHTER', value: 'DAUGHTER' },
    { name: 'DAUGHTER_IN_LAW', value: 'DAUGHTER_IN_LAW' },
    { name: 'MOTHER', value: 'MOTHER' },
    { name: 'MOTHER_IN_LAW', value: 'MOTHER_IN_LAW' },
    { name: 'SISTER', value: 'SISTER' },
    { name: 'SISTER_IN_LAW', value: 'SISTER_IN_LAW' },
    { name: 'WIFE', value: 'WIFE' },
  ],

  relationWithMainApplicant: [
    { name: 'BROTHER', value: 'BROTHER' },
    { name: 'BROTHER_IN_LAW', value: 'BROTHER_IN_LAW' },
    { name: 'DAUGHTER', value: 'DAUGHTER' },
    { name: 'DAUGHTER_IN_LAW', value: 'DAUGHTER_IN_LAW' },
    { name: 'FATHER', value: 'FATHER' },
    { name: 'FATHER_IN_LAW', value: 'FATHER_IN_LAW' },
    { name: 'HUSBAND', value: 'HUSBAND' },
    { name: 'MOTHER', value: 'MOTHER' },
    { name: 'MOTHER_IN_LAW', value: 'MOTHER_IN_LAW' },
    { name: 'SISTER', value: 'SISTER' },
    { name: 'SISTER_IN_LAW', value: 'SISTER_IN_LAW' },
    { name: 'SON', value: 'SON' },
    { name: 'SON_IN_LAW', value: 'SON_IN_LAW' },
    { name: 'WIFE', value: 'WIFE' },
  ],

  propertyTitle: [
    { name: 'DEVELOPMENT_AUTHORITY', value: 'DEVELOPMENT_AUTHORITY' },
    { name: 'FREEHOLD', value: 'FREEHOLD' },
    { name: 'GRAM_PANCHAYAT', value: 'GRAM_PANCHAYAT' },
    { name: 'HOUSING_BOARD', value: 'HOUSING_BOARD' },
    { name: 'LALDORA', value: 'LALDORA' },
    { name: 'NAGAR_NIGAM_OR_OTHER', value: 'NAGAR_NIGAM_OR_OTHER' },
    { name: 'OTHERS', value: 'OTHERS' },
    { name: 'SOCIETY', value: 'SOCIETY' },
    { name: 'UIT', value: 'UIT' },
  ],

  constitutionOfCompanyValue: [
    { name: 'ASSOCIATION_OF_PERSONS', value: 'ASSOCIATION_OF_PERSONS' },
    { name: 'GOVERNMENT', value: 'GOVERNMENT_ENTITY' },
    { name: 'HINDU_UNDIVIDED', value: 'HINDU_UNDIVIDED_FAMILY' },
    {
      name: 'LIMITED_LIABILITY_PARTNERSHIP',
      value: 'LIMITED_LIABILITY_PARTNERSHIP',
    },
    {
      name: 'MULTINATIONAL_CORPORATION',
      value: 'MULTI_NATIONAL_CORPORATION',
    },
    { name: 'PARTNERSHIP_FIRM', value: 'PARTNERSHIP_FIRM' },
    { name: 'PRIVATE_LIMITED', value: 'COMPANY_PRIVATE_LIMITED' },
    { name: 'PUBLIC_LIMITED', value: 'COMPANY_PUBLIC_LIMITED' },
    {
      name: 'PUBLIC_SECTOR_UNDERTAKING',
      value: 'PUBLIC_SECTOR_UNDERTAKING',
    },
    { name: 'SOCIETY_TRUST', value: 'SOCIETY_TRUST' },
    { name: 'SOLE_PROPRIETORSHIP', value: 'SOLE_PROPRIETORSHIP' },
  ],

  currentResidenceStatusValues: [
    {
      name: 'SELF_OWNED',
      value: 'selfOwned',
    },
    {
      name: 'PARENTAL',
      value: 'parental',
    },
    {
      name: 'RENTED',
      value: 'rented',
    },
  ],

  typeOfLoan: [
    {
      name: 'NEW_LOAN',
      value: 'NEW_LOAN',
    },
    {
      name: 'BALANCE_TRANSFER',
      value: 'BALANCE_TRANSFER',
    },
  ],

  typeOfBankAccount: [
    {
      name: 'SAVINGS',
      value: 'SAVINGS',
    },
    {
      name: 'CURRENT',
      value: 'CURRENT',
    },
    {
      name: 'CC/OD',
      value: 'CC/OD',
    },
    {
      name: 'SALARY',
      value: 'SALARY',
    },
  ],

  refDefaultValues: {
    name: '',
    phoneNumber: '',
    address: '',
    referenceCity: '',
  },

  basicInformationDefaultValues: {
    emailId: '',
    loanTenureInMonths: '',
    requiredLoanAmount: '',
    loanType: 'PERSONAL',
    haveExistingLoanWithAavas: '',
    typeOfLoan: '',
    product: '',
    // referCode: '',
    endUseForMSME: '',
    otherEmiPaidMonthly: '',
    isAssistedByEmployeeOfAavas: false,
    assistanceByEmployee: false,
    assistanceByOthers: false,
    aavasEmployeeName: '',
    aavasEmployeeCode: '',
    associateUserId: '',
    associateVendorName: '',
  },

  bankingDefaultValues: {
    bankName: '',
    accountNo: '',
    ifsc: '',
    micr: '',
    bankDetails: {
      address: '',
      branch: '',
      city: '',
      contact: '',
      district: '',
      state: '',
    },
  },

  ApplicantDetailsDefaultValues: {
    applicantMyDetails: {
      email: '',
      phoneNumber: '',
      verifiedPhoneNumber: '',
      otpSent: 'false',
      otp: '',
      otpVerified: 'false',
      details: '',
      firstName: '',
      lastName: '',
      middleName: '',
      name: '',
      gender: '',
      customerType: 'INDIVIDUAL',
      fatherName: '',
      motherName: '',
      spouseName: '',
      dateOfBirth: new Date(new Date().setFullYear(new Date().getFullYear() - 44)),
      maritalStatus: '',
      religion: '',
      casteCategory: '',
      occupation: '',
      panCardNumber: '',
      aadharCardNumber: '',
      drivingLicense: '',
      voterId: '',
      relationshipWithMainApplicant: '',
      residencyStatus: 'RESIDENCE',
      crmAccountId: '',
    },
    applicantAddress: {
      current: { isCommunicationAddress: true, ...address },
      permanent: { isSameAsCurrentAddress: false, isCommunicationAddress: false, ...address },
    },
    bankOrCash: {
      nameOfCompany: '',
      officialEmailId: '',
      officialPhoneNumber: '',
      monthlyNetIncome: '',
      subSector: '',
      address: '',
      itrFiledForIncomeDeclared: 'true',
    },

    SELF_EMPLOYED_PROFESSIONAL: {
      constitutionOfCompany: '',
      profession: '',
      officialEmailIdId: '',
      officialPhoneNumber: '',
      monthlyNetIncome: '',
      sector: '',
      subSector: '',
      address: '',
      itrFiledForIncomeDeclared: 'true',
    },
    SELF_EMPLOYED_BUSINESSPERSON: {
      constitutionOfCompany: '',
      nameOfEnterprise: '',
      officialEmailIdId: '',
      officialPhoneNumber: '',
      monthlyNetIncome: '',
      isThisAnMSME: '',
      msmeClassification: '',
      sector: '',
      subSector: '',
      isTheMSMEUdyamRegistered: '',
      gstNo: '',
      itrFiledForIncomeDeclared: 'true',
    },
    NONINDIVIDUAL: {
      isTheMSMEUdyamRegistered: '',
      constitutionOfCompany: '',
      isThisAnMSME: '',
      msmeClassification: '',
      officialEmailId: '',
      officialPhoneNumber: '',
      monthlyNetIncome: '',
      entityName: '',
      subSector: '',
      gstNo: '',
      dateOfEstablishment: '',
      sector: '',
      companyWebsite: '',
      itrFiledForIncomeDeclared: 'true',
    },
  },

  AssetDetailsDefaultValues: {
    typeOfProperty: '',
    // isCurrentAddSameAsCollateralProperty: false,
    address,
    // isPropertyIdentified: false,
    propertyOwnership: 'INDIVIDUAL',
    // displayProposedAreaOfConstruction: false,
    propertyOwners: '',
  },

  allStepsForAavasLoan: [
    { label: 'PHOTOS_FOR_OCR', key: 'isOcrDocumentSaved', available: 'isPhotosForOcrAvailable', route: ROUTE.OCR },
    {
      label: 'BASIC_INFORMATION',
      key: 'isApplicationInfoSaved',
      available: 'isApplicationInfoAvailable',
      route: ROUTE.BASIC_INFORMATION,
    },
    {
      label: 'APPLICANT_INFORMATION',
      key: 'isApplicantInfoSaved',
      available: 'isApplicantInfoAvailable',
      route: ROUTE.APPLICANT_DETAILS,
    },
    {
      label: 'ASSET_DETAILS',
      key: 'isAssetInfoSaved',
      available: 'isAssetDetailsAvailable',
      route: ROUTE.ASSET_DETAILS,
    },
		{
      label: 'REQUIRED_DOCUMENTS',
      key: 'isFtrDocumentSaved',
      available: 'isRequiredDocumentsAvailable',
      route: ROUTE.REQUIRED_DOCUMENTS,
    },
    { label: 'FEE_PAYMENT', key: 'isFeePaymentSaved', available: 'isFeePaymentAvailable', route: ROUTE.FEE_PAYMENT },
    {
      label: 'APPLICATION_STATUS',
      key: 'isApplicationStatusSaved',
      available: 'isApplicationStatusAvailable',
      route: ROUTE.APPLICATION_STATUS,
    },
  ],

  allAppliicationStatus: ['CAPTURED', 'GOOD_LEAD', 'IN_REVIEW', 'APPROVED', 'DISBURSED'],

  photoOfOcrDocType: {
    adhar: 'AADHAAR',
    bank_checkque: 'CHEQUE',
    driving_license: 'DRIVING_LICENSE',
    pan_card: 'PAN',
    passport_card: 'PASSPORT',
    aadhaar_back: 'AADHAAR_BACK',
    voter_card: 'VOTER_CARD',
  },

  docType: {
    // pan: 'pan_card',
    // aadhar: 'adhar',
    // driving_license: 'driving_license',
    PAN: 'pan_card',
    AADHAAR: 'adhar',
    DRIVING_LICENSE: 'driving_license',
    PASSPORT: 'passport',
    AADHAAR_BACK: 'aadhaar_back',
    VOTER_CARD: 'voter_card',
    aadhaar_back: 'AADHAAR_BACK',
  },

  tabAsRoute: {
    [ROUTE.PHOTOS_FOR_OCR]: 0,
    [ROUTE.BASIC_INFORMATION]: 1,
    [ROUTE.APPLICANT_DETAILS]: 2,
    [ROUTE.ASSET_DETAILS]: 3,
    [ROUTE.FEE_PAYMENT]: 4,
    [ROUTE.REQUIRED_DOCUMENTS]: 5,
    [ROUTE.APPLICATION_STATUS]: 6,
  },

  routesAsTabs: {
    0: ROUTE.PHOTOS_FOR_OCR,
    1: ROUTE.BASIC_INFORMATION,
    2: ROUTE.APPLICANT_DETAILS,
    3: ROUTE.ASSET_DETAILS,
    4: ROUTE.FEE_PAYMENT,
    5: ROUTE.REQUIRED_DOCUMENTS,
    6: ROUTE.APPLICATION_STATUS,
  },

  titleAsTabs: {
    0: 'PHOTOS_FOR_OCR',
    1: 'BASIC_INFORMATION',
    2: 'APPLICANT_DETAILS',
    3: 'ASSET_DETAILS',
    4: 'FEE_PAYMENT',
    5: 'REQUIRED_DOCUMENTS',
		6 : 'APPLICATION_STATUS'
  },

  requiredDocType: {
    driving_license: 'DRIVING_LICENSE',
    pan: 'PAN',
    passport: 'Passport',
    AADHAAR: 'adhar',
  },

  applicantionProgressAvailable: {
    isFtrDocumentSaved: ['isPhotosForOcrAvailable', 'isApplicationInfoAvailable'],
    isOcrDocumentSaved: ['isApplicationInfoAvailable'],
    isApplicationInfoSaved: ['isPhotosForOcrAvailable', 'isApplicantInfoAvailable'],
    isApplicantInfoSaved: ['isPhotosForOcrAvailable', 'isAssetDetailsAvailable'],
    isAssetInfoSaved: ['isPhotosForOcrAvailable', 'isRequiredDocumentsAvailable'],
    isFeePaymentSaved: ['isPhotosForOcrAvailable', 'isRequiredDocumentsAvailable','isApplicationStatusAvailable'],
    isApplicationStatusSaved: ['isFeePaymentAvailable'],
  },

  gstNoRegex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  panCardRegex: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
  ONLY_ALPHABETS: /^[a-zA-Z\s-]+$/,
  ALPHA_NUMERIC_DASH_REGEX: /^[a-zA-Z0-9\s-]+$/,
  ALPHA_NUMERIC_DASH_SLASH_REGEX: /^[a-zA-Z0-9\s-/]+$/,
  JPG_JPEG_REGEX: /^(image\/)/i,
  JPG_JPEG_PDF_REGEX: /(^image\/|\/pdf$)/i,
  VOTER_CARD_VALIDATION: /^[A-Z]{3}[0-9]{7}$/,
  AADHAR_REGEX: /^[0-9]{12}$/,
  ONLY_NUMBER: /^[0-9]*$/i,
  applicationStatus: [
    'draft',
    'application processing',
    'credit approval',
    'sanction',
    'disbursement (cheque handover)',
  ],
  DOC_TYPE: {
    'Driving License': 'DRIVING_LICENSE',
    Aadhar: 'AADHAR',
    'Aadhar Back': 'AADHAR_BACK',
    Cheque: 'CHEQUE',
    'PAN Card': 'PAN',
    Passport: 'PASSPORT',
    'Selfie Photos': 'CURRENT_ADDRESS_PROOF',
    'Bank Statement': 'BANK_STATEMENT',
    'Udhyam Registration': 'UDHYAM_REGISTERED_CERTIFICATE',
    'Consent Form': 'CONCENT_FORM',
    'Form 60': 'FORM_60',
    getlale1: 'CURRENT_ADDRESS_PROOF',
    getlale2: 'PROPERTY_DOCUMENT',
    getlale3: 'INCOME_DOCUMENT',
  },
};
