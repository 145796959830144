  import { httpService } from '../helper';
  import { constants } from '../constants';

  export const getAddredByPincode = (pinCode) => httpService().get(`${constants.API.GET_IP_LOCATION}?pincode=${pinCode}`);

  export const postBasicInformationFrom = httpService(null, null, true).post.bind(null, `${constants.API.BASIC_LOAN_INFO}`);
  export const getBasicInformationFrom = httpService(null, null, true).get.bind(null, `${constants.API.BASIC_LOAN_INFO}`);
  export const updateBasicInformationFrom = (data, applicationId) => {
    const url = `${constants.API.UPDATE_BASIC_INFO}/${applicationId}`;
    return httpService(null, null, true).put(url, data);
  };
  