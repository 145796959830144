import { httpService } from '../helper';
import { constants } from '../constants';

export const uploadRequiredDocument = async (data, controller) => httpService(data, controller).post(constants.API.SAVE_REQUIRED_DOC, data);

export const deleteRequiredDocument = async (data) =>
  httpService().delete(
    `${constants.API.DELETE_REQUIRED_DOC}/${data.DOCUMENT_TYPE}`
  );

export const getRequiredDocument = httpService().get.bind(null, constants.API.GET_REQUIRED_DOC);
export const saveBankDetails = httpService().post.bind(this, constants.API.SAVE_BANKING_DETAILS);

export const postUploadDocToAvaas = httpService().post.bind(this, constants.API.DOCUMENT_UPLOAD_AAVAS);
