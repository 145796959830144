import React, { memo, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// @mui
import { Typography, Box, LinearProgress, TextField } from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon, Image as ImageIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { photosForOcrItem as Item } from '../../styled-components';

// ----------------------------------------------------------------------

OCRCard.propTypes = {
  deleteFile: PropTypes.func.isRequired,
  onCancelUpload: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  fileData: PropTypes.object.isRequired,
  uploaded: PropTypes.bool.isRequired,
};

function OCRCard({ uploaded, label, name, fileData, onCancelUpload, onChange, deleteFile, ...other }) {
  const inputRef = useRef();
  const [cancelRefresh, setCancelRefresh] = useState(false);
  const source = useMemo(() => axios.CancelToken.source(), [cancelRefresh]);
  const { t } = useTranslation('translation');

  const onCancelFileUpload = () => {
    onCancelUpload(source, name);
    setCancelRefresh((prev) => !prev);
  };

  const { asterisk, helperText, ...rest } = other;

  return (
    <Box mt={4}>
      <Typography>
        {t(label)}
        {asterisk && !t(label).includes(' *') && ' *'}
      </Typography>
      <Item>
        <ImageIcon />
        {!fileData?.status && (
          <button
            type="button"
            onClick={() => {
              inputRef.current.click();
              return Promise.resolve();
            }}
          >
            {t('UPLOAD')}
            <TextField
              inputRef={inputRef}
              type="file"
              accept="image/png, image/jpg, image/jpeg, application/pdf"
              name={name}
              onChange={onChange.bind(this, source)}
              {...rest}
            />
          </button>
        )}
        {+fileData?.status !== 0 && (
          <Box className={`image-upload-progress ${+fileData?.status === 2 ? 'image-uploaded' : ''}`}>
            <Box className="fileName">
              <Box className="overflowText">{fileData?.file?.name || ''}</Box>
              {+fileData?.status === 1 && !uploaded && (
                <Box className="progress-bar" sx={{ width: '100%', marginTop: '2px' }}>
                  <LinearProgress color="success" />
                </Box>
              )}
            </Box>
            {+fileData?.status === 1 && !uploaded && (
              <Box className="uploadingDiv">
                <Typography>{t('UPLOADING')}</Typography>
                <CloseIcon color="error" onClick={onCancelFileUpload} />
              </Box>
            )}
            {uploaded && +fileData?.status !== 2 && (
              <Box className="SuccessDiv">
                <Typography>{t('UPLOADED')}</Typography>
                <DoneIcon color="success" />
              </Box>
            )}
            {+fileData?.status === 2 && <DeleteIcon color="error" onClick={deleteFile.bind(this, name, label)} />}
          </Box>
        )}
      </Item>
      {helperText && (
        <Box
          sx={() => ({
            marginLeft: '14px',
            color: '#7986cb',
            fontSize: '0.75rem',
            fontWeight: 400,
            marginTop: '3px !important',
          })}
        >
          {t(helperText)}
        </Box>
      )}
    </Box>
  );
}

export default memo(OCRCard);
