import { styled, Paper, Box, Dialog } from '@mui/material';

export const photosForOcrItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.ocrCardBg,
  padding: '10px 16px 10px 14px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.accordionBg,
  },
  '& svg': {
    fill: theme.palette.ocrImgColor,
    fontSize: 40,
    marginRight: 'auto',
  },
  '& button': {
    border: 0,
    borderRadius: '4px',
    backgroundColor: theme.palette.navyBlue,
    padding: '8px 20px',
    color: theme.palette.common.white,
    cursor: 'pointer',
    boxShadow: theme.palette.ocrButtonShadow,
    position: 'relative',
    '& div': {
      opacity: 0,
    },
    '& input': {
      position: 'absolute',
      display: 'none',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      zIndex: -1,
    },
  },
  '& .image-upload-progress': {
    paddingLeft: 15,
    marginRight: 'auto',
    width: 'calc(100% - 40px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& svg': {
      fontSize: 22,
      cursor: 'pointer',
    },
    '& .progress-bar': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiLinearProgress-root': {
        width: '100%',
        backgroundColor: theme.palette.progressTrackColor,
        '& + svg ': {
          margin: 0,
          fontSize: 25,
          marginLeft: 10,
          cursor: 'pointer',
          '& path': {
            fill: theme.palette.common.red,
          },
        },
      },
    },
  },
  '& .fileName': {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    paddingRight: 20,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '& .overflowText': {
      textOverflow: 'ellipsis',
      width: '100%',
      overflow: 'hidden',
    },
  },
  '& .SuccessDiv': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& p': {
      fontSize: 12,
      paddingRight: 12,
      marginBottom: 0,
    },
    '& svg': {
      fill: theme.palette.success.darker,
      marginRight: 0,
    },
  },
  '& .uploadingDiv': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& p': {
      fontSize: 12,
      paddingRight: 12,
      marginBottom: 0,
    },
    '& svg': {
      fill: theme.palette.error.main,
      marginRight: 0,
    },
  },
  '& .image-uploaded': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .fileName': {
      width: '100%',
    },
    '&  svg ': {
      margin: 0,
      fontSize: 25,
      marginLeft: 10,
      cursor: 'pointer',
      '& path': {
        fill: theme.palette.common.red,
      },
    },
  },
}));

const isErrorDisplay = (_) => _ === 'true';

export const photosForOcrCardContainerStyle = styled(Box)(({ theme, iserrordisplayed }) => ({
  padding: isErrorDisplay(iserrordisplayed) ? '214px 120px 50px' : '180px 120px 50px',
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: isErrorDisplay(iserrordisplayed) ? '214px 20px 50px' : '170px 20px 50px',
  },
  '& .title': {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      fontSize: 18,
      fontWeight: 500,
    },
  },
  '& .contentBox': {
    marginBottom: '20px',
    '& .separatorTitle': {
      width: '100% !important',
    },
    '&.requiredContentBox': {
      '& > .MuiBox-root': {
        width: '100%',
      },
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '& > .MuiBox-root': {
        width: '49%',
      },
    },
    '& .separator': {
      background: theme.palette.ocrCardBg,
      margin: '40px -20px',
      height: 1,
      [theme.breakpoints.up('sm')]: {
        margin: '40px 0 0',
        width: '100%',
      },
    },
    '& .orText': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 40,
      justifyContent: 'space-between',
      '& .lines': {
        background: theme.palette.ocrCardBg,
        // height: 1,
        // flex: 1,
      },
      '& .text': {
        padding: '0 10px',
        fontSize: 14,
        fontWeight: 'bold',
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        paddingTop: 32,
      },
    },
    '& p': {
      marginBottom: '6px',
      color: theme.palette.common.black,
      fontSize: '16px',
    },
  },
  '& .buttonBox': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '88px',
    '& button': {
      fontSize: 14,
      borderRadius: '4px',
      fontWeight: 500,
      textTransform: 'uppercase',
      height: 44,
    },
    '& .skipButton': {
      backgroundColor: theme.palette.ocrCardBg,
      color: theme.palette.common.black,
      marginRight: '16px',
    },
    '& .saveButton': {
      backgroundColor: theme.palette.navyBlue,
      color: theme.palette.common.white,
      flex: 1,
      [theme.breakpoints.up('sm')]: {
        flex: 'inherit',
      },
    },
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      padding: 20,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 9,
      background: theme.palette.common.white,
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
      marginTop: 88,
      '& button': {
        padding: '0 20px',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    padding: isErrorDisplay(iserrordisplayed) ? '184px 20px 50px' : '140px 20px 80px',
  },
}));

export const photosForOcrDialogStyle = styled(Dialog)(({ theme, iserror }) => ({
  '& .MuiPaper-root': {
    padding: '30px 20px',
    '& h2': {
      padding: 0,
      marginBottom: 20,
      fontSize: 16,
      color: theme.palette.common.black,
      fontWeight: 500,
      textAlign: 'center',
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      marginBottom: 30,
      '& p': {
        fontSize: 14,
        color: theme.palette.common.black,
      },
      '& .Mui-error': {
        color: theme.palette.inputError,
      },
    },
    '& .MuiDialogActions-root': {
      padding: 0,
      justifyContent: 'flex-end',
      '& button': {
        fontSize: 16,
        textTransform: 'uppercase',
        letterSpacing: '1px',
        boxShadow: theme.palette.ocrButtonShadow,
        borderRadius: 4,
        width: '120px',
        height: 40,
        fontWeight: 500,
      },
    },
  },
  '& .fixedHeader': {
    marginBottom: '0 !important',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: '20px 0px !important',
    background: '#fff',
    zIndex: 9,
    textAlign: 'center',
    fontSize: '24px !important',
    '& + form': {
      padding: '50px 0px 50px',
    },
  },
  '& .fixedFooter': {
    position: 'absolute',
    bottom: 0,
    background: '#fff',
    zIndex: 9,
    left: 0,
    right: 0,
    justifyContent: 'center !important',
    padding: '20px !important',
  },
  '& .scrollBlock': {
    height: 'calc(100vh - 240px)',
    marginBottom: '0 !important',
    padding: '0 20px !important',
    paddingTop: `${isErrorDisplay(iserror) ? 48 : 10}px !important`,
  },
  '& .crossIcon': {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
  },
  '& .acceptOfferBtn': {
    fontSize: 12,
    width: 300,
    margin: 'auto',
    [theme.breakpoints.between('xs', 'lg')]: {
      fontSize: 10,
      width: '300px !important',
    },
  },
  '& .dialogTitle': {
    fontSize: '24px !important',
    margin: 'auto',
    textTransform: 'capitalize',
  },
  '& .provisionalOfferDetails': {
    textAlign: 'center',
    fontWeight: 300,
    color: '#00000080',
    padding: '10px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px',
    },
    '& span': {
      fontWeight: '700 !important',
      color: '#000000',
    },
  },
  '& .provisionalOfferHR': {
    margin: '30px 20px',
  },
  '& .provisionalOfferHR2nd': {
    margin: '0 20px 20px 20px',
  },
  '& .eligibleLoanAmountHeading': {
    textAlign: 'center',
    fontWeight: 300,
    padding: '0px 30px',
  },
  '& .loanAmount': {
    fontSize: '36px !important',
    margin: '15px 0px',
    fontWeight: '700 !important',
  },
  '& .loanSectionDetails': {
    width: '60%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '100% ',
    },
  },
  '& .charlength': {
    display: 'flex',

    flexDirection: 'column',
    paddingBottom: '24px',
  },

  '& .charlength span': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    color: 'white',
    fontSize: '20px',
    fontWeight: '700',
    paddingBottom: '20px',
    '& .loanSectionText': {
      // fontWeight: '400',
      // fontStyle: 'italic',
      fontSize: '1.3rem',
    },
  },
  '& .loanInformation': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .loanInformationBox': {
    display: 'flex',
    // gap: '1px',

    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '30%',
      gap: '5px',
      wordWrap: 'break-word',
    },
    // width: '30%',

    wordWrap: 'break-word',
  },
  '& .loanHeader': {
    height: '10px !important',
    [theme.breakpoints.down('sm')]: {
      height: '50px !important',
    },
  },
  '& .loanValue': {
    color: '#0F52BA !important',
  },
  '& .continueBtn': {
    width: '300px !important',
    fontSize: '12px !important',
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: 10,
      width: '220px !important',
      padding: '5px 0px',
    },
  },
}));

