import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
import { getReccurssiveObjectKeys, hanelMixPanelEvents } from '../../helper';
import { CContext } from '../utils';

RHFSelectbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  menus: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  languageDropdown: PropTypes.bool,
  disable: PropTypes.bool,
  required: PropTypes.bool,
};

const Item = styled(Select)(({ theme, languagedropdown, error }) => ({
  color: languagedropdown ? `${theme.palette.common.white} !important` : null,
  border: error ? "1px solid red" : null,

  width: languagedropdown ? '110px' : null,
  height: languagedropdown ? '42px' : null,
  '& svg': {
    fill: languagedropdown ? `${theme.palette.common.white} !important` : null,
  },
  '& fieldset': {
    borderColor: languagedropdown ? `${theme.palette.common.white} !important` : null,
    borderWidth: languagedropdown ? '1px !important' : null,
  },
}));

export default function RHFSelectbox({ name, label, menus, onChange, languageDropdown, disable, required = false }) {
  const { t } = useTranslation('translation');
  const { mixpanel } = React.useContext(CContext);
  const { control ,formState: { errors }} = useFormContext();
  // const current=name.split(".").pop();
  // const hasError = errors?.applicant?.applicantMyDetails[`${current}`];


  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...field } }) => (
        <Box>
          <FormControl fullWidth >
            <InputLabel id="demo-simple-select-label">
              {t(label)}
              {required && !t(label).includes(' *') && ' *'}
            </InputLabel>
            <Item
              {...field}
              {...(languageDropdown && { languagedropdown: languageDropdown.toString() })}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={label}
              error={!!getReccurssiveObjectKeys(errors, name)?.message}
              disabled={disable}
              MenuProps={{
                disableScrollLock: true,
                marginThreshold: null
              }}
              onBlur={(...args) => {
                hanelMixPanelEvents.call(mixpanel, t(label), name);
                field.onBlur(...args);
              }}
              onChange={(...args) => {
                if (onChange && typeof onChange === 'function') {
                  onChange(...args);
                }
                field.onChange(...args);
              }}
              value={typeof value === 'string' ? value : ''}
              >
              {menus?.map((item) => (
                <MenuItem key={item.value + item.name} value={item.value}>
                  {t(item.name)}
                </MenuItem>
              ))}
            </Item>
          </FormControl>
          <Box
            sx={() => ({
              marginLeft: '14px',
              color: '#EE353F',
              fontSize: '0.75rem',
              fontWeight: 400,
              marginTop: '3px !important',
            })}
          >
            {!!getReccurssiveObjectKeys(errors, name)?.message && t(getReccurssiveObjectKeys(errors, name)?.message)}
          </Box>
        </Box>
      )}
    />
  );
}
