import { convertDateTimeStampToYYYYMMDDFormat } from "./utils";
import { checkWhetherPUTORPOSTIsApplicableFun, transformAddressPayloadAsPerAPI } from "./payloadTransformUtilities";

export default function transformPayload(request) {
	const apiKey = request?.data?.apiKey;
	const payloadPutIdState = request?.data?.payloadPutIdState;
	const payload = request?.data?.payload;
	let transformedPayload = {};
	const coApplicantArrayTransformedPayload = []
	let checkWhetherPUTORPOSTIsApplicable = null
	if (payloadPutIdState?.applicant) {
		checkWhetherPUTORPOSTIsApplicable = checkWhetherPUTORPOSTIsApplicableFun(payloadPutIdState)
	}
	try {
		console.log(apiKey, '----------apiKey')
		switch (apiKey) {
			case 'POST_BASIC_INFO':
				transformedPayload = {
					...request,
					data: {
						data: {

							loanAmount: payload?.requiredLoanAmount,
							otherMonthlyEMI: payload?.otherEmiPaidMonthly  ? payload?.otherEmiPaidMonthly : 0,
							isNewLoan: payload?.typeOfLoan === 'NEW_LOAN',
							loanType: payload?.product,
							...(payload?.product === 'MSME' && { endUserMsme: payload?.endUseForMSME }),
							loanTenureInMonths: payload?.loanTenureInMonths,
							isAssistedByEmployeeOrAssociate: payload?.isAssistedByEmployeeOfAavas,
							references: Array.isArray(payload?.references)
								? payload.references.map(reference => ({
									fullName: reference.name,
									phoneNumber: reference.phoneNumber,
									address: reference.address,
									city: reference.referenceCity
								}))
								: [],
							assistedByEmployeeDetails: {
								employeeCode: payload?.aavasEmployeeCode,
								employeeName: payload?.aavasEmployeeName
							},
							assistedByAssociateDetails: {
								userId: payload?.associateUserId,
								vendorName: payload?.associateVendorName
							}
						}
					}
				};
				break;

			case 'PUT_BASIC_INFO':
				transformedPayload = {
					...request,
					data: {
						data: {

							loanAmount: payload?.requiredLoanAmount,
							otherMonthlyEMI: payload?.otherEmiPaidMonthly  ? payload?.otherEmiPaidMonthly : 0,
							isNewLoan: payload?.typeOfLoan === 'NEW_LOAN',
							loanType: payload?.product,
							...(payload?.product === 'MSME' && { endUserMsme: payload?.endUseForMSME }),
							loanTenureInMonths: payload?.loanTenureInMonths,
							isAssistedByEmployeeOrAssociate: payload?.isAssistedByEmployeeOfAavas,
							references: Array.isArray(payload?.references)
								? payload.references.map(reference => ({
									id: reference.id,
									fullName: reference.name,
									phoneNumber: reference.phoneNumber,
									address: reference.address,
									city: reference.referenceCity
								}))
								: [],
							assistedByEmployeeDetails: {
								employeeCode: payload?.aavasEmployeeCode,
								employeeName: payload?.aavasEmployeeName
							},
							assistedByAssociateDetails: {
								userId: payload?.associateUserId,
								vendorName: payload?.associateVendorName
							}
						}
					}
				};
				break;

			case 'POST_ASSET_INFO':
				transformedPayload = {
					...request,
					data: {
						data: {
							assetInfo: payload?.assetInfo?.map(asset => ({
								address: {
									plotNumber: asset.address.plotNumber || "",
									floorNumber: asset.address.floorNumber || "",
									streetAndBuildingName: asset.address.streetAndBuildingName || "",
									locality: asset.address.locality || "",
									landmark: asset.address.landmark || "",
									pinCode: asset.address.pinCode || "",
									villageOrTown: asset.address.villageOrTown || "",
									tehsil: asset.address.tehsil || "",
									state: asset.address.state || "",
									numberOfMonthsAtThisAddress: 0,
								},
								typeOfProperty: asset.typeOfProperty,
								propertyOwnership: asset.propertyOwnership,
								propertyOwners: asset.propertyOwners,
							}))
						}
					}

				};
				break;

			case 'POST_APPLICANT_INFO':
				// const coApplicantArrayTransformedPayload = []
				// let checkWhetherPUTORPOSTIsApplicable = checkWhetherPUTORPOSTIsApplicableFun(payloadPutIdState)

				if (request?.data?.payload?.coApplicant?.length > 0) {
					if (request?.data?.payload?.coApplicant?.length > 0) {
						for (let index = 0; index < request?.data?.payload?.coApplicant?.length; index++) {
							const coApplicantPayload = {
								applicantMyDetails: {},
								applicantAddress: [],
								employmentInfo: {}
							}
							const activeCoApplicantObject = request.data.payload.coApplicant[index];
							let activeCoApplicantAddressArray = []
							if (payloadPutIdState?.coApplicant?.length > 0 && payloadPutIdState?.coApplicant[index]?.applicantAddress?.length >= 2) {
								activeCoApplicantAddressArray = [payloadPutIdState?.coApplicant[index]?.applicantAddress[0], payloadPutIdState?.coApplicant[index]?.applicantAddress[1]]
							}
							coApplicantPayload.applicantMyDetails = {
								...activeCoApplicantObject.applicantMyDetails,
								id: payloadPutIdState?.coApplicant[index]?.applicantMyDetails,
								indianResident: activeCoApplicantObject?.applicantMyDetails?.residencyStatus === 'RESIDENCE',
								dateOfBirth: convertDateTimeStampToYYYYMMDDFormat(activeCoApplicantObject?.applicantMyDetails?.dateOfBirth),
								drivingLicenseNumber: activeCoApplicantObject?.applicantMyDetails?.drivingLicense,
								voterCardNumber: activeCoApplicantObject?.applicantMyDetails?.voterId,
								emailId: activeCoApplicantObject?.applicantMyDetails?.email,
								phoneNumber: activeCoApplicantObject?.applicantMyDetails?.phoneNumber?.includes('+91') ? activeCoApplicantObject?.applicantMyDetails?.phoneNumber?.replace('+91', '') : activeCoApplicantObject?.applicantMyDetails?.phoneNumber
							}
							coApplicantPayload.applicantAddress = transformAddressPayloadAsPerAPI(
								activeCoApplicantObject.applicantAddress,
								activeCoApplicantAddressArray
							)
							coApplicantPayload.employmentInfo = {
								...activeCoApplicantObject.employmentInfo,
								id: payloadPutIdState?.coApplicant[index]?.employmentInfo
							}
							coApplicantArrayTransformedPayload.push(coApplicantPayload)
						}
					}

				}
				if (checkWhetherPUTORPOSTIsApplicable === "PUT") {
					console.log(payloadPutIdState, '------------------------payloadPutIdState')
					let mainApplicantEmploymentInfo = {}
					if (request?.data?.payload?.applicant?.employmentInfo?.nameOfCompany?.length > 0) {
						mainApplicantEmploymentInfo = { ...request.data.payload.applicant.employmentInfo }
					}

					transformedPayload = {
						...request,
						data: {
							data: {
								applicant: {
									applicantMyDetails: {
										...request.data.payload.applicant.applicantMyDetails,
										id: payloadPutIdState?.applicant?.applicantMyDetails,
										indianResident: payload?.applicant?.applicantMyDetails?.residencyStatus === 'RESIDENCE',
										dateOfBirth: convertDateTimeStampToYYYYMMDDFormat(payload?.applicant?.applicantMyDetails?.dateOfBirth),
										drivingLicenseNumber: payload?.applicant?.applicantMyDetails?.drivingLicense,
										voterCardNumber: payload?.applicant?.applicantMyDetails?.voterId,
										emailId: payload?.applicant?.applicantMyDetails?.email
									},
									applicantAddress: transformAddressPayloadAsPerAPI(
										request.data.payload.applicant.applicantAddress,
										payloadPutIdState?.applicant?.applicantAddress
									),
									employmentInfo: {
										...mainApplicantEmploymentInfo,
										id: payloadPutIdState?.applicant?.employmentInfo
									}
								},
								coApplicant: coApplicantArrayTransformedPayload
							}
						}
					};
				} else if (checkWhetherPUTORPOSTIsApplicable === "POST") {
					let mainApplicantEmploymentInfo = {}
					if (request?.data?.payload?.applicant?.employmentInfo?.nameOfCompany?.length > 0) {
						mainApplicantEmploymentInfo = { ...request.data.payload.applicant.employmentInfo }
					}

					transformedPayload = {
						...request,
						data: {
							data: {
								applicant: {
									applicantMyDetails: {
										...request.data.payload.applicant.applicantMyDetails,
										dateOfBirth: convertDateTimeStampToYYYYMMDDFormat(payload?.applicant?.applicantMyDetails?.dateOfBirth),
										drivingLicenseNumber: payload?.applicant?.applicantMyDetails?.drivingLicense,
										indianResident: payload?.applicant?.applicantMyDetails?.residencyStatus === 'RESIDENCE',
										voterCardNumber: payload?.applicant?.applicantMyDetails?.voterId,
										emailId: payload?.applicant?.applicantMyDetails?.email
									},
									applicantAddress: [
										...transformAddressPayloadAsPerAPI(request.data.payload.applicant.applicantAddress),
									],
									employmentInfo: mainApplicantEmploymentInfo
								},
								coApplicant: coApplicantArrayTransformedPayload
							}
						}
					};
				}
				delete transformedPayload?.data?.data?.applicant?.applicantMyDetails?.email
				delete transformedPayload?.data?.data?.applicant?.applicantMyDetails?.voterId
				delete transformedPayload?.data?.data?.applicant?.applicantMyDetails?.drivingLicense

				break;

			case 'POST_REGISTER':
				transformedPayload = {
					...request,
					data: {
						data: {
							customerType : payload?.customerType,
							emailId : payload?.email,
							firstName : payload?.firstName,
							lastName : payload?.lastName,
							middleName : payload?.middleName,
							password : payload?.password,
							phoneNumber : payload?.phoneNumber
						}
					}

				};
				break;

			default:
				transformedPayload = request;
				break;
		}

	} catch (error) {
		console.log(error, '----------------error')
		if (transformedPayload?.headers) {
			delete transformedPayload.headers;
		}
		return error
	}

	console.log(transformedPayload, '----------------transformedPayload')
	if (transformedPayload?.headers) {
		delete transformedPayload.headers;
	}

	return transformedPayload;
}
