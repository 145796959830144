import React, { memo, useContext, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @mui
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import {
  Typography,
  Box,
  Stack,
  LinearProgress,
  Button,
  ClickAwayListener,
  Stepper,
  Step,
  StepLabel,
  Tooltip,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import ForumIcon from '@mui/icons-material/Forum';
import { constants } from '../../constants';
import { CContext } from '../utils';
import ErrorMessage from './ErrorMessage';
import { StepperBox } from '../../styled-components';
import Logo from '../../assets/images/habilelabstagline.png';

const { ROUTE } = constants;

const FixedContainerStyle = styled(Box)(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  right: 0,
  zIndex: 9,
}));

const WebContainerStyle = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  right: 0,
  zIndex: 9,
  background: theme.palette.common.white,
  '& .status-title': {
    padding: '2px 120px 15px',
    fontSize: 22,
    fontWeight: 500,
    boxShadow: `0px 3px 4px ${theme.palette.greyishColor}`,
    [theme.breakpoints.between('sm', 'lg')]: {
      padding: '2px 20px 15px',
    },
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  backgroundColor: theme.palette.navyBlue,
  padding: '30px 50px',
  boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2)',
  '& > p': {
    color: theme.palette.common.white,
    fontSize: 16,
    marginBottom: 0,
    paddingTop: '10px',
    lineHeight: '22px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px 20px',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));
const TopConatinerStyle = styled(Box)(({ theme }) => ({
  '& h4': {
    color: theme.palette.common.white,
    fontSize: '23px',
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10,
    '@media (max-width: 375px)': {
      fontSize: 15,
    },
    '@media (min-width: 376px) and (max-width: 413px)': {
      fontSize: 18,
    },
    '& svg': {
      fill: theme.palette.common.white,
      fontSize: 24,
      marginLeft: '4px',
      '&.backarrow': {
        marginLeft: 0,
        fontSize: 28,
        marginRight: 4,
      },
    },
    '& .bottomArrow': {
      cursor: 'pointer',
    },
  },
  '& .rightSection': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .returnButton': {
      background: theme.palette.common.red,
      color: theme.palette.common.white,
      borderRadius: 5,
      padding: '6px 16px',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      marginLeft: 10,
      '@media (max-width: 375px)': {
        padding: '6px 10px',
      },
    },
    '& .languageSelectorContainer': {
      position: 'relative',
      '& .languageSelectorBox': {
        width: 34,
        height: 34,
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.common.white,
        background: theme.palette.common.blue,
        border: `1px solid ${theme.palette.dropdownHr}`,
        padding: '0 5px',
        '& p': {
          fontSize: 16,
          fontWeight: 400,
          margin: 0,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      },
      '& .languageDropdown': {
        background: theme.palette.common.white,
        zIndex: 100,
        right: -10,
        position: 'absolute',
        top: 40,
        flexDirection: 'column',
        boxShadow: theme.palette.dropdownShadow,
        borderRadius: 4,
        width: 172,
        opacity: 0,
        transition: '0.3s opacity linear',
        pointerEvents: 'none',
        '&.open': {
          opacity: 1,
          pointerEvents: 'unset',
        },
        '& > div': {
          width: '100%',
          fontSize: 18,
          fontWeight: 400,
          padding: '16px 20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: theme.palette.common.black,
          '& svg': {
            fill: theme.palette.common.blue,
            fontSize: 18,
          },
          '&:first-child': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          },
        },
      },
    },
    '& > svg': {
      fill: theme.palette.common.white,
      fontSize: 24,
      marginLeft: 10,
    },
  },
}));

const ProgressBarStyle = styled(Stack)(({ theme }) => ({
  '& > span': {
    height: 8,
    backgroundColor: theme.palette.progressBarbg,
    '& span': {
      backgroundColor: theme.palette.success.darker,
      borderRadius: 20,
    },
  },
}));

const WebHeaderStyle = styled('header')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '14px 120px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: '15px 20px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  '& img': {
    height: 50,
  },
  '& .languageBox': {
    display: 'flex',
    marginLeft: 'auto',
    // marginRight: 20,
    '& > div': {
      border: `1px solid ${theme.palette.greyBorder}`,
      padding: '3px 0',
      width: 80,
      fontSize: 16,
      textAlign: 'center',
      color: theme.palette.grey[2000],
      cursor: 'pointer',
      '&.active': {
        border: `1px solid ${theme.palette.common.blue}`,
        color: theme.palette.common.blue,
      },
      '&.engLan': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        '& + .engLan': {
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      },
      '&.hinLan': {
        borderTopRightRadius: 4,
        borderBottomRighRadius: 4,
      },
    },
  },
  '& .iconBox': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      marginLeft: 30,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        fill: theme.palette.common.blue,
        fontSize: 30,
      },
    },
  },
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.success.darker,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.success.darker,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.greyBorder,
    borderRadius: 1,
  },
}));

CommonHeader.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  isButton: PropTypes.bool,
  backArrow: PropTypes.bool,
  steps: PropTypes.array,
  webViewOnly: PropTypes.bool,
  isWithoutStepper: PropTypes.bool,
};

function CommonHeader({ title, desc, isButton, backArrow, steps, webViewOnly, isWithoutStepper }) {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const [chatBotOpen, setChatBotOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const {
    lang,
    isMobile,
    activeStep,
    hidestepper,
    setActiveStep,
    changeLanguage,
    applicationProgress: { completed, available, allStepsCount },
    showProvisionalOffer,
    offerAccepted,
  } = useContext(CContext);

  const handleChange = (value, event) => {
    if ((event?.target?.value || value) !== lang) {
      changeLanguage();
    }
    setDropdownVisible(false);
  };

  const openDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const handleClickAway = () => {
    setDropdownVisible(false);
  };

  const applicaitionProgressBar = useMemo(() => (completed.length / allStepsCount) * 100, [completed]);

  const classNameForTitle = useCallback(
    (item) => {
      // console.log(`..............available....`)
      // console.log(available)
      let className = 'hand-icon';
      if (item.available === "isRequiredDocumentsAvailable") {
        return className;
      } if (available.includes(item.available) || completed.includes(item.key)) {
        return className;
      }
      className = 'disabled-title'
      return className;
    },
    [completed, available]
  );

  const hideButton = useMemo(
    () => window?.location?.pathname?.toLowerCase() === ROUTE.APPLICATION_STATUS,
    [window?.location?.pathname]
  );

  return (
    <>
      {isMobile && (
        <FixedContainerStyle>
          <HeaderStyle>
            <TopConatinerStyle display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" gutterBottom>
                {backArrow && !hidestepper && (
                  <ArrowBackIcon
                    onClick={navigate.bind(null, ROUTE.APPLICANT_DETAILS, { replace: true })}
                    className="backarrow"
                  />
                )}
                {title}
                {isButton || hideButton || hidestepper ? null : (
                  <KeyboardArrowDownIcon
                    className="bottomArrow"
                    onClick={navigate.bind(null, `${ROUTE.APPLICATION_PROGRESS}?previous=${window.location.pathname}`, {
                      replace: true,
                    })}
                  />
                )}
              </Typography>
              <Box className="rightSection">
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Box className="languageSelectorContainer">
                    <Box className="languageSelectorBox" onClick={openDropdown}>
                      <Typography>{lang.toUpperCase()}</Typography>
                    </Box>
                    <Box className={`languageDropdown ${dropdownVisible ? 'open' : null}`}>
                      {[
                        { label: 'English', value: 'en' },
                        { label: 'हिन्दी', value: 'hi' },
                      ].map((item) => (
                        <Box key={item.value} onClick={handleChange.bind(this, item.value)}>
                          {t(item.label)}
                          {lang === item.value && <CheckIcon />}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </ClickAwayListener>
                <ForumIcon
                  onClick={() => {
                    const w = window;
                    const ic = w.YellowMessenger;
                    if (typeof ic === 'function') {
                      if (chatBotOpen) {
                        setChatBotOpen(false);
                        w.YellowMessengerPlugin.hide();
                      } else {
                        setChatBotOpen(true);
                        w.YellowMessengerPlugin.show();
                        w.YellowMessengerPlugin.openBot();
                      }
                    } else {
                      const d = document;
                      const i = (...args) => {
                        i.c(...args);
                      };
                      const l = () => {
                        setChatBotOpen(true);
                        const e = d.createElement('script');
                        e.type = 'text/javascript';
                        e.async = !0;
                        e.src = 'https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js';
                        const t = d.getElementsByTagName('script')[0];
                        t.parentNode.insertBefore(e, t);
                        setTimeout(() => {
                          w.YellowMessengerPlugin.show();
                          w.YellowMessengerPlugin.openBot();
                        }, 1000);
                      };
                      i.q = [];
                      i.c = (e) => {
                        i.q.push(e);
                      };
                      w.YellowMessenger = i;
                      if (w.attachEvent) {
                        w.attachEvent('onload', l);
                      } else {
                        l();
                      }
                    }
                  }}
                />
                {isButton && (
                  <Button
                    onClick={navigate.bind(
                      null,
                      new URLSearchParams(window.location.search).get('previous') || ROUTE.OCR,
                      { replace: true }
                    )}
                    className="returnButton"
                  >
                    {t('RETURN')}
                  </Button>
                )}
              </Box>
            </TopConatinerStyle>
            {desc && <Typography gutterBottom>{desc}</Typography>}
          </HeaderStyle>
          <ProgressBarStyle>
            <LinearProgress variant="determinate" value={applicaitionProgressBar} />
          </ProgressBarStyle>
          <ErrorMessage />
        </FixedContainerStyle>
      )}
      {!isMobile && webViewOnly && (
        <WebContainerStyle>
          <WebHeaderStyle>
            <Box>
              <img src={Logo} alt="logo" />
            </Box>
            <Box className="languageBox">
              {[
                { label: 'English', value: 'en' },
                { label: 'हिन्दी', value: 'hi' },
              ].map((item) => (
                <Box
                  key={item.value}
                  onClick={handleChange.bind(this, item.value)}
                  className={`engLan ${lang === item.value ? 'active' : ''}`}
                >
                  {t(item.label)}
                </Box>
              ))}
            </Box>
            {/* <Box className="iconBox">
              <Box> */}
                {/* <Tooltip title={!chatBotOpen ? 'chat with us' : 'close Chat'} aria-label="add" placement="right" arrow> */}
                  {/* <ForumIcon */
                    // onClick={() => {
                    //   const w = window;
                    //   const ic = w.YellowMessenger;
                    //   if (typeof ic === 'function') {
                    //     if (chatBotOpen) {
                    //       setChatBotOpen(false);
                    //       w.YellowMessengerPlugin.hide();
                    //     } else {
                    //       setChatBotOpen(true);
                    //       w.YellowMessengerPlugin.show();
                    //       w.YellowMessengerPlugin.oonClick={() => {
                    //   const w = window;
                    //   const ic = w.YellowMessenger;
                    //   if (typeof ic === 'function') {
                    //     if (chatBotOpen) {
                    //       setChatBotOpen(false);
                    //       w.YellowMessengerPlugin.hide();
                    //     } else {
                    //       setChatBotOpen(true);
                    //       w.YellowMessengerPlugin.show();
                    //       w.YellowMessengerPlugin.openBot();
                    //     }
                    //   } else {
                    //     const d = document;
                    //     const i = (...args) => {
                    //       i.c(...args);
                    //     };
                    //     const l = () => {
                    //       setChatBotOpen(true);
                    //       const e = d.createElement('script');
                    //       e.type = 'text/javascript';
                    //       e.async = !0;
                    //       e.src = 'https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js';
                    //       const t = d.getElementsByTagName('script')[0];
                    //       t.parentNode.insertBefore(e, t);
                    //       setTimeout(() => {
                    //         w.YellowMessengerPlugin.show();
                    //         w.YellowMessengerPlugin.openBot();
                    //       }, 500);
                    //     };
                    //     i.q = [];
                    //     i.c = (e) => {
                    //       i.q.push(e);
                    //     };
                    //     w.YellowMessenger = i;
                    //     if (w.attachEvent) {
                    //       w.attachEvent('onload', l);
                    //     } else {
                    //       l();
                    //     }
                    //   }
                    // }}penBot();
                    //     }
                    //   } else {
                    //     const d = document;
                    //     const i = (...args) => {
                    //       i.c(...args);
                    //     };
                    //     const l = () => {
                    //       setChatBotOpen(true);
                    //       const e = d.createElement('script');
                    //       e.type = 'text/javascript';
                    //       e.async = !0;
                    //       e.src = 'https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js';
                    //       const t = d.getElementsByTagName('script')[0];
                    //       t.parentNode.insertBefore(e, t);
                    //       setTimeout(() => {
                    //         w.YellowMessengerPlugin.show();
                    //         w.YellowMessengerPlugin.openBot();
                    //       }, 500);
                    //     };
                    //     i.q = [];
                    //     i.c = (e) => {
                    //       i.q.push(e);
                    //     };
                    //     w.YellowMessenger = i;
                    //     if (w.attachEvent) {
                    //       w.attachEvent('onload', l);
                    //     } else {
                    //       l();
                    //     }
                    //   }
                    // }}
                  // />
                /* </Tooltip> */}
              {/* </Box> */}
            {/* </Box> */}
          </WebHeaderStyle>
          {isWithoutStepper && <Box className="status-title">{t('APPLICATION_STATUS')}</Box>}
          {!isWithoutStepper && !hidestepper && (
            <StepperBox sx={{ width: '100%' }}>
              <Box flexDirection="column">
                <Box className="stepperTitle">{t(steps?.[activeStep]?.label)}</Box>
                {/* {activeStep === 1 ? <Box className="stepperName">{t('MY_DETAILS')}</Box> : null} */}
              </Box>
              <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                {steps.map((item, index) => (
                  // item.key === 'isFeePaymentDone' && !offerAccepted ? (
                  //   ''
                  // ) :
                  <Step
                    key={item.label}
                    completed={completed.includes(item.key)}
                    className={classNameForTitle(item)}
                    onClick={setActiveStep.bind(
                      null,
                      classNameForTitle(item) === 'disabled-title' ? activeStep : index
                    )}
                  >
                    <StepLabel>{t(item.label)}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </StepperBox>
          )}
          <ErrorMessage />
        </WebContainerStyle>
      )}
    </>
  );
}

export default memo(CommonHeader);
