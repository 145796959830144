// @mui
import React from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import PropType from 'prop-types';
import { LoadingButton } from '@mui/lab';

import { useTranslation } from 'react-i18next';
import { photosForOcrDialogStyle as DialogStyle } from '../../styled-components';

const DeleteModal = ({
  deleteModal: { txtMessage, question, ...deleteModal },
  handleModalClose,
  onDeleteFileModal,
}) => {
  const { t } = useTranslation('translation');

  return (
    <>
      {deleteModal.state && (
        <DialogStyle onClose={handleModalClose} open={deleteModal.state}>
          <DialogTitle>{t(txtMessage)}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t(question)}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              fullWidth
              size="large"
              type="button"
              onClick={handleModalClose}
              variant="contained"
              sx={(theme) => ({
                background: theme.palette.offWhite,
                color: theme.palette.common.black,
                '&:hover': {
                  background: theme.palette.offWhite,
                  color: theme.palette.common.black,
                },
              })}
            >
              {t('CANCEL')}
            </LoadingButton>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={onDeleteFileModal.bind(null, deleteModal.deleteDocType)}
              sx={(theme) => ({
                background: theme.palette.error.main,
                color: theme.palette.common.white,
                '&:hover': {
                  background: theme.palette.error.main,
                  color: theme.palette.common.white,
                },
              })}
            >
              {t('DELETE')}
            </LoadingButton>
          </DialogActions>
        </DialogStyle>
      )}
    </>
  );
};

DeleteModal.propTypes = {
  deleteModal: PropType.object.isRequired,
  handleModalClose: PropType.func.isRequired,
  onDeleteFileModal: PropType.func.isRequired,
};
export default React.memo(DeleteModal);
