import { styled } from '@mui/material/styles';
import { Stack, Box, Accordion } from '@mui/material';
import { loadingButton } from './assetDetails';

export const basicInfoContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    '& form': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& > div': {
        width: '49%',
        '&.referenceSection': {
          width: '100%',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
          '& > div': {
            width: '49%',
          },
        },
        '&.genderBlock': {
          width: '100%',
        },
      },
    },
  },
  '& .loanAmmountInNumbers': {
    textTransform: 'capitalize',
    marginTop: 5,
    fontSize: 14,
    marginLeft: 14,
  },
}));

const isErrorDisplay = (_) => _ === 'true';

export const ApplicantDetailsTabs = styled('div')(({ theme, iserrordisplayed }) => ({
  padding: isErrorDisplay(iserrordisplayed) ? '200px 120px 56px' : '170px 120px 56px',
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: isErrorDisplay(iserrordisplayed) ? '214px 20px 50px' : '170px 20px 50px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: isErrorDisplay(iserrordisplayed) ? '164px 20px 70px' : '120px 20px 70px',
  },
}));

export const basicInfoContainerStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
	padding:  '170px 120px 56px',
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: '170px 20px 50px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '120px 20px 70px',
  },
  borderTop: 0,
  borderBottom: 0,
  '& .loadingButton': loadingButton(theme),
  '& .fullContainer': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  '& .width-50': {
    width: '49%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  '& .mobile-view': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > div': {
      width: '49% !important',
    },
  },
}));

export const basicInfoStackStyle = styled(Stack)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  border: `1px solid ${theme.palette.grey[1000]}`,
  borderRadius: 4,
  '& > button': {
    marginTop: '0px !important',
    marginLeft: '0 !important',
    padding: '0 !important',
  },
}));

export const basicInfoHeaderStyle = styled('header')(({ theme }) => ({
  backgroundColor: theme.palette.navyBlue,
  padding: '30px 50px',
  boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 9,
  '& > p': {
    color: theme.palette.common.white,
    fontSize: 16,
    marginBottom: 0,
    paddingTop: '10px',
    lineHeight: '22px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px 20px',
  },
}));

export const basicInfoTopConatinerStyle = styled(Box)(({ theme }) => ({
  '& h4': {
    color: theme.palette.common.white,
    fontSize: '23px',
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fill: theme.palette.common.white,
      fontSize: 24,
      marginLeft: '4px',
    },
  },
  '& > svg': {
    fill: theme.palette.common.white,
    fontSize: 24,
  },
}));

export const basicInfoAccordionStyle = styled(Accordion)(({ theme }) => ({
  marginTop: '0 !important',
  '&.Mui-expanded': {
    border: `1px solid ${theme.palette.grey[1000]}`,
    padding: '20px 0 0',
    borderTop: 0,
    marginTop: '0 !important',
  },
  '& .MuiAccordionSummary-root': {
    display: 'none',
  },
  '&::before': {
    display: 'none',
  },
}));
