import { httpService } from '../helper';
import { constants } from '../constants';

export const uploadDocument = async (data, controller) =>
  httpService(data, controller).post(constants.API.SAVE_OCR_DOC, data);

export const deleteDocument = async (data) =>
  httpService().delete(
    `${constants.API.DELETE_OCR_DOC}/${data.documentType}${data.applicantId ? `/${data.applicantId}` : ''}`
  );

export const getDocument = async () => httpService().get(constants.API.GET_OCR_DOC);
