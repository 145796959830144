/* eslint-disable import/no-unresolved */
import { formDefaultValues } from "src/constants";

export const transformAddressPayloadAsPerAPI = (addressPayloadArray, addressIds) => {
	const transformedAddressPayloadArray = [];

	for (let index = 0; index < addressPayloadArray.length; index++) {
		let updatedAddressId = null
		if (addressIds?.length > 0) {
			updatedAddressId = addressIds?.length > 0 ? addressIds[index] : null
		}
		const addressObject = {
			...addressPayloadArray[index],
			id: updatedAddressId
		};

		if (addressObject.addressType === "CURRENT") {
			addressObject.addressType = "CURRENT_ADDRESS";
		} else if (addressObject.addressType === "PERMANENT") {
			addressObject.addressType = "PERMANENT_ADDRESS";
		} else if (addressObject.addressType === "ASSET") {
			addressObject.addressType = "ASSET_ADDRESS";
		}

		addressObject.propertyOwnership = formDefaultValues.propertyOwnershipAPIValue[addressObject.propertyOwnership];
		transformedAddressPayloadArray.push(addressObject);
	}

	return transformedAddressPayloadArray;
};

export const transformEmploymentPayloadAsPerAPI = (employmentPayload) => {
	employmentPayload.sector = formDefaultValues.sectorAPIValue[employmentPayload.sector]
	employmentPayload.subSector = formDefaultValues.subSectorAPIValue[employmentPayload.subSector]

	return employmentPayload
}

export const checkWhetherPUTORPOSTIsApplicableFun = (payloadPutIdState) => {
	let method = null
	const applicantState = payloadPutIdState?.applicant
	if (applicantState?.applicantMyDetails > 0 && applicantState?.applicantAddress[0] > 0 && applicantState?.applicantAddress[1] > 0) {
		method = "PUT"
	} else {
		method = "POST"
	}
	return method
} 